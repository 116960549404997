import React, { Component } from "react";
import "./MaterialSwitch.css";
 

class MaterialSwitch extends Component {

  constructor(props) {
    super(props);
    this.state = {switchStatus: this.props.checked};
  }

  onChangeChecked(e) {
    this.setState({switchStatus: e.target.checked})
    this.props.onChange(e.target.checked)
  }

  render() {
    return (<label className="pure-material-switch">
    <input checked={this.state.switchStatus} type="checkbox" onChange={(e) => this.onChangeChecked(e)}/>
    <span></span>
  </label>);
  }
}

export default MaterialSwitch;
