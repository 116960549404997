import React from "react";
import "./CookiePolicyPopup.css";

class CookiePolicyPopup extends React.Component {
  render() {
    return (
      <div className="cookie_policy_popup">
        <div className="cookie_policy_popup_open">
          <h1>{this.props.text}</h1>
          <div>
          <div className="terms-conditions-text">
          <p/>
          Verimpact may use cookies to identify a visitor/user of certain services and pages of the website (e.g., newsletters). The visitors of the Verimpact website can set their browsers in such a way so as to be informed on the use of cookies in certain services of the Company or to prevent the use of cookies in any case. However, by disabling cookies, or certain types of cookies, you may hinder your user experience on this and other websites, or prevent them from working entirely.
          <br/>
          Some cookies used on this website are essential in order to enable you to move around the website and use its features, such as accessing secure areas of the website. Without these cookies, services you have asked for, like shopping baskets or e-billing, cannot be provided.
          <br/>
          Some cookies used on this website collect information about how visitors use a website, for instance which pages visitors go to most often, and if they get error messages from web pages. These cookies don't collect information that identifies a visitor. All information these cookies collect is aggregated and therefore anonymous. It is only used to improve how a website works.
          <br/>
          Some cookies used on this website allow the website to remember choices you make (such as your username, language or the region you are in) and provide enhanced, more personal features. These cookies can also be used to remember changes you have made to text size, fonts and other parts of web pages that you can customize. They may also be used to provide services you have asked for such as watching a video or commenting on a blog. The information these cookies collect may be anonymised and they cannot track your browsing activity on other websites. Cookies may also be used to improve the user experience and to enable some of the functionality provided by this website.
          <br/>
          Please note that some cookies may be placed by third party service providers who perform some of these functions (or other services) for us. Cookies are used on this website for the following third party services: Google Analytics, LinkedIn Follow button, Facebook Like Button, Twitter Follow button, Google+ button, Mailchimp social bookmarking tool, YouTube videos. Some of these services may be used to track your behavior on other websites and we have no control over this. By continuing to use this website without adjusting your browser's cookie settings, you agree that we can place these cookies on your device.
          <br/>
          <a href="http://www.verimpact.com">http://www.verimpact.com</a>
          <br/>
          This document was last updated on Jenuary 20, 2021
          </div>
          <div className="agree-button-container">
          <button onClick={this.props.closeCookiePolicy} className="agree-button">Accept</button>
          </div>
          <p/>
          </div>
        </div>
      </div>
    );
  }
}
export default CookiePolicyPopup;
