import React, { Component } from "react";
import "./ProfilePage.css";
import DropdownSelect from "../../CustomItems/DropdownSelect";
import EmployeesList from '../../lists/EmployeesList';
import CountriesList from '../../lists/CountriesList';
import IndustryList from '../../lists/IndustryList';
import { Navigate } from "react-router-dom";
import MessagePopup from "../../CustomItems/Message/MessagePopup";
import { authManager } from "../../Auth/AuthManager";
import config from "../../../constants";
import fetchInterceptor from "../../../utils/FetchClient";

class ProfilePage extends Component {

    constructor() {
        super();

        this.companyName = React.createRef();
        this.jobTitle = React.createRef();
        this.industryField = React.createRef();
        this.employeeField = React.createRef();
        this.countryField = React.createRef();


        this.state = {
            isCompanyNameEmpty: false,
            isJobTitleEmpty: false,
            isIndustryFieldEmpty: false,
            isEmployeeFieldEmpty: false,
            isCountryFieldEmpty: false,
            industry: IndustryList.IndustryList,
            employees: EmployeesList.EmployeesList,
            countries: CountriesList.CountriesList,
            redirect: false,
            companyName: "",
            jobTitle: "",
            industryField: "",
            employeeField: "",
            countryField: "",
            isProfileComplete: false,
            showMessagePopup: false,
            messageTitle: "",
            messageDescr: "",
            messageButton: "",
            username: "",
            userID: ""

        }

        this.setUsername = this.setUsername.bind(this);

    }

    componentDidMount() {
        this.getUsername();

    }

    async getUsername() {
        let user = null;
        try {
            user = await authManager.getCurrentAuthenticatedUser();
        } catch {
            user = null;
        }
        await this.setUsername(user);
    }

    async setUsername(value) {
        await this.setState({ username: value });
        await this.getUserDetails(value);
    }

    async getUserDetails(user) {
        let token = await authManager.getAccessToken();
        token = 'Bearer' + " " + token;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        let { response, data } = await fetchInterceptor(config.url.API_URL + "/VerimpactAPI/GetUserDetailsByName/" + user, requestOptions);
        this.prefillFields(data);
    }

    async prefillFields(userDetails) {
        await this.setState({
            isProfileComplete: userDetails.isProfileComplete,
            companyName: userDetails.companyName,
            jobTitle: userDetails.jobTitle,
            industryField: userDetails.industry,
            employeeField: userDetails.employees,
            countryField: userDetails.location,
            userID: userDetails.userID
        });
        //put data inside the fields if exists

        //company field prefill
        if (userDetails.companyName != "") {
            this.companyName.current.value = userDetails.companyName;
        }

        //job title field prefill
        if (userDetails.jobTitle != "") {
            this.jobTitle.current.value = userDetails.jobTitle;
        }

        //check industry list/data
        if (userDetails.industry != "" && userDetails.industry != null) {
            let industryIndex = this.state.industry.findIndex(object => {
                return object.title == userDetails.industry;
            });
            this.resetThenSet(industryIndex, "industry");
        }
        // check employees list/data
        if (userDetails.employeeRange != "" && userDetails.employeeRange != null) {
            let employeesIndex = this.state.employees.findIndex(object => {
                return object.title == userDetails.employeeRange;
            });
            this.resetThenSet(employeesIndex, "employees");
        }
        //check location list/data
        if (userDetails.location != "" && userDetails.location != null) {
            let locationIndex = this.state.countries.findIndex(object => {
                return object.title == userDetails.location;
            });
            this.resetThenSet(locationIndex, "countries");
        }
        await this.setIsProfileComplete(userDetails.isProfileComplete);
    }

    async setIsProfileComplete(value) {
        await this.setState({ isProfileComplete: value });
    }

    resetThenSet = (id, key) => {
        const temp = [...this.state[key]];
        //console.log(key);
        if (key == "industry") {
            this.setState({ isIndustryFieldEmpty: false });
        } else if (key == "employees") {
            this.setState({ isEmployeeFieldEmpty: false });
        } else if (key == "countries") {
            this.setState({ isCountryFieldEmpty: false });
        }

        temp.forEach((item) => item.selected = false);
        temp[id].selected = true;

        this.setState({
            [key]: temp,
        });
    }

    saveForLaterProfile = async (event) => {
        event.preventDefault();

        const companyName = this.companyName.current.value;
        const jobTitle = this.jobTitle.current.value;
        const industry = this.industryField.current.state.headerValue;
        const employees = this.employeeField.current.state.headerValue;
        const location = this.countryField.current.state.headerValue;
        const username = this.state.username;

        let ops = [];
        if (companyName.length > 0) {
            ops.push({
                "operationType": 2,
                "path": "/CompanyName",
                "op": "replace",
                "from": "<string>",
                "value": companyName
            });
        }
        if (jobTitle.length > 0) {
            ops.push({
                "operationType": 2,
                "path": "/JobTitle",
                "op": "replace",
                "from": "<string>",
                "value": jobTitle
            });
        }
        if (industry.length > 0 && industry != "Select") {
            ops.push({
                "operationType": 2,
                "path": "/industry",
                "op": "replace",
                "from": "<string>",
                "value": industry
            });
        }
        if (employees.length > 0 && employees != "Select") {
            ops.push({
                "operationType": 2,
                "path": "/EmployeeRange",
                "op": "replace",
                "from": "<string>",
                "value": employees
            });
        }
        if (location.length > 0 && location != "Select") {
            ops.push({
                "operationType": 2,
                "path": "/location",
                "op": "replace",
                "from": "<string>",
                "value": location
            });
        }
        try {
            let token = await authManager.getAccessToken();
            token = 'Bearer' + " " + token;
            var myHeaders = new Headers();
            myHeaders.append("Authorization", token);
            myHeaders.append("Content-Type", "application/json-patch+json");
            myHeaders.append("Accept", "application/json");

            var raw = JSON.stringify(ops);

            var requestOptions = {
                method: 'PATCH',
                headers: myHeaders,
                body: raw
            };
            await fetchInterceptor(config.url.API_URL + "/VerimpactAPI/UpdateProfile/" + this.state.userID, requestOptions);
        } catch (e) {
            console.log(e);
        }
        this.showPopup("Your responses have been saved.", "You can continue editing your profile later.", "close");
    }

    showPopup(title, descr, button) {
        this.setState({ showMessagePopup: true, messageTitle: title, messageDescr: descr, messageButton: button });
    }

    closePopup() {
        this.setState({ showMessagePopup: false, messageTitle: "", messageDescr: "", messageButton: "" });
        if (this.state.isProfileComplete) {
            this.setState({ redirect: true });
        }
    }

    submitProfile = async (event) => {
        event.preventDefault();
        const companyName = this.companyName.current.value;
        const jobTitle = this.jobTitle.current.value;
        const industry = this.industryField.current.state.headerValue;
        const employees = this.employeeField.current.state.headerValue;
        const location = this.countryField.current.state.headerValue;
        const username = this.state.username;

        if (companyName.length <= 0) {
            await this.setState({ isCompanyNameEmpty: true });
        } else {
            await this.setState({ isCompanyNameEmpty: false });
        }

        if (jobTitle.length <= 0) {
            await this.setState({ isJobTitleEmpty: true });
        } else {
            await this.setState({ isJobTitleEmpty: false });
        }

        if (industry == "Select") {
            await this.setState({ isIndustryFieldEmpty: true });
        } else {
            await this.setState({ isIndustryFieldEmpty: false });
        }

        if (employees == "Select") {
            await this.setState({ isEmployeeFieldEmpty: true });
        } else {
            await this.setState({ isEmployeeFieldEmpty: false });
        }

        if (location == "Select") {
            await this.setState({ isCountryFieldEmpty: true });
        } else {
            await this.setState({ isCountryFieldEmpty: false });
        }

        if (this.state.isCompanyNameEmpty === false &&
            this.state.isJobTitleEmpty === false &&
            this.state.isIndustryFieldEmpty === false &&
            this.state.isEmployeeFieldEmpty === false &&
            this.state.isCountryFieldEmpty === false) {

            const isProfileComplete = true;
            await this.setState({ isProfileComplete: true });
            try {
                let token = await authManager.getAccessToken();
                token = 'Bearer' + " " + token;
                var myHeaders = new Headers();
                myHeaders.append("Authorization", token);
                myHeaders.append("Content-Type", "application/json-patch+json");
                myHeaders.append("Accept", "application/json");
                let ops = [];
                ops.push({
                    "operationType": 2,
                    "path": "/CompanyName",
                    "op": "replace",
                    "from": "<string>",
                    "value": companyName
                });
                ops.push({
                    "operationType": 2,
                    "path": "/JobTitle",
                    "op": "replace",
                    "from": "<string>",
                    "value": jobTitle
                });
                ops.push({
                    "operationType": 2,
                    "path": "/industry",
                    "op": "replace",
                    "from": "<string>",
                    "value": industry
                });
                ops.push({
                    "operationType": 2,
                    "path": "/EmployeeRange",
                    "op": "replace",
                    "from": "<string>",
                    "value": employees
                });
                ops.push({
                    "operationType": 2,
                    "path": "/location",
                    "op": "replace",
                    "from": "<string>",
                    "value": location
                });
                ops.push({
                    "operationType": 2,
                    "path": "/IsProfileComplete",
                    "op": "replace",
                    "from": "<string>",
                    "value": 1
                });
                var raw = JSON.stringify(ops);

                var requestOptions = {
                    method: 'PATCH',
                    headers: myHeaders,
                    body: raw
                };
                await fetchInterceptor(config.url.API_URL + "/VerimpactAPI/UpdateProfile/" + this.state.username, requestOptions);
                this.showPopup("Your profile is complete.", "Proceed to your ESG assessment.", "Continue");
            } catch (e) {
                console.log(e);
            }

        }
    } // submit profile



    render() {

        const { redirect } = this.state;

        if (redirect) {
            return <Navigate to="/survey-prepare" />;
        }

        const { isCompanyNameEmpty, isJobTitleEmpty, isIndustryFieldEmpty, isEmployeeFieldEmpty, isCountryFieldEmpty } = this.state;
        return (
            <div className="verimpact-container">

                {this.state.showMessagePopup ?
                    <MessagePopup
                        messageTitle={this.state.messageTitle}
                        messageDescr={this.state.messageDescr}
                        messageButton={this.state.messageButton}
                        closePopup={() => { this.closePopup() }}
                    />
                    : null
                }

                <div className="profile-heading">
                    <h3 className="page-title top-space bot-space text-blue">Create your profile.</h3>
                </div>
                <div className="profile-cards-container">
                    <div className="card-item company-name-container">
                        <div className="card-container">
                            <div className="card-content">
                                <div className="card-group-1">
                                    <h3 className="card-title">Company Name</h3>
                                </div>
                                <hr className="card-line"></hr>
                                <div className="card-group-2">
                                    <p className="card-description">
                                        Add your full legally registered company name below
                  </p>
                                </div>
                                <div className={"card-group-3 " + (isCompanyNameEmpty ? "error" : "") + `${this.state.companyName === "" ? "" : "form-text-input-filled"}`}>
                                    <input
                                        disabled={this.state.isProfileComplete ? true : false}
                                        className="form-text-input"
                                        placeholder="Enter your company name here"
                                        name="company_name"
                                        id="company_name"
                                        type="text"
                                        value={this.state.companyName}
                                        onChange={(e) => this.setState({ companyName: e.target.value })}
                                        ref={this.companyName}
                                        onBlur={(e) => (this.companyName.current.value.length > 0 ? this.setState({ isCompanyNameEmpty: false }) : this.setState({ isCompanyNameEmpty: true }))}
                                    />
                                    <small className="error-message">Please enter value.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-item job-title-container">
                        <div className="card-container">
                            <div className="card-content">
                                <div className="card-group-1">
                                    <h3 className="card-title">Job Title</h3>
                                </div>
                                <hr className="card-line"></hr>
                                <div className="card-group-2">
                                    <p className="card-description">
                                        Add your job title below
                  </p>
                                </div>
                                <div className={"card-group-3 " + (isJobTitleEmpty ? "error" : "") + `${this.state.jobTitle === "" ? "" : "form-text-input-filled"}`}>
                                    <input
                                        disabled={this.state.isProfileComplete ? true : false}
                                        className="form-text-input"
                                        placeholder="Enter your job title name here"
                                        name="job_title"
                                        id="job_title"
                                        type="text"
                                        value={this.state.jobTitle}
                                        onChange={(e) => this.setState({ jobTitle: e.target.value })}
                                        ref={this.jobTitle}
                                        onBlur={(e) => (this.jobTitle.current.value.length > 0 ? this.setState({ isJobTitleEmpty: false }) : this.setState({ isJobTitleEmpty: true }))}
                                    />
                                    <small className="error-message">Please enter value.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-item industry-select-container">
                        <div className="card-container">
                            <div className="card-content">
                                <div className="card-group-1">
                                    <h3 className="card-title">Sector</h3>
                                </div>
                                <hr className="card-line"></hr>
                                <div className="card-group-2">
                                    <p className="card-description">
                                        Use the dropdown to enter the sector your company belongs to
                  </p>
                                </div>
                                <div className={"card-group-3 " + (isIndustryFieldEmpty ? "error" : "") + `${this.state.industryField === "" ? "" : "filled"}`}
                                    style={{ pointerEvents: `${this.state.isProfileComplete ? 'none' : 'auto'}` }}>
                                    <DropdownSelect
                                        className=""
                                        title="Select"
                                        list={this.state.industry}
                                        resetThenSet={this.resetThenSet}
                                        ref={this.industryField}
                                        onChange={(value) => { this.setState({ industryField: value }) }}
                                        hasSearch={true}
                                    />
                                    <small className="error-message">Please enter value.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-item employees-select-container">
                        <div className="card-container">
                            <div className="card-content">
                                <div className="card-group-1">
                                    <h3 className="card-title">Number of employees</h3>
                                </div>
                                <hr className="card-line"></hr>
                                <div className="card-group-2">
                                    <p className="card-description">
                                        Use the drop down to indicate the number of employees in your company
                  </p>
                                </div>
                                <div className={"card-group-3 " + (isEmployeeFieldEmpty ? "error" : "") + `${this.state.employeeField === "" ? "" : "filled"}`}
                                    style={{ pointerEvents: `${this.state.isProfileComplete ? 'none' : 'auto'}` }}>
                                    <DropdownSelect
                                        className=""
                                        title="Select"
                                        list={this.state.employees}
                                        resetThenSet={this.resetThenSet}
                                        ref={this.employeeField}
                                        onChange={(value) => this.setState({ employeeField: value })}
                                    />
                                    <small className="error-message">Please enter value.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-item country-select-container">
                        <div className="card-container">
                            <div className="card-content">
                                <div className="card-group-1">
                                    <h3 className="card-title">Location</h3>
                                </div>
                                <hr className="card-line"></hr>
                                <div className="card-group-2">
                                    <p className="card-description">
                                        Use the dropdown to indicate the location your company is based. If your company is based in multiple locations please select the location of your headquarters.
                  </p>
                                </div>
                                <div className={"card-group-3 " + (isCountryFieldEmpty ? "error" : "") + `${this.state.countryField === "" ? "" : "filled"}`}
                                    style={{ pointerEvents: `${this.state.isProfileComplete ? 'none' : 'auto'}` }}>
                                    <DropdownSelect
                                        className=""
                                        title="Select"
                                        list={this.state.countries}
                                        resetThenSet={this.resetThenSet}
                                        ref={this.countryField}
                                        onChange={(value) => this.setState({ countryField: value })}
                                        hasSearch={true}
                                    />
                                    <small className="error-message">Please enter value.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="profile-heading top-space bot-space">
                    {!this.state.isProfileComplete && (
                        <div className="profile-btn-container">
                            <a href="#" className="a-button-primary" onClick={this.submitProfile}>Submit</a>
                            <a href="#" className="save-for-later" onClick={this.saveForLaterProfile}>Save for later.</a>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default ProfilePage;
