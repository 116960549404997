import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import AddFileIcon from "../../../assets/svg/add_file_icon.svg";
import PlusIcon from "../../../assets/svg/plus_icon.svg";
import LinkIcon from "../../../assets/svg/link_icon.svg";
import LinkIconColored from "../../../assets/svg/link_icon_color.svg";
import AddCircleIcon from "../../../assets/svg/add_circle_icon_black.svg";
import FilesIcon from "../../../assets/svg/files_icon.svg";
import FilesIconGrey from "../../../assets/svg/files_icon_grey.svg";
import DeleteIconGrey from "../../../assets/svg/delete_icon_grey.svg";
import { urlPatternValidation } from "../../../helpers";
import { authManager } from "../../Auth/AuthManager";
import config from "../../../constants";
import "./SurveyFinishPage.css";
import fetchInterceptor from "../../../utils/FetchClient";

class SurveyFinishPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      uploadFiles: [
      ],
      links: [
        {
          id: 0,
          value: "",
          focus: true,
        }
      ],
      selectedUploadFile: null,
      redirect: false,
      userID: "",
    };
    this.changeSelectedFile.bind(this);
    this.changeLinkInput.bind(this);
    this.onFocusLinkItem.bind(this);

    this.skipAndSubmit = this.skipAndSubmit.bind(this);
    this.submitLinks = this.submitLinks.bind(this);
  }

  componentDidMount () {
    this.setState({'userID': localStorage.getItem("userID")});
  }

  async skipAndSubmit () {
    await this.setState({redirect: true});
  }

  async submitLinks () {
    await this.saveLinksToDB();
  }


  saveLinksToDB = async () => {
      let linksString = '';
    const linksList = this.state.links;
      linksList.forEach((link) => {
          if (link.value != "") {
              if (linksString === '') {
                  linksString = link.value;
              } else {
                  linksString = linksString + ', ' + link.value;
              }
          }
      });
      try {
          let token = await authManager.getAccessToken();
          token = 'Bearer' + " " + token;
          var myHeaders = new Headers();
          myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json-patch+json");
        myHeaders.append("Accept", "application/json");
        let ops = [];

        ops.push({
            "operationType": 2,
            "path": "/Links",
            "op": "replace",
            "from": "<string>",
            "value": linksString
        });
        var raw = JSON.stringify(ops);

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            body: raw
          };
          await fetchInterceptor(config.url.API_URL + "/VerimpactAPI/UpdateProfile/" + this.state.userID, requestOptions);
          this.setState({ redirect: true });
       
      } catch (e) {
        console.log(e);
      }
  }



  readFile(event) {
    if (event.target.files.length !== 0  && this.state.selectedUploadFile != null){
      let file = event.target.files[0];
      

      //CHECK THAT THE FILE IS NOT BIGGER THAN 1MB
      const maxAllowedSize = 1 * 1024 * 1024;
      if (file.size > maxAllowedSize) {
        return;
      }

      let reader = new FileReader();
      reader.onloadend = () => {
        var storedUploadFiles = this.state.uploadFiles;

        

        storedUploadFiles.forEach((value,index) => {
            if (value.id == this.state.selectedUploadFile.id) {
              storedUploadFiles[index].file = file;
            }
        });

        this.setState({uploadFiles: storedUploadFiles});
      }
      reader.readAsDataURL(file)
    }
  }

  changeSelectedFile(uploadFile) {
    this.setState({selectedUploadFile: uploadFile});
  }

  changeUploadFileDescription(e, index) {
    let value = e.target.value;
    let files = [...this.state.uploadFiles];    
    files[index].value = value;
    this.setState({uploadFiles: files})
  }

  changeLinkInput(e,index) {
    let value = e.target.value;
    let links = [...this.state.links];    
    links[index].value = value;
    this.setState({links: links})
  }

  onFocusLinkItem(e, index) {
    let links = [...this.state.links];
    links.forEach((link) => {
      link.focus = false;
    });
    links[index].focus = true;
    this.setState({links: links});
  }

  openFileDelete(index) {
    let files = [...this.state.uploadFiles];    
    files.splice(index, 1); 
    this.setState({uploadFiles: files})
  }

  openLinkDelete(index) {
    let links = [...this.state.links];    
    links.splice(index, 1); 
    this.setState({links: links})
  }
  

  renderAddFiles() {
    let filesItems = [];

    this.state.uploadFiles.forEach((uploadFile,index) => {
      filesItems.push(
      <div key={`upload_file_item_${uploadFile.id}`} className="survey-finish-file-item">
        <label id={`upload_file_label_${uploadFile.id}`} onClick={(e)=> {this.changeSelectedFile(uploadFile)}}  style={{width: '100%',height: '76px'}} htmlFor='myInput'>
          <input onClick={(e)=> {e.stopPropagation()}} id={`upload_file_input_${uploadFile.id}`} onChange={(event)=> { this.readFile(event) }} id="myInput" type="file" style={{visibility: 'hidden',height:'0px',display:'none'}} accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*" />
            {uploadFile.file == null ? 
              <div className="survey-finish-upload-bg">
                <div style={{display:'flex',alignItems:'center'}}>
                  <img src={FilesIconGrey} alt=""></img>
                     <div className="survey-add-links-text-black">Choose File</div>
                </div>
                </div> : 
                <div className="survey-finish-upload-bg">
                <div style={{display:'flex',alignItems:'center'}}>
                  <img src={FilesIconGrey} alt=""></img>
                     <div className="survey-add-links-text-black">{uploadFile.file.name}</div>
                </div>
                  <img onClick={(e) => {this.openFileDelete(index)}} src={DeleteIconGrey} alt=""></img>
                </div> 
              }
        </label>
      </div>)
    });

    

    return (<div style={{marginBottom: '18px'}}>
        <h4 className="survey-finish-add-links-title">Upload multiple files</h4>
        <div onClick={()=> {
            this.setState({
              uploadFiles: [...this.state.uploadFiles, {
                id: this.state.uploadFiles.length+1,
                file: null,
                value: ""
              }]
            })
          }} className="survey-finish-add-more-files-container">
                     <div className={`survey-finish-link-container`} style={{justifyContent:'space-between'}}>
                <div style={{display:'flex',alignItems:'center'}}>
                  <img src={FilesIcon} alt=""></img>
                  <div 
                    className="survey-add-links-text">Add File</div>
                </div>
               
                <img src={AddCircleIcon} alt=""></img>
            </div>
          </div>
       {/* <div onClick={()=> {
            this.setState({
              uploadFiles: [...this.state.uploadFiles, {
                id: this.state.uploadFiles.length+1,
                file: null,
                value: ""
              }]
            })
          }} className="survey-finish-add-more-files-container">
              <img alt="" src={PlusIcon}/>
              <div className="survey-finish-add-more-files">Add more files</div>
          </div> */}
        {filesItems}
        <div>
         
        </div>
    </div>)
  }

  renderAddLinks() {
    let linkItems = [];

    this.state.links.forEach((item,index) => {
      linkItems.push(
      <div style={{marginTop: '15px'}} className={`survey-submit-link-item-container ${item.focus ? "focus" : ""} ${!urlPatternValidation(item.value) && item.value !== "" ? "error" : ''}`}>
        <div style={{justifyContent: 'space-between'}} className={`survey-finish-link-container`}>
          <div style={{display:'flex',alignItems: 'center',width:'100%'}}>
           <img src={item.focus ? LinkIconColored : LinkIcon} alt=""></img>
           <input 
              type={"url"} 
              value={item.value} 
              onChange={(e) => {this.changeLinkInput(e,index)}} 
              placeholder="Insert Link here" 
              className="survey-finish-link-input"
              onBlur={(e) => (!urlPatternValidation(item.value))}
              onFocus={(e) => {this.onFocusLinkItem(e, index)}}
             />
          </div>
          <img style={{cursor: 'pointer'}} onClick={(e) => {this.openLinkDelete(index)}} src={DeleteIconGrey} alt=""></img>
                 
            </div>
            <div className="error-message">Please enter a valid link.</div>
      </div>
    );
    });
    

    return(<div className="" style={{marginBottom: '18px'}}>
          <h4 className="survey-finish-add-links-title">Useful links:</h4>
          {linkItems}
          <div onClick={()=> {
            var oldLinks = this.state.links;
            oldLinks.forEach((link) => {
              link.focus = false;
            });
            this.setState({links: oldLinks});
            this.setState({
              links: [...this.state.links, {
                id: this.state.links.length+1,
                value: "",
                focus: true,
              }]
            })
          }} className="survey-finish-add-more-files-container">
                     <div className={`survey-finish-link-container`}>
                <img src={AddCircleIcon} alt=""></img>
                <div 
                  className="survey-add-links-text">Add more</div>
                 
            </div>
          </div>
         
    </div>)
  }
 
  render() {

    const { redirect } = this.state;

    if (redirect) {
      return <Navigate to="/survey-thanks" />;
    }

      return (
        <div className="verimpact-container">
            <div className="survey-finish-container">
                <h2 className="survey-finish-title">Thanks for completing your assessment.</h2>
                <p className="survey-finish-subtitle">Before submitting are there any supporting links you would like to share?</p>
                <p className="survey-finish-subtitle">If yes, please use the button below.</p>

                <div className="card-item survey-finish-card-item"  style={{height: 'auto',maxWidth: 'none',marginTop:'40px'}}>
                  <div className="card-container survey-finish-card-container">
                      <div className="survey-finish-info-container">
                         <h3 className="survey-finish-provide-info-title">Provide supporting information</h3>
                         <h4 className="survey-finish-provide-info-subtitle">Insert links below (ex. Linkedin)</h4>
                          <div className="survey-finish-provide-info-data-container">
                              {/* <div className="survey-finish-provide-upload-files">
                                  {this.renderAddFiles()}
                              </div>
                              <div className="survey-finish-info-and-or">
                                <p className="survey-finish-info-and-or-text">and/or</p>
                              </div> */}
                              <div>
                                  {this.renderAddLinks()}
                              </div>
                          </div>
                      </div>
                  </div>
                </div>

                <div className="survey-finish-bottom-buttons">
                    <div className="survey-finish-submit-button" onClick={this.submitLinks}>Submit</div>
                    <span className="survey-finish-skip-button" onClick={this.skipAndSubmit}>Skip</span>
                </div>
            </div>
        </div>
      );
  }
}

export default SurveyFinishPage;
