import React from "react";
import "./DashboardPopUp.css";

import CloseIcon from '../../../assets/svg/close.svg';
import { Link } from "react-router-dom";

class DashboardPopUp extends React.Component {
  render() {
    return (
      <div className="popup">
        <div className="popup_open">

          <div>
              <img onClick={() => this.props.closePopup()} className="close-icon" alt="" src={CloseIcon}></img>
          </div>

          <div className="dashboard-popup-title-container">
            <div className="dashboard_popup_title">
              Have any questions on your
            </div>
            <div className="dashboard_popup_title">
              ESG Assessment results? Get in touch.
            </div>
          </div>

          <div className="dashboard-popup-middle-line"></div>

          <Link style={{textDecoration: 'none',cursor: 'pointer'}} to="/contact" >
            <div className="dashboard-popup-button">
            Contact Us
            </div>
          </Link>
        
          
        </div>
      </div>
    );
  }
}
export default DashboardPopUp;
