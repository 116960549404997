import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import { userContext } from "../../context/AuthContext";
import "./EmailSentPage.css";

class EmailSentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const redirect = this.state.redirect;
    const { value } = {
      user: this.state.user,
      isLoggedIn: this.state.isLoggedIn,
    };
    if (redirect) {
      return (
        <userContext.Provider value={value}>
          <Navigate to="/internal" />
        </userContext.Provider>
      );
    }
    const isMobile = window.innerWidth <= 500;
    if (isMobile) {
      return this.renderMobile();
    } else {
      return (
        <div className="login-container">
          <div className="login-form">
            <br/><br/>
            <img className="check-icon" src="/assets/images/complete-check.png" />
            <p className="login-heading">Email sent!</p>
            <p className="login-heading-description">
            Check your inbox, we have sent you a confirmation <br />
            link to change your password to a new one.
            </p>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="login-signup-container">
              <p className="login-signup-text">
                Do not have an account? Get in touch with us to set up your
                account.
              </p>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default EmailSentPage;
