import { decodeTokenPayload } from "./JWTUtils";
import dayjs from 'dayjs'
import config from "../constants";

let baseAuthURL = config.url.AUTH_URL;

let originalRequest = async (url, config) => {
    let response = await fetch(url, config);
    let data = await response.json();
    return { response, data };
}

let syncOriginalRequest = async (url, config) => {
    let response = fetch(url, config);
    let data = await response.json();
    console.log(data);
    return { response, data };
}

let refreshToken = async (authTokens) => {
    let token: any;
    let raw_token: any;
    let refresh_token: any;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("client_id", "Verimpact");
    urlencoded.append("grant_type", "refresh_token");
    urlencoded.append("refresh_token", authTokens);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    await fetch(baseAuthURL + "/connect/token", requestOptions)
        .then(response => response.json())
        .then(result => {
            raw_token = result;
            token = decodeTokenPayload(raw_token.access_token);
            refresh_token = raw_token.refresh_token;
            localStorage.setItem('token', raw_token.access_token);
            localStorage.setItem('refresh_token', raw_token.refresh_token);
        })
        .catch(error => console.log('error', error));
    return raw_token.access_token;
}


let fetchInterceptor = async (url, config) => {
    let refToken = localStorage.getItem('refresh_token') ? localStorage.getItem('refresh_token') : null
    let user = decodeTokenPayload(localStorage.getItem('token'));
    let token = localStorage.getItem('token');
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
    if (isExpired) {
        token = await refreshToken(refToken);
        config.headers.set("Authorization", `Bearer ${token}`);
    }

    let { response, data } = await originalRequest(url, config);
    if (response.statusText === 'Unauthorized') {
        user = await refreshToken(refreshToken)
        config['headers'] = {
            Authorization: `Bearer ${token}`
        }
        let { response, data } = await originalRequest(url, config);
        return { response, data };
    }
    return { response, data };
}
export default fetchInterceptor;