import React, { Component } from "react";
import "./MobileSupportPage.css";


class MobileSupportPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

 


    render() {

        var sliderImage = "/assets/images/verimpact-mobile-slide-bg.jpg";
        // if (window.innerWidth <= 720) {
        //   sliderImage = "/assets/images/verimpact-mobile-slide-bg.jpg";
        // } else if (window.innerWidth > 2000) {
        //   sliderImage = "/assets/images/verimpact-slide-bg-large.jpg";
        // }

        return (<div className="mobile-support-bg">
              <div style={{position:'relative'}}>
              <div className="mobile-support-text">
                    Our ESG Assessment is only available on desktop for an optimal experience. Please login on a desktop device <span className="mobile-text-dot">.</span>
                </div>
                <img
                    src={sliderImage}
                    className="landing-img"
                    alt="Fishing Boat"
                />
              
              </div>
        </div>);
  }
}

export default MobileSupportPage;
