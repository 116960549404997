import React from "react";
import "./AddUserPopUp.css";
import config from "../../../../constants";
import CloseIcon from '../../../../assets/svg/close.svg';
import { validateEmail } from "../../../../helpers";
import { authManager } from "../../../Auth/AuthManager";
import fetchInterceptor from "../../../../utils/FetchClient";

class AddUserPopUp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          firstName: "",
          lastName: "",
          email: "",
          fnameError: "",
          lnameError: "",
          emailError: "",
          confirmEmail: true,
          userId: ""
        }
        this.onAddUserSubmit = this.onAddUserSubmit.bind(this);
        this.onEditUserSubmit = this.onEditUserSubmit.bind(this);

   }

   componentDidMount() {
    if (this.props.isUserEdit) {
        if (this.props.selectedUser != null) {
            var selectedUser = this.props.selectedUser;
            this.setState({
                firstName: selectedUser.firstName,
                lastName: selectedUser.lastName,
                email: selectedUser.email,
                userId: selectedUser.username
            })
        }
    }
   }
 
    onAddUserSubmit = async (e) => {
        e.preventDefault();

        this.setState({fnameError:"", lnameError: "", emailError: ""})

        if (this.state.firstName === ""){
            this.setState({fnameError: "First name is required"})
            return;
        }

        if (this.state.lastName === ""){
            this.setState({lnameError: "Last name is required"})
            return;
        }

        if (this.state.email === ""){
            this.setState({emailError: "Email is required"})
            return;
        }

        if (!validateEmail(this.state.email)){
            this.setState({emailError: "Email is not valid"})
            return;
        }


        //create user if admin
        //add group to user
        //create a profile
        try {
            let token = await authManager.getAccessToken();
            token = 'Bearer' + " " + token;
            var myHeaders = new Headers();
            myHeaders.append("Authorization", token);
            myHeaders.append("Content-Type", "application/json");
            var raw = JSON.stringify({
                "userName": this.state.email,
                "email": this.state.email,
                "confirmEmail": true,
                "firstName": this.state.firstName,
                "lastName": this.state.lastName
            });
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            let { response, data } = fetchInterceptor(config.url.API_URL + "/users/create", requestOptions);
            this.props.userAdded("User added!");

        } catch (e) {
            console.log(e);
        }


    }

    onEditUserSubmit = async (e) => {
        e.preventDefault();

        this.setState({fnameError:"", lnameError: "", emailError: ""})

        if (this.state.firstName === ""){
            this.setState({fnameError: "First name is required"})
            return;
        }

        if (this.state.lastName === ""){
            this.setState({lnameError: "Last name is required"})
            return;
        }

        
        //update a profile
        const id = this.state.userId;
        const firstName = this.state.firstName;
        const lastName = this.state.lastName;

        try {

            let token = await authManager.getAccessToken();
            token = 'Bearer' + " " + token;
            var myHeaders = new Headers();
            myHeaders.append("Authorization", token);
            myHeaders.append("Content-Type", "application/json-patch+json");
            myHeaders.append("Accept", "application/json");
            let ops = [];
            ops.push({
                "operationType": 2,
                "path": "/FirstName",
                "op": "replace",
                "from": "<string>",
                "value": firstName
            });
            ops.push({
                "operationType": 2,
                "path": "/LastName",
                "op": "replace",
                "from": "<string>",
                "value": lastName
            });

            var raw = JSON.stringify(ops);

            var requestOptions = {
                method: 'PATCH',
                headers: myHeaders,
                body: raw
            };
            await fetchInterceptor(config.url.API_URL + "/VerimpactAPI/UpdateProfile/" + this.state.userId, requestOptions);
        } catch (e) {
            console.log(e);
        }

        this.props.userAdded("User updated!");

    }


    render() {
        return (
        <div className="add-user-popup">
            <div className="add-user-popup_open">

            <div>
                <img onClick={() => this.props.closePopup()} className="close-icon" alt="" src={CloseIcon}></img>
            </div>

            <div className="admin-add-user-container">
                <br/>
                <h3 className="admin-add-new-user-title">{this.props.isUserEdit ? "Edit user" : "Add new user"}<span style={{color: '#2E2EFF'}}>.</span></h3>
              
                <div className={`admin-add-user-field-container`}>
                    <p className="admin-add-user-field-label">First Name</p>
                    <div className={`admin-add-user-input-container ${this.state.fnameError !== "" ? "_aauic-error" : ""}`}>
                        <div className={`admin-add-user-input-user-icon ${this.state.firstName == "" ? "" : "admin-add-user-input-user-icon-filled"}`}/>
                        <input 
                            type="text"
                            onChange={(e) => this.setState({firstName: e.target.value})}
                            value={this.state.firstName}
                            className="admin-add-user-input"
                            placeholder="Enter user first name"
                        />
                    </div>
                    {this.state.fnameError != "" ? <div className="add-user-error-text">{this.state.fnameError}</div> : ""}
                </div>
                <div className={`admin-add-user-field-container`}>
                    <p className="admin-add-user-field-label">Last Name</p>
                    <div className={`admin-add-user-input-container ${this.state.lnameError !== "" ? "_aauic-error" : ""}`}>
                    <div className={`admin-add-user-input-user-icon ${this.state.lastName == "" ? "" : "admin-add-user-input-user-icon-filled"}`}/>
                        <input 
                            type="text"
                            onChange={(e) => this.setState({lastName: e.target.value})}
                            value={this.state.lastName}
                            className="admin-add-user-input"
                            placeholder="Enter user last name"
                        />
                    </div>
                    {this.state.lnameError !== "" ? <div className="add-user-error-text">{this.state.lnameError}</div> : ""}
                </div>
                <div className={`admin-add-user-field-container`}>
                    <p className="admin-add-user-field-label">Email</p>
                    <div className={`admin-add-user-input-container ${this.state.emailError !== "" ? "_aauic-error" : ""}`}>
                    <div className={`admin-add-user-input-email-icon ${this.state.email === "" ? "" : "admin-add-user-input-email-icon-filled"}`}/>
                        <input 
                            disabled={this.props.isUserEdit ? true : false}
                            title={this.props.isUserEdit ? "You cannot edit the email address" : ""}
                            type="email"
                            onChange={(e) => this.setState({email: e.target.value})}
                            value={this.state.email}
                            className="admin-add-user-input"
                            placeholder="example@mail.com"
                        />
                    </div>
                    {this.state.emailError !== "" ? <div className="add-user-error-text">{this.state.emailError}</div> : ""}
                </div>
                
                <br/>
       
                <button onClick={(e) => {this.props.isUserEdit ? this.onEditUserSubmit(e) : this.onAddUserSubmit(e)}} className="add-user-submit-button">{this.props.isUserEdit ? "Update user" : "Add user"}</button>

           
            
            </div>  
            
            
            
            </div>
        </div>
        );
    }
}
export default AddUserPopUp;
