import React, { Component } from "react";
import "./SurveyQuestionsSections.css";
 
import checkedIcon from '../../../../assets/survey/q_checked.svg';
import unCheckedIcon from  '../../../../assets/survey/q_unchecked.svg';
import * as sectionIcons from  '../../../../assets/survey/sections/';
import radioFilled from "../../../../assets/survey/radio_filled.svg";
import radioIdle from "../../../../assets/survey/radio_idle.svg";


class SurveyQuestionsSections extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentSectionIndex: 0,
      sectionItems: [],
      selectedFilter: 'all',
      filterPopUpOption: "all",
      hasFilterPopUp: false,
    };
    this.changeSelectedSection = this.changeSelectedSection.bind(this);

    this.listRef = React.createRef();
  }

  componentDidUpdate(prevProps,prevState){
    const { hasFilterPopUp } = this.state;



    setTimeout(() => {
      if(hasFilterPopUp){
        window.addEventListener('click', this.closeFilterPopUp)
      }
      else{
        window.removeEventListener('click', this.closeFilterPopUp)
      }
    }, 0)
  }

  componentWillReceiveProps(nextProps) {

    //CREATE SECTION REFS TO SCROLL TO THEM 
    //if (this.listRefs == null || this.listRefs.length != nextProps.sectionItems.length) {
      this.listRefs = nextProps.sectionItems.reduce((acc, current, index) => {
        acc[index] = React.createRef();
        return acc;
    }, {});
    //}
 

    if (nextProps.sectionItems !== this.state.sectionItems) {
      this.setState({
        sectionItems: nextProps.sectionItems
      }, function() {
        
      });  
    }

    //console.log(this.state.sectionItems);

  }

    changeSelectedSection(item, index) {
    this.setState({selectedSection: item,currentSectionIndex: index});
    this.notifySectionChanged(item,index);
  }

  notifySectionChanged(item,index){
    this.props.onChangeSection(item,index);
    this.selectedSectionScroll(index);
  }

  selectedSectionScroll(index) {
    // console.log(this.listRefs);
    //if (this.listRefs[index] !== undefined) {
      this.listRefs[index].current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    //}
  }

 
  renderSectionItem(item,index) {
    var allAnswered = false;
    if (item.questions != null) {
      var sectionAnswers =  [];

      //  console.log("item", item);
      //  console.log("index", index);
      //  console.log("questions", this.props.questionItems);
      //  console.log("answers", this.props.answersItems);

        this.props.questionItems.map((question) => {
            this.props.answersItems.map((answer) => {
                if (answer.sectionGuid === item.sectionGuid && answer.questionGuid == question.questionGuid) {
            sectionAnswers.push(answer);
          }
        });

      });
      
      //console.log(sectionAnswers);
      var questionsOnSection = 0;
      var answersOnSection = 0;
        var allAnswered = false;
      item.questions.forEach(question => {
              questionsOnSection ++;
          sectionAnswers.forEach(answer => {
              if (answer.questionGuid === question.questionGuid) {
                  answersOnSection ++;
                }
              });
          
      });

      if (questionsOnSection == answersOnSection) {
        allAnswered = true;
      }
      


      // console.log(item.abbreviation);
      // console.log(sectionAnswers.length);
      // console.log(item.questions.length);
      //allAnswered = sectionAnswers.length === item.questions.length;
    }

    //console.log(allAnswered);

      try {
          return (<div ref={this.listRefs[index]} key={item.sectionGuid} onClick={(e) => this.changeSelectedSection(item, index)} className="survey-section-item">

            <img alt="" src={allAnswered ? checkedIcon : unCheckedIcon}></img>
              <img alt="" className="survey-section-type-icon" src={sectionIcons[`${item.abbreviation}`]}></img>
              <h3 className={`survey-section-item-text ${this.props.currentSection == null ? "" : this.props.currentSection.sectionGuid == item.sectionGuid ? "survey-section-item-text-selected" : ""}`}>{`${item.abbreviation == "GOV" ? "" : `${item.abbreviation} -`}  ${item.title}`}</h3>
  
      </div>) 
    } catch (e) {
      return (<div></div>)
    }
  }

  changeSelectedFilter(value) {
    // if (value === "unanswered"){
    //   this.setState({hasFilterPopUp: true});
      // }
      this.setState({ selectedFilter: value });
    if (value == "all") {
      this.props.onFilterChange(false);
    } else {
      this.props.onFilterChange(true);
    }
  }

    renderFilterPopUp() {
    return (<div className="card-item survey-filter-popup-container" >
      <div className="card-container">
          <div style={{display:'flex',flexDirection:'column',justifyContent:'center',height:'100%'}}>
            <div onClick={(e) => {this.setState({filterPopUpOption: "all"})}} className="survey-filter-popup-item">
              <img alt="" src={this.state.filterPopUpOption === "all" ? radioFilled : radioIdle}></img>
              <p className="survey-filter-popup-item-text">All</p>
            </div>
            <div onClick={(e) => {this.setState({filterPopUpOption: "current"})}} className="survey-filter-popup-item">
              <img alt=""src={this.state.filterPopUpOption === "all" ? radioIdle : radioFilled}></img>
              <p className="survey-filter-popup-item-text">Current Section</p>
            </div>
          </div>
      </div>
    </div>);
  }

  closeFilterPopUp = () => {
    this.setState({
      hasFilterPopUp: false,
    });
  }

    render() {
    return (
      <div className="card-item filters-sections-container">
        <div className="survey-filters-container">
          <div>
            <h3 className="survey-questions-filter-text">Filter Questions</h3>
          </div>
          <div className="survey-questions-filter-card">
            <div className="card-container surevy-currentQuestions-filter-container">
              <div className="survey-questions-filter-options-container">
                <div onClick={(e) => {this.changeSelectedFilter("all")}} className={`survey-questions-filter-option-text ${this.state.selectedFilter === "all" ? "survey-questions-filter-option-text-selected" : ""}`}>
                  All
                </div>
                <div onClick={(e) => {this.changeSelectedFilter("unanswered")}} className={`survey-questions-filter-option-text ${this.state.selectedFilter === "unanswered" ? "survey-questions-filter-option-text-selected" : ""}`}>
                  Unanswered
                </div>
              </div>
            </div>
          </div>
          {/*{this.state.hasFilterPopUp ? this.renderFilterPopUp() : null}*/}
        </div>
      <div className="survey-questions-section-card">
        <div className="card-container" >
            <div>
                <p className="survey-section-title">Sections</p>
            </div>
            <div ref={this.listRef} className="survey-section-items-list">
                {this.state.sectionItems.map((item,index) => {
                    return this.renderSectionItem(item,index);
                })}
            </div>
        </div>
      </div>
    </div>
  );
  }
}

export default SurveyQuestionsSections;
