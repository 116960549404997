import React from "react";
import "./ConfirmationPopup.css";

class ConfirmationPopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
   }

   
    render() {
        return (
        <div className="confirm-popup ">
            <div className="confirm-popup_open">

               <div className="confirm-container">

                    <div className="confirm-title" dangerouslySetInnerHTML={{__html: this.props.confirmTitle}}/>
                    <div className="confirm-message">{this.props.confirmMessage}</div>

                    <div className="confirm-buttons-container">
                        <button className="confirm-button" onClick={() => this.props.closePopup()}>Continue</button>  
                        
                        <button className="confirm-button" onClick={() => this.props.onConfirmSubmit()}>Submit</button>
                    </div>

               </div>

            </div>
        </div>
        );
    }
}
export default ConfirmationPopup;
