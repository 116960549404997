/**
 * Decodes a JWT token and returns the payload as JSON.
 *
 * @param token the token
 */
export function decodeTokenPayload(token: string): any {
    if (!token) {
        return undefined;
    }
    const base64Url = token.split('.')[1];
    if (!base64Url) {
        return undefined;
    }
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    try {
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map((c) => {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );

        return JSON.parse(jsonPayload);
    } catch (e) {
        return undefined;
    }
}

