import React, { Component } from "react";
import surveyArrowLeft from '../../../../assets/survey/surveyArrowLeft.svg';
import surveyArrowRight from  '../../../../assets/survey/surveyArrowRight.svg';
import SlideRangeSelect from "../../../CustomItems/SlideRangeSelect";
import SlideRangeStepSelect from "../../../CustomItems/SlideRangeStepSelect";
import radioFilled from "../../../../assets/survey/radio_filled.svg";
import radioIdle from "../../../../assets/survey/radio_idle.svg";
import { ANSWER_TYPE_CURRENCY, ANSWER_TYPE_PERCENTAGE, ANSWER_TYPE_PERCENTAGE_MINUS_HANDREND, ANSWER_TYPE_SCALE, ANSWER_TYPE_SLIDER_10, ANSWER_TYPE_YES_NO } from "../../../../constants";
import CurrencyValueSelect from "../../../CustomItems/CurrencyValueSelect";
import * as sectionIcons from '../../../../assets/survey/sections/';
import { authManager } from "../../../Auth/AuthManager";
import fetchInterceptor from "../../../../utils/FetchClient";
import config from "../../../../constants";
import "./SurveyQuestionsAnswers.css";

class SurveyQuestionsAnswers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            answerValue: null,
            userAnswered: false,
            questionIndex: 0,
            currentQuestions: [],
            errorCode: '',
            previousText: '',
            nextText: '',
            username: '',
        };
        this.onChangeRange = this.onChangeRange.bind(this);
        this.onScaleChange = this.onScaleChange.bind(this);
        this.onChangeRangeStep = this.onChangeRangeStep.bind(this);
        this.onCurrencyValue = this.onCurrencyValue.bind(this);
        this.changeQIndexNext = this.changeQIndexNext.bind(this);
        this.changeQIndexPrevious = this.changeQIndexPrevious.bind(this);

        this.answersOuterContainer = React.createRef();

        //console.log(localStorage.getItem('userID'));
    }


    async createOrUpdateAnswer(question) {
        if (this.state.answerValue == null && this.state.userAnswered == false) {
            //DONT DO ANYTHING IF THE USER HAS NOT YET ANSWERED
            return;
        }

        var answerExist = this.props.answersItems.find((value, index) => value.questionGuid == question.questionGuid && value.identityGuid == this.props.currentUser);
        if (answerExist != null && answerExist.answerValue == this.state.answerValue) {
            //DONT DO ANYTHING IF VALUE DIDN"T CHANGE
            return;
        }

        let username = this.props.currentUser;
        let questionID = question.questionGuid;
        let sectionID = this.props.currentSection.sectionGuid;
        let dateAnswered = new Date();
        let answerType = question.questionType;
        let answerValue = this.state.answerValue;
        if (answerExist == null) {
            let token = await authManager.getAccessToken();
            token = 'Bearer' + " " + token;
            var myHeaders = new Headers();
            myHeaders.append("Authorization", token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "answerType": answerType,
                "answerValue": answerValue,
                "questionGuid": questionID,
                "sectionGuid": sectionID,
                "identityGuid": username,
                "dateAnswered": dateAnswered
            });
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            await fetchInterceptor(config.url.API_URL + "/VerimpactAPI/AddAnswer", requestOptions);
            this.props.onAnswersUpdate();

        } else {
            let token = await authManager.getAccessToken();
            token = 'Bearer' + " " + token;
            var myHeaders = new Headers();
            myHeaders.append("Authorization", token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "answerType": answerType,
                "answerValue": answerValue,
                "questionGuid": questionID,
                "sectionGuid": sectionID,
                "identityGuid": username,
                "surveyAnswerId": answerExist.surveyAnswerId,
                "dateAnswered": dateAnswered
            });
            var requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            await fetch(config.url.API_URL + "/VerimpactAPI/UpdateAnswer", requestOptions);
            this.props.onAnswersUpdate();
        }
    }

    //END OF CALLS

    changeSection(section) {
        const firstQuestionOfSectionIndex = this.state.currentQuestions.findIndex(question => question.sectionGuid === section.sectionGuid);
        this.setState({ questionIndex: firstQuestionOfSectionIndex });
    }

    componentDidMount() {
        //disable left click
        this.answersOuterContainer.current.addEventListener('contextmenu', event => event.preventDefault());
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.questionItems !== this.state.questionItems) {
            this.setState({
                currentQuestions: nextProps.questionItems
            }, function () { });
        }
    }



    componentDidUpdate(prevProps, prevState) {

        if (this.props.questionItems.length != prevProps.questionItems.length) {
            this.checkIfQuestionAnswered(this.props.questionItems[0]);
        }
    }

    async checkIfQuestionAnswered(question) {
        var answerExist = this.props.answersItems.find((value, index) => value.questionGuid == question.questionGuid && value.identityGuid == this.props.currentUser);
        if (answerExist != null) {
            if (this.state.answerValue !== answerExist.answerValue) {
                await this.setState({ answerValue: answerExist.answerValue, userAnswered: true });
            }
        } else {
            await this.setState({ answerValue: null, userAnswered: false });
        }
   //     console.log("Current Answer: " + this.state.answerValue + " by user: " + this.state.userAnswered);
    }

    onChangeRange(value) {
        this.setState({ answerValue: value, userAnswered: true });
    }

    onScaleChange(value) {
        this.setState({ answerValue: value, userAnswered: true })
    }

    onChangeRangeStep(value) {
        this.setState({ answerValue: value, userAnswered: true });
    }

    onCurrencyValue(value) {
        this.setState({ answerValue: value, userAnswered: true });
    }

    renderQuestionItem() {
        if (typeof this.state.currentQuestions[this.state.questionIndex] === 'undefined') {
            return <div></div>;
        }

        let item = this.state.currentQuestions[this.state.questionIndex];
        let sectionTitle = "";
        let sectionSub = "";
        let isMandatory = "";
        if (this.props.currentSection != null) {
            sectionTitle = this.props.currentSection.abbreviation;
            sectionSub = this.props.currentSection.title;
            isMandatory = item.isMandatory ? "survey-question-mandatory" : "";
        }

        let answerType = this.renderQuestionAnswerYesNo();
        if (item.type == ANSWER_TYPE_YES_NO) {
            answerType = this.renderQuestionAnswerYesNo();
        } else if (item.questionType == ANSWER_TYPE_SLIDER_10) {
            answerType = this.renderQuestionAnswerSlider();
        } else if (item.questionType == ANSWER_TYPE_SCALE) {
            answerType = this.renderQuestionAnswerScale();
        } else if (item.questionType == ANSWER_TYPE_PERCENTAGE) {
            answerType = this.renderQuestionAnswerPercentage(item);
        } else if (item.questionType == ANSWER_TYPE_CURRENCY) {
            answerType = this.renderCurrencyField();
        }

        return (<div className={"survey-question-container " + isMandatory}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className="survey-question-title"><img style={{ marginTop: '30px' }} src={sectionIcons[`${item.sectionAbbr}`]} width="88" height="88" /></div>
                <div className="survey-question-title">{sectionTitle} - {sectionSub}</div>
            </div>
            <div>
                <p className="survey-question-text-p">{item.question}</p>
            </div>
            <div className="survey-answers-options-container">
                {answerType}
            </div>
            <div className="survey-answers-counter-container">
                {this.rednerQuestionCounterText()}
            </div>
        </div>);
    }

    renderQuestionAnswerYesNo() {
        return (<div className="survey-answer-one-container">
            <div onClick={() => { this.setState({ answerValue: 1 }) }} className={`survey-answer-button ${(this.state.answerValue == 1) ? "survey-answer-button-selected" : ''}`}>
                Yes
        </div>
            <div style={{ width: '10px' }} />
            <div onClick={() => { this.setState({ answerValue: 0 }) }} className={`survey-answer-button ${(this.state.answerValue == 0) ? "survey-answer-button-selected" : ''}`}>
                No
        </div>
        </div>);
    }

    renderQuestionAnswerPercentage(item) {
        return (<div className="survey-answer-two-container">
            <SlideRangeSelect min={item.minValue} max={item.maxValue} step={0.5} indicator={item.indicator} rangeValue={this.state.answerValue == null ? 0 : this.state.answerValue} userAnswered={this.state.userAnswered} onChangeRange={this.onChangeRange} />
        </div>);
    }


    renderQuestionAnswerScale() {
        var scaleItems = [0, 1, 2, 3, 4];

        let scaleSelections = scaleItems.map((item, index) => {
            var borderClass = "survey-scale-item-border-mid";

            if (index === 0) {
                borderClass = "survey-scale-item-border-start";
            }

            if (index === scaleItems.length - 1) {
                borderClass = "survey-scale-item-border-end";
            }

            var selectedClass = "";

            if (item === this.state.answerValue) {
                selectedClass = "survey-scale-item-selected";
            }

            return (<div key={`scale_item${index}`} onClick={(e) => { this.onScaleChange(item) }} className={`survey-scale-item ${borderClass} ${selectedClass}`}>
                <div>{item}</div>
            </div>)
        })

        return (<div className="survey-answer-three-container">
            {scaleSelections}
        </div>);
    }

    renderCurrencyField() {
        return (<div>
            <CurrencyValueSelect currencyValue={this.state.answerValue} onCurrencyValue={this.onCurrencyValue} userAnswered={this.state.userAnswered} fieldType={""} />
            <br />
            {/*<p className="survey-answer-explain-text">All answers need to be to 2 decimal place. <br/>
All answers need to converted from your local currency to euros.</p>*/}

        </div>)
    }

    changeQIndexNext() {
        this.createOrUpdateAnswer(this.props.questionItems[this.state.questionIndex]);

        var nextIndex = this.state.questionIndex + 1;


        if (this.state.currentQuestions.length - 1 === this.state.questionIndex) {
            //WE ARE IN THE LAST QUESTION SO WE GO BACK TO THE FIRST ONE
            this.setState({ questionIndex: 0 }, () => {
                //CHECK IF THE QUESTION IS ANSWERED
                this.checkIfQuestionAnswered(this.props.questionItems[0]);
            });
            this.props.updateSection(0);
        } else {
            this.setState({ questionIndex: nextIndex }, () => {
                //CHECK IF THE QUESTION IS ANSWERED
                this.checkIfQuestionAnswered(this.props.questionItems[nextIndex]);
            });
            this.props.updateSection(nextIndex);
        }
    }

    changeQIndexPrevious() {
        this.createOrUpdateAnswer(this.props.questionItems[this.state.questionIndex]);

        if (this.state.questionIndex === 0) {
            //WE ARE IN THE FIRST QUESTION SO WE GO BACK TO THE LAST SURVEY QUESTION
            let lastQuestionIndex = this.state.currentQuestions.length - 1;
            this.setState({ questionIndex: lastQuestionIndex }, () => {
                //CHECK IF THE QUESTION IS ANSWERED
                this.checkIfQuestionAnswered(this.props.questionItems[lastQuestionIndex]);
            });
            this.props.updateSection(lastQuestionIndex);
        } else {
            var prevIndex = this.state.questionIndex - 1;
            this.setState({ questionIndex: prevIndex }, () => {
                //CHECK IF THE QUESTION IS ANSWERED
                this.checkIfQuestionAnswered(this.props.questionItems[prevIndex]);
            });
            this.props.updateSection(prevIndex);
        }
    }

    changeErrorCode(error) {
        this.setState({ errorCode: error });
        this.props.onErrorCode(error);
    }


    renderQuestionAnswerSlider() {
        return (<div className="survey-answer-fourth-container">
            <SlideRangeStepSelect rangeValue={(this.state.answerValue == null ? 0 : this.state.answerValue)} userAnswered={this.state.userAnswered} onChangeRange={this.onChangeRangeStep} />
        </div>);
    }

    rednerQuestionCounterText() {
        if (this.state.currentQuestions.length == 0) {
            return '';
        }

        return `${this.state.questionIndex + 1}/${this.state.currentQuestions.length}`;

    }

    render() {
        return (<div>
            <div className="survey-questions-outer">

                <div className="survey-questions-container-inner">
                    <div onClick={(e) => { this.changeQIndexPrevious() }} className="survey-question-count-container">
                        <img src={surveyArrowLeft} alt="Previous"></img>
                        <span className="survey-question-count-text">Previous</span>
                    </div>
                    <div id="answers_container" className={`card-item survey-answers-container unselectable`} ref={this.answersOuterContainer}>
                        <div className={`card-container ${this.state.errorCode !== "" ? "survey-answer-error" : ""} `} >
                            {this.state.currentQuestions.length > 0 ?
                                this.renderQuestionItem(this.state.questionIndex) : null}
                        </div>
                    </div>
                    <div onClick={(e) => { this.changeQIndexNext() }} className="survey-question-count-container">
                        <img src={surveyArrowRight} alt="Next"></img>
                        <span className="survey-question-count-text">Next</span>
                    </div>
                </div>
            </div>

        </div>);
    }
}

export default SurveyQuestionsAnswers;
