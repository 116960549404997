import React from "react";
import "./CookieNoticePopup.css";

class CookieNoticePopup extends React.Component {

  constructor () {
    super();
    this.setCookie = this.setCookie.bind(this);
    this.getCookie = this.getCookie.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  setCookie () {
    var name = 'cookies';
    var value = 'allow';
    var days = 10;
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    return '';
  }

  getCookie (name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)===' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  handleSubmit() {

    this.setCookie();

    this.props.closeCookieNotice();
  }



  render() {
    if (this.getCookie('cookies')) {
      return (false);
    } else {
      return (

        <div className="cookie_notice">
          <div className="cookie-text">
            <img src="/assets/images/cookie.png" width="45" height="45" alt="Cookie Notice" /> We use cookies to ensure that we give you the best experience on our website. <span onClick={this.props.openCookiePolicy}>Read cookies policies.</span>
          </div>
          <div className="accept-button-container">
            <button onClick={this.props.closeCookieNotice} className="decline-button">Decline</button>
            <button onClick={this.handleSubmit} className="accept-button">Allow</button>
          </div>
        </div>
      );
    }
  }
}
export default CookieNoticePopup;