import React, { Component } from "react";
import './ThankYouPage.css';

class ThankYouPage extends Component {
  render() {
    return <div className="thank-you-page-container">
        <div className="thank-you-div">
            <p className="thank-you-heading">Thank you for <br/> getting in touch.</p>
            <p className="thank-you-message">We will aim to respond to you <br/> as soon as possible.</p>
        </div>
    </div>;
  }
}

export default ThankYouPage;
