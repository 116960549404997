import React, { Component } from "react";
import DropdownSelect from "../DropdownSelect";
import "./CurrencyValueSelect.css";

import CurrenciesList from '../../lists/CurrenciesList';
 

class CurrencyValueSelect extends Component {

  constructor(props) {
    super(props);
    this.state = {
        currencies: [],
        selectedCurrency: '',
        value: '',
        userAnswered: false,
    };

    //this.getUserAnswered();
    //this.inputCurRef = React.createRef();

    //this.onOverlayClick = this.onOverlayClick.bind(this);
  }

  async getUserAnswered() {
    await this.setState({userAnswered: this.props.userAnswered});
  }

  componentDidMount() {
    this.getUserAnswered();
      //const currencies = CurrenciesList.CurrenciesList;
      //var fixedCurrencies = [];
 
      //currencies.forEach((currency,index) => {
      //      currency.id = index + 1;
      //      currency.selected = false;
      //      fixedCurrencies.push(currency);
      //});

      //var preselectedCur = fixedCurrencies.find((item) => item.title === "EUR");

      //console.log(this.props.currencyValue);
     // this.setState({currencies: fixedCurrencies,selectedCurrency: preselectedCur.title});

      this.showFieldValue(this.props.currencyValue, this.props.userAnswered);
  }


    showFieldValue(newVal, userAnswered) {
        //if (newVal == 0) {
            this.setState({value: newVal, userAnswered: userAnswered});
        //} else if ( newVal > 0) {
        //    this.setState({value: newVal});
        //} else {
        //    this.setState({value: ''});
        //}
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.currencyValue !== this.props.currencyValue) {
          this.showFieldValue(this.props.currencyValue, this.props.userAnswered);
        }
      } 



  // resetThenSet = (id, key) => {

  //   console.log("id",id);
  //   console.log("key",key);

  // }

       

    // renderInput() {

    //     //console.log("Value:" + this.state.value);
    //     var num = this.state.value;
    //     num = num.toString().replace(".", "");

    //     var fixedNumber = "";
    //     var textChars = [];
    //     var zeroValue = false;

    //     if (num == "0") {
    //         fixedNumber = "0";
    //         zeroValue = true;
    //         var color = "black";

    //         textChars.push(<span key={`input_value_1`} style={{color: color }}>0</span>);

    //     } else if (num !== "") { 
    //         var stepNumber = parseFloat(num / 100);
    //         fixedNumber = stepNumber.toFixed(2).toString();

    //         for (var i = 0; i < fixedNumber.length; i++) {
    //             var color = "black";

    //             if (fixedNumber.charAt(i) === "0") {
    //                 color = "#CCCCCC";
                   
    //                 if (fixedNumber.charAt(0) !== "0") {
    //                     color = "black";
    //                 } else {
    //                     if (fixedNumber.charAt(i-1) !== "0" && fixedNumber.charAt(i-1) !== "." && fixedNumber.charAt(i-1) !== ""){
    //                         color = "black";
    //                     }
    //                 }
    //             }

    //             if (fixedNumber.charAt(i) === ".") { 
    //                 if (fixedNumber.charAt(0) !== "0") {
    //                     color = "black";
    //                 } else {
    //                     color = "#CCCCCC";
    //                 }
    //             }



    //             textChars.push(<span key={`input_value_${i}`} style={{color: color }}>
    //                 {fixedNumber.charAt(i)}
    //             </span>);
    //         }
    //     }

        
    
    //         return (<div>
    //            {textChars}
    //         </div>)

    // }

    // onOverlayClick() {
    //     this.inputCurRef.current.focus();
    // }

    onChangeValue(value) {
        this.setState({value: value, userAnswered: true})
        this.props.onCurrencyValue(value);
    }

    render() {
        return (<div className={`currency-value-select ${this.state.value == "" ? "" : "currency-value-select-filled"}`}>
            <div className="currency-value-select-inner">

                {/* <DropdownSelect 
                    className="currency-value-dropdown"
                    title={this.state.selectedCurrency}
                    list={this.state.currencies}
                    resetThenSet={this.resetThenSet}
                    onChange={(value) => this.setState({selectedCurrency: value})}
                /> */}

                <div className="currency-type-value">{this.props.fieldType}</div>

                <div>
                    <div className="cur-value-container">
                    
                        <input
                            ref={this.inputCurRef}
                            className="currency-value-input"
                            type='number' 
                            step="0.01"
                            value={(this.state.userAnswered == false ? "" : this.state.value)}
                            onChange={(e) => this.onChangeValue(e.target.value)}
                            placeholder='0.00'
                        />
                        {/*<div onClick={() => {this.onOverlayClick()}} style={{display: `${this.state.value == "" ? "none" : "block"}`}} className="cur-value-input-overlay">{this.renderInput()}</div>*/}
                    </div>
                </div>
                

            



            </div>
        </div>);
  }
}

export default CurrencyValueSelect;
