import React, { Component } from "react";
import "./GaugeProgress.css";
import prgoressPointerIcon from '../../../assets/svg/progress_pointer.svg'; 

class GaugeProgress extends Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.radialContainer = React.createRef();
    this.radialGauge = React.createRef();
    this.gaugeContainer = React.createRef();
    this.radialCircle = React.createRef();
  }

  componentDidMount() {
    var gauge = this.Gauge(this.radialGauge.current);
			
			gauge.value(this.props.percentage != null ? this.props.percentage / 100 : 0);
			// this.gaugeContainer.current.addEventListener("mouseover", function() {
			// 	gauge.value(0.75);
			// });
      // this.gaugeContainer.current.addEventListener("mouseout", function() {
			// 	gauge.value(0.25);
			// });
  }

  componentDidUpdate(prevProps, prevState) {
      if (prevProps.percentage !== this.props.percentage) {
        var gauge = this.Gauge(this.radialGauge.current);
        gauge.value(this.props.percentage != null ? this.props.percentage / 100 : 0);
      }
    }
  
  Gauge(el) {

    // ##### Private Properties and Attributes

    var element,      // Containing element for the info component
        data,         // `.gauge--data` element
        needle,       // `.gauge--needle` element
        value = 0.0,  // Current gauge value from 0 to 1
        prop;         // Style for transform

    // ##### Private Methods and Functions

    var setElement = function(el) {
        // Keep a reference to the various elements and sub-elements
        element = el;
        data = element.querySelector(".gauge--data");
        needle = element.querySelector(".gauge--needle");
    };

    var setValue = function(x) {
        value = x;
        var turns = -0.5 + (x * 0.5);
        data.style[prop] = "rotate(" + turns + "turn)";
        needle.style[prop] = "rotate(" + turns + "turn)";
    };

    // ##### Object to be Returned

    function exports() { };

    // ##### Public API Methods

    exports.element = function(el) {
        if (!arguments.length) { return element; }
        setElement(el);
        return this;
    };

    exports.value = function(x) {
        if (!arguments.length) { return value; }
        setValue(x);
        return this;
    };

    // ##### Initialization

    var body = this.radialContainer.current;
    ["webkitTransform", "mozTransform", "msTransform", "oTransform", "transform"].
        forEach(function(p) {
            if (typeof body.style[p] !== "undefined") { prop = p; }
        }
    );

    if (arguments.length) {
        setElement(el);
    }

    return exports;

  };

  render() {


    return (<div>
      <div ref={this.radialContainer}>
        <div ref={this.radialGauge} className="gauge gauge__liveupdate" id="gauge">
                <div className="gauge--container" id="gauge-container" ref={this.gaugeContainer}>
                  <div className="gauge--marker"></div>
                  <div className="gauge--background"></div>
                  <div className="gauge--center"></div>
                  <div className="gauge--data"></div>
                  <div className="gauge--needle"></div>
                </div>
                <div className="gauge--labels mdl-typography--headline">
                    <span className="gauge--label__low"></span>
                    <span className="gauge--label__spacer"></span>
                    <span className="gauge--label__high"></span>
                </div>
        </div>
       </div>    
    </div>);
  }
}

export default GaugeProgress;
