import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import { authManager } from "../Auth/AuthManager";
import config from "../../constants";
import "./ContactUsPage.css";
import * as yup from "yup";

class ContactUsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: "",
            email: "",
            city: "",
            jobTitle: "",
            companyName: "",
            website: "",
            phoneNumber: "",
            sector: "Other",
            emailSubject: "",
            emailBody: "",
            redirect: false,
        };

        this.schema = yup.object().shape({
            fullName: yup.string().required(),
            email: yup.string().email(),
            website: yup.string().url(),
        });

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({ [name]: value });
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const {
            fullName,
            email,
            city,
            jobTitle,
            companyName,
            website,
            phoneNumber,
            sector,
            emailSubject,
            emailBody,
        } = this.state;
        try {
            // let token = await authManager.getAccessToken();
            // token = 'Bearer' + " " + token;
            var myHeaders = new Headers();
            // myHeaders.append("Authorization", token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "fullName": fullName,
                "jobTitle": jobTitle,
                "email": email,
                "city": city,
                "companyName": companyName,
                "website": website,
                "sector": sector,
                "phoneNumber": phoneNumber,
                "emailSubject": emailSubject,
                "emailBody": emailBody
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            let { respone, data } = await fetch(config.url.API_URL + "/VerimpactAPI/AddCandidate", requestOptions);
            this.setState({ redirect: true });

        } catch (e) {
            console.log(e);
        }
    };

    render() {
        const { redirect } = this.state;

        if (redirect) {
            return <Navigate to="/thankyou" />;
        }

        return (
            <div className="contact-us-page-container verimpact-container">
                <div className="contact-us-page-form">
                    <h3 className="contact-us-page-title">Get in touch<span className="text-dot">.</span></h3>
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-row-1">
                            <div className="form-group-1">
                                <input
                                    className="contact-us-form-text-input"
                                    placeholder="Full Name*"
                                    name="fullName"
                                    type="text"
                                    value={this.state.fullName}
                                    onChange={this.handleChange}
                                    required="required"
                                />
                                <input
                                    className="contact-us-form-text-input"
                                    placeholder="Email*"
                                    name="email"
                                    type="text"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    required="required"
                                />
                            </div>
                            <div className="form-group-2">
                                <input
                                    className="contact-us-form-text-small-input"
                                    placeholder="Job Title*"
                                    name="jobTitle"
                                    type="text"
                                    value={this.state.jobTitle}
                                    onChange={this.handleChange}
                                    required="required"
                                />
                                <input
                                    className="contact-us-form-text-small-input"
                                    placeholder="City*"
                                    name="city"
                                    type="text"
                                    value={this.state.city}
                                    onChange={this.handleChange}
                                    required="required"
                                />
                            </div>
                        </div>
                        <div className="form-row-2">
                            <div className="form-group-3">
                                <input
                                    className="contact-us-form-text-input"
                                    placeholder="Company Name*"
                                    name="companyName"
                                    type="text"
                                    value={this.state.companyName}
                                    onChange={this.handleChange}
                                    required="required"
                                />
                                <input
                                    className="contact-us-form-text-input"
                                    placeholder="Subject*"
                                    name="emailSubject"
                                    type="text"
                                    value={this.state.emailSubject}
                                    onChange={this.handleChange}
                                    required="required"
                                />
                            </div>
                            <div className="form-group-4">
                                <input
                                    className="contact-us-form-text-small-input"
                                    placeholder="Website"
                                    name="website"
                                    type="text"
                                    value={this.state.website}
                                    onChange={this.handleChange}
                                />
                                <input
                                    className="contact-us-form-text-small-input"
                                    placeholder="Phone Number"
                                    name="phoneNumber"
                                    type="text"
                                    value={this.state.phoneNumber}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <input
                            className="contact-us-form-textarea-big-input"
                            placeholder="You message*"
                            name="emailBody"
                            type="textarea"
                            value={this.state.emailBody}
                            onChange={this.handleChange}
                            required="required"
                        />
                        <br />
                        <p className="form-required-info">*These fields are compulsory, please complete them to proceed.</p>
                        <input className="a-button-primary send-button" type="submit" value="Send" />
                    </form>
                </div>
            </div>
        );
    }
}

export default ContactUsPage;
