import React from "react";
import "./UserDataPopup.css";




import LinkIconColored from '../../../../assets/svg/link-icon-colored.svg';

import ViewLinkGrey from '../../../../assets/svg/view-link-grey.svg';

import UserIcon from '../../../../assets/svg/user-icon.svg';

class UserDataPopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          firstName: "",
          lastName: "",
          links: []
        }
   }

   componentDidMount() {
       //console.log(this.props);
        if (this.props.selectedUser != null) {
            var selectedUser = this.props.selectedUser;
            this.setState({
                firstName: selectedUser.firstName,
                lastName: selectedUser.lastName,
                links: selectedUser.links
            })
        }
   }

   
    render() {
        var userLinks = this.props.selectedUser.links?.split(', ');
        return (
        <div className="user-data-popup ">
            <div className="user-data-popup_open">

                <div style={{display: 'flex',flexDirection:'column',justifyContent:'space-between',alignItems:'center',height:'100%'}}>
                    <div className="user-data-container">
                            
                            <h3 className="user-data-links-title">Attached links.</h3>
                        
                            <div className="user-data-links-user-icon"></div>
                            
                            <p className="user-data-links-desc">{this.state.firstName + " " + this.state.lastName}</p>

                            {userLinks?.map((value, index) => {
                                return (<div key={`user_${this.props.selectedUser.id}_link_${index}`}>
                                        <div className="user-data-link-container">
                                                <div style={{display:'flex',alignItems:'center'}}>
                                                    <img style={{marginLeft: '19px'}} src={LinkIconColored} alt=""/>
                                                    <div className="user-data-link-text">{value}</div>
                                                </div>

                                                <a target="_blank" rel="noreferrer" style={{marginRight: '19px',cursor:'pointer'}}  href={value.indexOf("http") == 0 ? value : "https://" + value}>
                                                    <img style={{width:'15px',height:'15px'}} src={ViewLinkGrey} alt=""/>
                                                </a>
                                                
                                        </div> 
                                    </div>)
                            })}   

                    </div>
                    
                    <button className="user-data-back-button" onClick={() => this.props.closePopup()}>Back</button>  

                </div>
               

            </div>
        </div>
        );
    }
}
export default UserDataPopup;
