import React, { Component } from "react";
import "./DashboardResults.css";


class DashboardResults extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

 
  render() {

    var percentagesItems = [];
    var i =0;
    while (i<=100) {
        //CREATE THE LEFT SIDE ITEMS ADD EVERY 5 NUMBERS
        percentagesItems.push(
        <div key={`bar_chart_per_${i}`} className={` ${i === 0 ? "barchart-Time-no-line" : "barchart-Time"}`}>
        <span className="barchart-TimeText">{i}%</span>
        </div>
        );
        i+=5;
    }
  
    //REVERSE THE LSIT TO START FROM 0 TO 100
    percentagesItems.reverse();
 
    
    return (
            <div className="dashboard-card">
                <div className="results-container">
                    <h3 className="results-title">Results</h3>

                     <div>
                     <div className="barchart-Wrapper">
                            <div className="barchart-TimeCol">
                                {percentagesItems}
                            </div>

                            <div className="barChart-Container">
                                <div className="barchart">
                                    {this.props.chartItems != null ? 
                                    this.props.chartItems.map((item,index) => {
                                        return (<div key={`chart_item_${index}`} className="barchart-Col">
                                        <div className="barchart-Bar single-bar" style={{height:`${item.value}%`}}></div>
                                        <div className="barchart-BarFooter">
                                        <h3>{item.name}</h3>
                                        </div>
                                    </div>)
                                    }): null}
                                </div>
                            </div>
                            </div>
                     </div>

                </div>
            </div>
      );
  }
}

export default DashboardResults;
