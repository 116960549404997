import React, { Component } from "react";
import SearchIcon from "../../../assets/svg/search_icon.svg";
import DropdownSelect from "../../CustomItems/DropdownSelect";
import userListCheck from  '../../../assets/svg/user_list_check.svg';
import userListIcon from  '../../../assets/svg/user_list_icon.svg';
import PlusIcon from '../../../assets/svg/add_circle_icon_grey.svg';
import PlusIconBlack from '../../../assets/svg/add_circle_icon_black.svg';
import UserDeleteIcon from '../../../assets/svg/user-icons/user_delete_icon.svg';
import UserDownloadIcon from '../../../assets/svg/user-icons/user_download_icon.svg';
import UserEditIcon from '../../../assets/svg/user-icons/user_edit_icon.svg';
import UserEmailIcon from '../../../assets/svg/user-icons/user_email_icon.svg';
import UserUnverifiedIcon from '../../../assets/svg/user-icons/user_unverified_icon.svg';
import UserVerifiedIcon from '../../../assets/svg/user-icons/user_verified_icon.svg';
import UserFilesIcon from '../../../assets/svg/user-icons/user_files_icon.svg';
import UserDeleteIconDisabled from '../../../assets/svg/user-icons/user_delete_icon_disabled.svg';
import UserDownloadIconDisabled from '../../../assets/svg/user-icons/user_download_icon_disabled.svg';
import UserEditIconDisabled from '../../../assets/svg/user-icons/user_edit_icon_disabled.svg';
import UserEmailIconDisabled from '../../../assets/svg/user-icons/user_email_icon_disabled.svg';
import UserFilesIconDisabled from '../../../assets/svg/user-icons/user_files_icon_disabled.svg';
import UserReIcon from '../../../assets/svg/user-icons/user_re_icon.svg';
import MaterialSwitch from "../../CustomItems/MaterialSwitch";
import checkedIcon from '../../../assets/svg/user_select_icon_check.svg';
import unCheckedIcon from  '../../../assets/svg/user_select_icon_uncheck.svg';
import disabledCheckedIcon from '../../../assets/svg/disabled_user_selected_icon.svg';
import AddUserPopUp from "../AdminPopUp/AddUser/AddUserPopUp";
import AdminConfirmationPopup from "../AdminPopUp/Confirm/AdminConfirmationPopup";
import UserDataPopup from "../AdminPopUp/UserData/UserAddedPopup";
import AdminMessagePopup from "../AdminPopUp/Message/AdminMessagePopup";
import { authManager } from "../../Auth/AuthManager";
import fetchInterceptor from "../../../utils/FetchClient";
import config from "../../../constants";
import "./AdminManageUsersPage.css";

class AdminManageUsersPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: "",
            sortType: '',
            usersItems: [],
            sortType: null,
            showAddUserPopPup: false,
            showAdminMessagePopup: false,
            showUserDataPopup: false,
            selectedUser: null,
            selectedUsers: [],
            showConfirmationPopup: false,
            confirmType: "",
            messageTitle: "",
            messageType: "",
            isUserEdit: false,
        };

        this.sortTypes = [
            {
                id: 0,
                title: 'A - Z',
                selected: false,
            },
            {
                id: 1,
                title: 'Z - A',
                selected: false
            },
            {
                id: 2,
                title: 'Last Login',
                selected: false
            },
            {
                id: 3,
                title: 'Completed - Not Started',
                selected: false
            },
            {
                id: 4,
                title: 'Not Started - Completed',
                selected: false
            }
        ]

        this.toggleAdminMessagePopup.bind(this);
        this.toggleAddUserPopup.bind(this);
        this.userAdded.bind(this);
        this.onUserSelect.bind(this);
        this.hasSelectedUsers.bind(this);
        this.onConfirmSubmit.bind(this);
        this.onUserDelete.bind(this);


    }



    componentDidMount() {
        this.getUsers();
        // let userItemsListTemp = await this.getUsers();
        // this.setState({usersItems: userItemsListTemp});
        // console.log(userItemsListTemp);
        // console.log(this.state.usersItems);
    }


    resetThenSet() {

    }

    async setUsersList(users) {
        await this.setState({ usersItems: users });
        //console.log(this.state.usersItems);
    }

    getUsers = async (e) => {

        let usersList: [];
        try {
            let token = await authManager.getAccessToken();
            token = 'Bearer' + " " + token;
            var myHeaders = new Headers();
            myHeaders.append("Authorization", token);
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            let { response, data } = await fetchInterceptor(config.url.API_URL + "/AdminAPI/GetUsers", requestOptions);
            usersList = data;

            //console.log((await Auth.currentSession()).getAccessToken().getJwtToken());
            //get users based on their group
            //   Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`

            //userList loop to update state with data
            if (usersList.length > 0) {

                //   let usersDetails = await API.graphql({
                //     query: listUserDetails,
                //     authMode: 'AMAZON_COGNITO_USER_POOLS'
                //   });
                //   let usersDetails = authManager.getCurrentAuthenticatedUser();
                //  usersDetails = usersDetails.data.listUserDetails.items;

                var userItemsListTemp = [];
                //create list on state
                for (var i = 0; i < usersList.length; i++) {
                    var userItemTemp = [];
                    //console.log("true");
                    var status = "Not Started";
                    if (usersList[i].assessmentProgress > 0 && !usersList[i].isAssessmentComplete) {
                        status = "In Progress";
                    } else if (usersList[i].isAssessmentComplete) {
                        status = "Completed";
                        //   } else if (usersList.UserStatus == "FORCE_CHANGE_PASSWORD") {
                        //    status = "Not Verified";
                    }
                    userItemTemp = {
                        username: usersList[i].identityGuid,
                        firstName: usersList[i].firstName,
                        lastName: usersList[i].lastName,
                        email: usersList[i].email,
                        company: usersList[i].company,
                        status: status,
                        lastLogin: usersList[i].lastLogin,
                        checked: false,
                        active: usersList[i].active,
                        assessmentProgress: usersList[i].assessmentProgress,
                        links: usersList[i].links
                    };
                    //console.log(userItemTemp);
                    if (Object.keys(userItemTemp).length > 0) {
                        userItemsListTemp.push(userItemTemp);
                    }
                    //console.log(userItemsListTemp);


                } // userList loop ends


                //return userItemsListTemp;
                this.setUsersList(userItemsListTemp);


            } // end if length
        } catch (e) {
            console.log(e);
        }

    }

    handleSearch(keyword) {
        //console.log(keyword);
    }

    disableEnableUser = async (id, enabled) => {
        //disable user if admin
        let apiName = 'AdminQueries';
        let path = enabled ? '/enableUser' : '/disableUser';
        let myInit = {
            body: {
                "username": id
            },
            headers: {
                'Content-Type': 'application/json',
                //  Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        // let results = await API.post(apiName, path, myInit);
        let results: any;
        //console.log(results);
        //const username = results.results.User.Username;


    }

    onUserStatusChange(status, index) {
        //console.log(`USER_${index}`,status);
        let users = [...this.state.usersItems];
        users[index].active = status;
        this.setState({ usersItems: users });

        this.disableEnableUser(users[index].username, status);
    }

    // ADD USER POPUP FUNCTIONS
    openCloseAddUserPopup() {
        this.setState({
            showAddUserPopPup: false
        });
    }

    toggleAddUserPopup() {
        this.setState({
            isUserEdit: false,
            selectedUser: null,
            showAddUserPopPup: !this.state.showAddUserPopPup
        });
    }


    // ADMIN MESSAGE POPUP FUNCTIONS
    closeAdminMessagePopup() {
        this.setState({ showAdminMessagePopup: false })
    }

    toggleAdminMessagePopup() {
        this.setState({
            showAdminMessagePopup: !this.state.showAdminMessagePopup
        });
    }

    userAdded() {
        const userMessage = this.state.isUserEdit ? "User Updated!" : "User Added!";
        this.setState({ showAdminMessagePopup: true, messageTitle: userMessage, messageType: "user-add", showAddUserPopPup: false })
        this.getUsers();
    }


    // USER DATA POPUP
    onOpenUserDataPopup(user) {
        this.setState({ showUserDataPopup: true, selectedUser: user });
    }

    closeUserDataPopup() {
        this.setState({
            showUserDataPopup: false
        });
    }

    toggleUserDataPopup() {
        this.setState({
            showUserDataPopup: !this.state.showUserDataPopup
        });
    }

    //CONFIRM SUBMIT POPUP
    onConfirmSubmit() {
        if (this.state.confirmType === "delete") {
            let users = [...this.state.usersItems];
            let selectedUsers = this.state.selectedUsers;
            let filteredUsers = users.filter((user) => {
                var toAdd = true;
                selectedUsers.forEach(selectedUser => {
                    if (user.id === selectedUser.id) {
                        toAdd = false
                    }
                });
                return toAdd;
            });
            this.setState({
                usersItems: filteredUsers,
                showConfirmationPopup: false,
                confirmTitle: "",
                confirmType: "",
                selectedUser: null,
                showAdminMessagePopup: true,
                messageTitle: "User removed!",
                messageType: "user-delete",
                selectedUsers: []
            })
        } else if (this.state.confirmType === "confirm-email") {
            //TODO SEND CONFIRMATION EMAIL TO SELECTED USERS
            let selectedUsers = this.state.selectedUsers;
            let users = [...this.state.usersItems];

            users.map((value, index) => users[index].checked = false);

            this.setState({
                usersItems: users,
                showConfirmationPopup: false,
                confirmTitle: "",
                confirmType: "",
                selectedUser: null,
                showAdminMessagePopup: true,
                messageTitle: "Confirmation email sent!",
                messageType: "user-confirm-email",
                selectedUsers: []
            })
        }
    }

    closeConfirmationPopup() {
        this.setState({ showConfirmationPopup: !this.state.showConfirmationPopup });
    }


    //OTHER FUNCTIONS
    onUserSelect(user, index) {
        let users = [...this.state.usersItems];
        users[index].checked = !user.checked;
        this.setState({ usersItems: users })
    }

    hasSelectedUsers() {
        const selectedUsers = this.state.usersItems.find((value) => value.checked === true);
        return selectedUsers != null;
    }

    onUserDelete(user) {
        let selectedUsers = [];
        selectedUsers.push(user);
        this.setState({
            showConfirmationPopup: true,
            confirmTitle: "Are you sure you <br/> want to remove user?",
            confirmType: "delete",
            selectedUsers: selectedUsers
        });
    }

    onUserEdit(user) {
        this.setState({
            selectedUser: user,
            showAddUserPopPup: !this.state.showAddUserPopPup,
            isUserEdit: true,
        });
    }

    onUserConfirmEmail(user) {
        let selectedUsers = [];
        selectedUsers.push(user);
        this.setState({
            showConfirmationPopup: true,
            confirmTitle: "Are you sure you to <br/> send confirmation email?",
            confirmType: "confirm-email",
            selectedUsers: selectedUsers
        });
    }

    async onDownloadResults(user) {
        let token = await authManager.getAccessToken();
        token = 'Bearer' + " " + token;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        let assessment: any;
        let { response, data } = await fetchInterceptor(config.url.API_URL + "/VerimpactAPI/GetAssessmentResults/" + user.username, requestOptions);
        assessment = data;
        const questionsAnswers = JSON.parse(assessment.questionsWithAnswers);

        var csv = this.JSON2CSV(questionsAnswers);
        var downloadLink = document.createElement("a");
        var blob = new Blob(["\ufeff", csv]);
        var url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = user.firstName + "_" + user.lastName + "_" + "answers.csv";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

    }

    deleteMultipleUsers() {
        const selectedUsers = this.state.usersItems.filter((value) => value.checked === true);
        this.setState({
            showConfirmationPopup: true,
            confirmTitle: "Are you sure you <br/> want to remove users?",
            confirmType: "delete",
            selectedUsers: selectedUsers
        })
    }

    sendConfirmEmailToMultipleUsers() {
        const selectedUsers = this.state.usersItems.filter((value) => value.checked === true);
        this.setState({
            showConfirmationPopup: true,
            confirmTitle: "Are you sure you to <br/> send confirmation email?",
            confirmType: "confirm-email",
            selectedUsers: selectedUsers
        })
    }

    statusColor(status) {

        switch (status) {
            case "Not Verified":
                return "#ececec";
            case "Not Started":
                return "#BCBDFF";
            case "In Progress":
                return "#6B6CFE";
            case "Completed":
                return "#2E2EFF";
        }

    }

    JSON2CSV(objArray) {
        var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        var str = '';
        //console.log(array);

        str += "abbreviation;question;is Mandatory;answer\r\n";
        for (const answer of Object.keys(array)) {
            var line = '';
            //console.log(array[obj]);
            for (const item of Object.keys(array[answer])) {
                if (item == "questionId" || item == "answerId" || item == "type") continue;

                if (line != '') line += ';';

                if (array[answer]['type'] == 'currency' && item == "answer") {
                    line += (array[answer][item] / 100);
                } else {
                    line += array[answer][item].toString().replace(/[\r\n]/gm, "");
                }

            }

            str += line + '\r\n';
        }
        return str;
    }

    render() {

        return (<div className="verimpact-container">

            {this.state.showConfirmationPopup ?
                <AdminConfirmationPopup
                    closePopup={() => { this.closeConfirmationPopup() }}
                    confirmTitle={this.state.confirmTitle}
                    onConfirmSubmit={() => { this.onConfirmSubmit() }}
                />
                : null
            }

            {this.state.showUserDataPopup ?
                <UserDataPopup
                    closePopup={() => { this.closeUserDataPopup() }}
                    selectedUser={this.state.selectedUser}
                />
                : null
            }

            {this.state.showAddUserPopPup ?
                <AddUserPopUp
                    closePopup={() => { this.openCloseAddUserPopup() }}
                    userAdded={() => { this.userAdded() }}
                    selectedUser={this.state.selectedUser}
                    isUserEdit={this.state.isUserEdit}
                />
                : null
            }

            {this.state.showAdminMessagePopup ?
                <AdminMessagePopup
                    messageTitle={this.state.messageTitle}
                    closePopup={() => { this.closeAdminMessagePopup() }}
                />
                : null
            }

            <div className="admin-manage-users-container">
                <h3 className="admin-manage-users-title">Manage Users<span style={{ color: '#2E2EFF' }}>.</span></h3>
                <div className="admin-manage-users-search">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div onClick={() => this.toggleAddUserPopup()} className="admin-manage-users-input-container admin-manage-users-input-container-add-user">
                            <p style={{ opacity: `${this.state.showAddUserPopPup || (this.state.showAdminMessagePopup && this.state.messageType === "user-add") ? 1.0 : 0.2}` }} className="admin-manage-users-add-user-text">Add User</p>
                            <img alt="" src={this.state.showAddUserPopPup || (this.state.showAdminMessagePopup && this.state.messageType === "user-add") ? PlusIconBlack : PlusIcon}></img>
                        </div>
                        <div className="admin-manage-users-input-container">
                            <input
                                className="admin-manage-text-input"
                                placeholder="Search"
                                name="searchValue"
                                type="text"
                                value={this.state.searchValue}
                                onChange={(e) => this.handleSearch(e.target.value)}
                            />
                            <img style={{ marginRight: '17px' }} src={SearchIcon} width="15px" alt="" />
                        </div>
                        <DropdownSelect
                            className="admin-manage-users-input-container"
                            title={`${this.state.sortType == null ? "Sort" : this.state.sortType}`}
                            list={this.sortTypes}
                            resetThenSet={this.resetThenSet}
                            onChange={(value) => { this.setState({ sortType: value }) }}
                        />
                    </div>


                    {this.hasSelectedUsers() ?
                        <div className="admin-manage-users-tools">
                            <img src={UserReIcon} alt="" />
                            <img onClick={(e) => { this.deleteMultipleUsers() }} src={UserDeleteIcon} alt="" />
                            <img onClick={(e) => { this.sendConfirmEmailToMultipleUsers() }} src={UserEmailIcon} alt="" />
                            <img src={UserFilesIcon} alt="" />
                        </div> : null}

                </div>


                <div className="admin-manage-users-table-card">
                    <div className="admin-manage-users-table-card-inner">
                        <table className="admin-manage-users-table-container">
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th></th>
                                <th>Email</th>
                                <th>Company</th>
                                <th>Status</th>
                                <th>Last Login</th>
                                <th></th>
                            </tr>
                            {this.state.usersItems.map((value, index) => {

                                return (<tr style={{ position: 'relative' }} className={`${value.checked ? "admin-manage-user-selected-tr" : ""}`} >

                                    <td>
                                        <div onClick={(e) => { this.onUserSelect(value, index) }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', cursor: 'pointer' }}>
                                            {value.active ? <img alt="" src={value.checked ? checkedIcon : unCheckedIcon} /> : <img alt="" src={value.checked ? checkedIcon : disabledCheckedIcon} />}
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <div className={`admin-manage-users-value-text${value.checked ? "-selected" : value.active ? "" : "-disabled"}`} >
                                                    {`${value.firstName} ${value.lastName}`}
                                                </div>
                                                <div>
                                                </div>
                                            </div>
                                            <div className="admin-manage-users-table-progress " style={{ '--esg-progress-width': value.assessmentProgress + "%" }} />
                                        </div>
                                    </td>
                                    <td>
                                        <div style={{ position: 'relative' }}>
                                            <div className={`admin-manage-users-list-buttons ${this.hasSelectedUsers() ? "admin-manage-users-list-buttons-hide" : ""}`}>
                                                <img className="icons verifiedIcon noClickCursor" src={value.status != "Not Verified" ? UserVerifiedIcon : UserUnverifiedIcon} title={value.status != "Not Verified" ? "Verified Account" : "Unverified Account"} />
                                                <img className="icons editIcon" onClick={(e) => this.onUserEdit(value)} src={UserEditIcon} title="Edit User" />
                                                {/*<img className="icons deleteIcon" onClick={(e) => this.onUserDelete(value)} src={UserDeleteIcon} title="Delete User"/>*/}
                                                <img className="icons emailVerifyIcon" onClick={(e) => this.onUserConfirmEmail(value)} src={UserEmailIcon} title="Send temp password" />
                                                <img className="icons downloadIcon" onClick={(e) => this.onDownloadResults(value)} src={UserDownloadIcon} title="Download Results" />
                                                <img className="icons linksIcon" onClick={(e) => this.onOpenUserDataPopup(value)} src={UserFilesIcon} title="View Users links" />

                                            </div>
                                        </div>
                                    </td>
                                    <td><div className={`admin-manage-users-value-text${value.checked ? "-selected" : value.active ? "" : "-disabled"}`} >{value.email}</div></td>
                                    <td><div className={`admin-manage-users-value-text${value.checked ? "-selected" : value.active ? "" : "-disabled"}`} >{value.company}</div></td>
                                    <td>
                                        <div style={{ position: 'relative' }}>
                                            {value.active ? "" : <div style={{ position: 'absolute', opacity: '0.8', background: '#fff', width: '230px', height: '35px', borderRadius: '100px' }} />}
                                            <div className="admin-manage-users-table-status" style={{ backgroundColor: this.statusColor(value.status) }}>
                                                {value.status}
                                            </div>
                                        </div>

                                    </td>
                                    <td><div className={`admin-manage-users-value-text${value.checked ? "-selected" : value.active ? "" : "-disabled"}`} >{value.lastLogin}</div></td>
                                    <td>
                                        <MaterialSwitch
                                            checked={value.active}
                                            onChange={(status) => { this.onUserStatusChange(status, index) }}
                                        />
                                    </td>
                                    {index !== 0 && this.state.showAdminMessagePopup && this.state.messageType === "user-add" ? <td className="admin-user-manage-list-overlay"></td> : null}
                                </tr>);
                            })}

                        </table>
                    </div>


                </div>

            </div>
        </div>)


    }
}

export default AdminManageUsersPage;
