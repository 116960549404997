import { logDOM } from "@testing-library/react";
import React, { Component } from "react";
import SurveyQuestionAssesment from "./SurveyQuestionAssesment/SurveyQuestionAssesment";
import SurveyQuestionsAnswers from "./SurveyQuestionsAnswers/SurveyQuestionsAnswers";
import "./SurveyQuestionsPage.css";
import SurveyQuestionsSections from "./SurveyQuestionsSections/SurveyQuestionsSections";
import { Navigate } from "react-router-dom";
import MobileSupportPage from "../../Mobile/CurrencyValueSelect";
import MessagePopup from "../../CustomItems/Message/MessagePopup";
import ConfirmationPopup from "../../CustomItems/Confirm/ConfirmationPopup";
import { authManager } from "../../Auth/AuthManager";
import config from "../../../constants";
import fetchInterceptor from "../../../utils/FetchClient";

class SurveyQuestionsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentSection: null,
            currentSectionIndex: 0,
            sectionItems: [],
            questionItems: [],
            questionsAll: [],
            currentQuestionsNoFilters: [],
            answersItems: [],
            answersAll: [],
            currentAnswersNoFilters: [],
            questionsLength: 0,
            answersLength: 0,
            errorCode: '',
            canSubmitAssessment: false,
            allQuestionsCompleted: false,
            currentUser: null,
            questionPercent: 0,
            showMessagePopup: false,
            showConfirmationPopup: false,
            confirmTitle: "",
            confirmMessage: "",
            messageTitle: "",
            messageDescr: "",
            messageButton: "",
            redirect: false,
            redirectTo: "",
            compolsuryOnly: false,

        };

        this.answersComponentRef = React.createRef();
        this.sectionComponentRef = React.createRef();

        this.onChangeSection = this.onChangeSection.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onErrorCode = this.onErrorCode.bind(this);
        this.updateSection = this.updateSection.bind(this);
        this.onAnswersUpdate = this.onAnswersUpdate.bind(this);
        this.onSubmitAssessment.bind(this);

    }

    componentDidMount() {
        this.getData(false);
    }

    saveForLaterProfile = async () => {
        await this.answersComponentRef.current.createOrUpdateAnswer(this.state.questionItems[this.answersComponentRef.current.state.questionIndex]);

        const userID = localStorage.getItem('userID');
        try {
            let token = await authManager.getAccessToken();
            token = 'Bearer' + " " + token;
            var myHeaders = new Headers();
            myHeaders.append("Authorization", token);
            myHeaders.append("Content-Type", "application/json-patch+json");
            myHeaders.append("Accept", "application/json");
            let ops = [];

            ops.push({
                "operationType": 2,
                "path": "/AssessmentProgress",
                "op": "replace",
                "from": "<string>",
                "value": this.state.questionPercent
            });
            var raw = JSON.stringify(ops);

            var requestOptions = {
                method: 'PATCH',
                headers: myHeaders,
                body: raw
            };
            let { response, data } = await fetchInterceptor(config.url.API_URL + "/VerimpactAPI/UpdateProfile/" + userID, requestOptions);
        } catch (e) {
            console.log(e);
        }

        this.showPopup("Your answers were saved.", "You can continue were you left off any time.", "close");
    }

    onSubmitAssessment = async (event) => {
        if (event.target.className == "survey-questions-submit-button-on-card survey-questions-submit-button-disabled") {
            return false;
        }
        this.setState({
            confirmTitle: "You are about to submit your responses.",
            confirmMessage: "These cannot be edited once you have done so. Are you sure you want to submit?",
            showConfirmationPopup: !this.state.showConfirmationPopup
        });
    }

    onConfirmSubmit() {

        this.answersComponentRef.current.createOrUpdateAnswer(this.state.questionItems[this.answersComponentRef.current.state.questionIndex]);

        this.createLastDataArray();

    }

    async createLastDataArray() {
       // console.log("Creating last data array");
        var dataArray = {};

        var questions = this.state.questionsAll;
        var answers = this.state.answersAll;

        questions.forEach((question) => {
            var questionItem = {};
            var answerFound = false;
            answers.forEach((answer) => {
                if (question.questionGuid == answer.questionGuid) {
                    answerFound = true;
                    questionItem = {
                        "questionId": question.id,
                        "answerId": answer.questionID,
                        "abbreviation": question.abbreviation,
                        "question": question.question,
                        "type": question.type,
                        "isMandatory": question.isMandatory,
                        "answer": answer.answerValue,
                        "answeredByUser": true,
                        "min": question.minValue,
                        "max": question.maxValue,
                    };
                    dataArray[(question.abbreviation.replace(".", "_"))] = questionItem;

                }
            });
            //after looping all answers if questions hasn't been answered add default value.
            if (!answerFound) {
                var defaultValue = 0;

                if (question.type == "percentage") {
                    defaultValue = question.maxValue * 0.05;
                } else if (question.type == "slider-10") {
                    defaultValue = 1;
                }
                //question hasn't been answered
                questionItem = {
                    "questionId": question.id,
                    "answerId": "",
                    "abbreviation": question.abbreviation,
                    "question": question.question,
                    "type": question.type,
                    "isMandatory": question.isMandatory,
                    "answer": defaultValue,
                    "answeredByUser": false,
                    "min": question.minValue,
                    "max": question.maxValue,
                };
                dataArray[(question.abbreviation.replace(".", "_"))] = questionItem;
            }

        });


        dataArray = JSON.stringify(dataArray);

        //updateDetails
        const userID = localStorage.getItem('userID');
        try {
            let token = await authManager.getAccessToken();
            token = 'Bearer' + " " + token;
            var myHeaders = new Headers();
            myHeaders.append("Authorization", token);
            myHeaders.append("Content-Type", "application/json-patch+json");
            myHeaders.append("Accept", "application/json");
            let ops = [];

            ops.push({
                "operationType": 2,
                "path": "/AssessmentProgress",
                "op": "replace",
                "from": "",
                "value": this.state.questionPercent
            });
            ops.push({
                "operationType": 2,
                "path": "/IsAssessmentComplete",
                "op": "replace",
                "from": "",
                "value": 1
            });
            var raw = JSON.stringify(ops);

            var requestOptions = {
                method: 'PATCH',
                headers: myHeaders,
                body: raw
            };

            let { response, data } = await fetchInterceptor(config.url.API_URL + "/VerimpactAPI/UpdateProfile/" + userID, requestOptions);

        } catch (e) {
            console.log(e);
        }

        //saveData then go to next
        let username = localStorage.getItem('userID');
        let token = await authManager.getAccessToken();
        token = 'Bearer' + " " + token;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        var raw = JSON.stringify({
            "allQuestionsAnswered": this.state.allQuestionsCompleted,
            "environmental": 0,
            "fullRating": 0,
            "governance": 0,
            "medalType": "",
            "minimumCriteriaRating": 0,
            "questionsWithAnswers": dataArray,
            "ReadyToDisplayDashboard": false,
            "resultsBySection": "",
            "social": 0,
            "identityGuid": username
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        };

        let { response, data } = await fetchInterceptor(config.url.API_URL + "/VerimpactAPI/CreateAssessmentResults/", requestOptions);

        //goTo next page
        this.setState({ redirect: true, redirectTo: "/survey-submit" });
    }

    closeConfirmationPopup() {
        this.setState({ showConfirmationPopup: !this.state.showConfirmationPopup });
    }

    showPopup(title, descr, button) {
        this.setState({ showMessagePopup: true, messageTitle: title, messageDescr: descr, messageButton: button });
    }

    closePopup() {
        this.setState({ showMessagePopup: false, messageTitle: "", messageDescr: "", messageButton: "" });
    }

    async onAnswersUpdate() {
        //updateDetails
        const userGUID = localStorage.getItem('userID');
        try {
            let token = await authManager.getAccessToken();
            token = 'Bearer' + " " + token;
            var myHeaders = new Headers();
            myHeaders.append("Authorization", token);
            myHeaders.append("Content-Type", "application/json-patch+json");
            myHeaders.append("Accept", "application/json");
            let ops = [];

            ops.push({
                "operationType": 2,
                "path": "/AssessmentProgress",
                "op": "replace",
                "from": "<string>",
                "value": this.state.questionPercent
            });
            var raw = JSON.stringify(ops);

            var requestOptions = {
                method: 'PATCH',
                headers: myHeaders,
                body: raw
            };
            let { response, data } = await fetchInterceptor(config.url.API_URL + "/VerimpactAPI/UpdateProfile/" + userGUID, requestOptions);
        } catch (e) {
            console.log(e);
        }

        let surveyAnswers: any;
        let token = await authManager.getAccessToken();
        token = 'Bearer' + " " + token;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        let { response, data } = await fetchInterceptor(config.url.API_URL + "/VerimpactAPI/GetSurveyAnswers/" + userGUID, requestOptions)
                surveyAnswers = data;
            
        ///GET ALL THE ANSWERS 
        let newAnswers = [];
        surveyAnswers.forEach(surveyAnswer => {
            newAnswers.push(surveyAnswer);
        });

        this.setState({ answersLength: newAnswers.length });


        this.setState({ answersItems: newAnswers, answersLength: newAnswers.length }, () => {
            this.setPercentage();
            this.checkIfCompulsoryOrAllQuestionsAreAnswered();
        });

    }

    async getUsername() {
        let user = null;
        try {
            user = await authManager.getCurrentAuthenticatedUser();
        } catch {
            user = null;
        }
        this.setState({ currentUser: user });
    }

    async getUserDetails() {
        let token = await authManager.getAccessToken();
        token = 'Bearer' + " " + token;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        let userDetails: any;
        const userID = localStorage.getItem('userID');
        let { response, data } = await fetchInterceptor(config.url.API_URL + "/VerimpactAPI/GetUserDetailsByName/" + userID, requestOptions);
        userDetails = data;
        
        return userDetails;
        //        this.prefillFields(userDetails);

    }

    async getData(onlyUnanswered) {
        await this.getUsername();
        //check if assessment is complete before getting any other data
        let user = authManager.getCurrentAuthenticatedUser();
        let userDetails = await this.getUserDetails();

        if (userDetails.isProfileComplete == false) {
            this.setState({ redirect: true, redirectTo: "/profile" });
        }

        if (userDetails.isAssessmentComplete == true) {
            this.setState({ redirect: true, redirectTo: "/dashboard" });
        }


        //set mandatory or all questions option
        if (userDetails.assessmentMandatoryOnly) {
            await this.setState({ compolsuryOnly: true });
        } else {
            await this.setState({ compolsuryOnly: false });
        }

        let sectionResult: any;
        //assessment is not complete so load component with data

        let token = await authManager.getAccessToken();
        token = 'Bearer' + " " + token;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        let { response, data } = await fetchInterceptor(config.url.API_URL + "/VerimpactAPI/GetSurveySections", requestOptions);
        sectionResult = data;
        let questionResult: any;

        let result = await fetchInterceptor(config.url.API_URL + "/VerimpactAPI/GetSurveyQuestions", requestOptions);
        questionResult = result.data;
        let surveyAnswers = [];
        let answerResult = await fetchInterceptor(config.url.API_URL + "/VerimpactAPI/GetSurveyAnswers/" + this.state.currentUser, requestOptions);
        surveyAnswers = answerResult.data;

        var newSections = [];
        let newQuestions = [];
        let newAnswers = [];
        let questionsAll = [];
        let answersAll = [];

        sectionResult.forEach(section => {
            var sectionQuestions = [];
            if (section.active) {
                //FIND ALL THE QUESTIONS ASSOCIATED WITH THIS SECTION AND ADD THEM TO THE SECTION
                questionResult.forEach(question => {
                    if (question.sectionGuid === section.sectionGuid && question.active) {
                        if (this.state.compolsuryOnly) {
                            if (question.isMandatory) {
                                sectionQuestions.push(question);
                            }
                        } else {
                            sectionQuestions.push(question);
                        }
                        questionsAll.push(question);
                    }
                });

                //ADD THE QUESTIONS ORDERED
                section.questions = sectionQuestions.sort(function (a, b) {
                    return a.order - b.order;
                });
                newSections.push(section);
            }
        });

        //ORDER THE SECTIONS
        newSections = newSections.sort(function (a, b) {
            return a.order - b.order;
        });

        //GET ALL THE QUESTIONS LIST IN ORDER BASED ON SECTIONS ORDER AND QUESTIONS ORDER
        var i =1;
        newSections.forEach(section => {
            section.questions.forEach(question => {
                i++;
                newQuestions.push(question);
            });
        });

        ///GET ALL THE ANSWERS
        surveyAnswers.forEach(surveyAnswer => {
            if (this.state.compolsuryOnly) {
                newQuestions.forEach(question => {
                    if (surveyAnswer.questionID == question.id && question.isMandatory) {
                        newAnswers.push(surveyAnswer);
                    }
                });
            } else {
                newAnswers.push(surveyAnswer);
            }
            answersAll.push(surveyAnswer);

        });

        //set state with the data before any kind of filtering for using on the percentage and on checking if the complete button should be enabled.
        this.setState({ answersLength: newAnswers.length, questionsLength: newQuestions.length, currentAnswersNoFilters: newAnswers, currentQuestionsNoFilters: newQuestions, answersAll: answersAll, questionsAll: questionsAll });

        //filters
        if (onlyUnanswered) {
            var sectionsTemp = [];
            var questionsTemp = [];




            newQuestions.forEach(question => {
                var hasAnswer = false;
                newAnswers.forEach(answer => {
                    if (answer.questionGuid == question.questionGuid) {
                        hasAnswer = true;
                    }
                });
                if (!hasAnswer) {
                    questionsTemp.push(question);
                }
            });


            newSections.forEach(section => {
                var hasQuestions = false;
                questionsTemp.forEach(question => {
                    if (question.sectionGuid == section.sectionGuid) {
                        hasQuestions = true;
                    }
                });

                if (hasQuestions) {
                    sectionsTemp.push(section);
                }
            });
            if (questionsTemp.length > 0 || sectionsTemp.length > 0) {
                newSections = sectionsTemp;
                newQuestions = questionsTemp;
            } else {
                this.sectionComponentRef.current.changeSelectedFilter("all");
            }
        }

        await this.setState({ currentUser: user, sectionItems: newSections, questionItems: newQuestions, answersItems: newAnswers, currentSection: newSections[0], currentSectionIndex: 0 }, () => {
            this.setPercentage();
            this.onChangeSection(newSections[0], 0);
            //this.checkIfCompulsoryOrAllQuestionsAreAnswered();
        });
    }

    setPercentage() {
        let esgPercent = (this.state.answersLength / this.state.questionsLength) * 100;
        let pFixed = Math.round(esgPercent);
        this.setState({ questionPercent: pFixed }, () => {
            this.checkIfCompulsoryOrAllQuestionsAreAnswered();
        });

    }

    checkIfCompulsoryOrAllQuestionsAreAnswered() {
        var CompulsoryQuestionsTotal = 0;
        var CompulsoryAnswersQuestionsTotal = 0;
        var allQuestionsTotal = 0;
        var allAnswersTotal = 0;

        const answers = this.state.currentAnswersNoFilters;
        const questions = this.state.currentQuestionsNoFilters;

        allQuestionsTotal = questions.length;
        allAnswersTotal = answers.length;

        if (allQuestionsTotal === allAnswersTotal) {

            this.setState({ canSubmitAssessment: true });
            if (this.state.compolsuryOnly == false) {
                this.setState({ allQuestionsCompleted: true });
            }


        } else {
            if (this.state.compolsuryOnly == false) {
                questions.forEach((question) => {
                    if (question.isMandatory) {
                        CompulsoryQuestionsTotal += 1;
                    }
                    answers.forEach((answer) => {

                        if (question.id == answer.questionID) {
                            if (question.isMandatory) {
                                CompulsoryAnswersQuestionsTotal += 1;
                            }
                        }
                    });
                });

                if (CompulsoryQuestionsTotal == CompulsoryAnswersQuestionsTotal) {
                    if (this.state.questionPercent > 95) {
                        this.setState({ canSubmitAssessment: true, allQuestionsCompleted: true });
                    }
                }
            }
        }
    }

    onChangeSection(item, index) {
        this.setState({ currentSection: item, currentSectionIndex: index })
        this.answersComponentRef.current.changeSection(item)
    }

    onFilterChange(onlyUnanswered) {
        this.getData(onlyUnanswered);
    }

    updateSection(questionIndex) {
        var sectionIndex = 0;
        var sectionItem = null;

        let selectedQuestion = this.state.questionItems[questionIndex];
        this.state.sectionItems.forEach((section, index) => {
            if (selectedQuestion.sectionGuid == section.sectionGuid) {
                sectionIndex = index;
                sectionItem = section;
            }
        });

        this.setState({ currentSection: sectionItem, currentSectionIndex: sectionIndex })
        this.sectionComponentRef.current.selectedSectionScroll(sectionIndex);
    }


    onErrorCode(errorCode) {
        this.setState({ errorCode: errorCode });
    }

    renderMobile() {
        return (<MobileSupportPage />)
    }

    render() {
        const { redirect, redirectTo } = this.state;
        if (redirect) {
            return <Navigate to={redirectTo} />;
        }
        return (
            <div className="verimpact-container">
                {this.state.showMessagePopup ?
                    <MessagePopup
                        messageTitle={this.state.messageTitle}
                        messageDescr={this.state.messageDescr}
                        messageButton={this.state.messageButton}
                        closePopup={() => { this.closePopup() }}
                    />
                    : null
                }
                {this.state.showConfirmationPopup ?
                    <ConfirmationPopup
                        closePopup={() => { this.closeConfirmationPopup() }}
                        confirmTitle={this.state.confirmTitle}
                        confirmMessage={this.state.confirmMessage}
                        onConfirmSubmit={() => { this.onConfirmSubmit() }}
                    />
                    : null
                }
                <div className="survey-questions-mobile">
                    {this.renderMobile()}
                </div>
                <div className="survey-questions-desktop">
                    <div className="survey-questions-container">
                        <SurveyQuestionsSections
                            ref={this.sectionComponentRef}
                            currentSection={this.state.currentSection}
                            currentSectionIndex={this.state.currentSectionIndex}
                            onChangeSection={this.onChangeSection}
                            sectionItems={this.state.sectionItems}
                            questionItems={this.state.questionItems}
                            answersItems={this.state.answersItems}
                            onFilterChange={this.onFilterChange}
                        />
                        <SurveyQuestionsAnswers
                            ref={this.answersComponentRef}
                            currentSection={this.state.currentSection}
                            currentSectionIndex={this.state.currentSectionIndex}
                            onErrorCode={this.onErrorCode}
                            sectionItems={this.state.sectionItems}
                            questionItems={this.state.questionItems}
                            answersItems={this.state.answersItems}
                            updateSection={this.updateSection}
                            currentUser={this.state.currentUser}
                            onAnswersUpdate={this.onAnswersUpdate}
                        />
                        <SurveyQuestionAssesment
                            questionPercent={this.state.questionPercent}
                            canSubmitAssessment={this.state.canSubmitAssessment}
                            onSubmitAssessment={this.onSubmitAssessment}
                        />
                    </div>
                    <div className="survey-questions-footer">
                        <div className="survey-questions-footer-center-items">
                            <div className="survey-save-for-later-button" onClick={this.saveForLaterProfile}>Save for later</div>
                            {/* <div className={`survey-footer-cumpulsory-text ${this.state.errorCode === "" ? "" : "survey-footer-cumpulsory-text-error"}`} >*This is a cumpulsory question, please complete it to proceed.</div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SurveyQuestionsPage;
