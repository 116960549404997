import React from "react";
import "./MessagePopup.css";

import CloseIcon from '../../../assets/svg/close.svg';
import UserAddedCheck from '../../../assets/svg/user-added-check.svg'


class MessagePopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
   }

   

    render() {
        return (
        <div className="message-popup">
            <div className="message-popup_open">


            <div className="message-container">

                <img alt="" src={UserAddedCheck}></img>



                <h4 style={{color:"#2E2EFF"}}>{this.props.messageTitle}</h4>

           

                <div className="message-popup-title">{this.props.messageDescr}</div>

                <br/>

                <button className="message-back-button" onClick={() => this.props.closePopup()}>{this.props.messageButton}</button>

            </div>            
            
            </div>
        </div>
        );
    }
}
export default MessagePopup;
