import { StringUtils } from "utils/StringUtils";
import { decodeTokenPayload } from "utils/JWTUtils";
import config from "../../constants";



class AuthManager {
    private user: any;
    private group: any;
    /**
     * get token.
     *
     */
    // public async getAccessToken(): Promise<string | undefined> {
    public getAccessToken() {
     //   let token: string | undefined = undefined;
        //   const success = token !== null && !StringUtils.isEmpty(token);
        let token = localStorage.getItem("token");
        return token;
    }
    /**
     * get current authenticated user
     */
    public getTempUnauthenticatedUser() {
        let user = localStorage.getItem("unauthUserID");
        if (user != undefined) {
            return user;
        }
        return;
    }
    /**
     * get current authenticated user
     */
    public getTempUnauthenticatedUserGroup() {
        let group = localStorage.getItem("unauthGroup");
        if (group != undefined) {
            return group;
        }
        return;
    }
    /**
     * get current authenticated user
     */
    public getCurrentAuthenticatedUser() {
        let user = localStorage.getItem("userID");
        if (user != undefined) {
            return user;
        }
        return this.user;
    }
    /**
     * get current authenticated user
     */
    public getCurrentAuthenticatedUserGroup() {
        let group = localStorage.getItem("userGroup");
        if (group != undefined) {
            return group;
        }
        return this.group;
    }
    /**
     * Sign In.
     *
     */
    public async signIn(userEmail, password) {
        let token: any;
        let raw_token: any;
        let refresh_token: any;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("client_id", "Verimpact");
        urlencoded.append("grant_type", "password");
        urlencoded.append("username", userEmail);
        urlencoded.append("password", password);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow' as RequestRedirect
        };
        // await fetch("https://verimpact-oauth.azurewebsites.net/connect/token", requestOptions)
        await fetch(config.url.AUTH_URL + "/connect/token", requestOptions)
            .then(response => response.json())
            .then(result => {
                raw_token = result;
                token = decodeTokenPayload(raw_token.access_token);
                refresh_token = raw_token.refresh_token;
            })
            .catch(error => console.log('error', error));
        this.user = token.sub;
        this.group = token.role;
        localStorage.setItem('token', raw_token.access_token);
        localStorage.setItem('refresh_token', raw_token.refresh_token);
        return token;
    }
}

export const authManager = new AuthManager();