import React from "react";
import "./AdminConfirmationPopup.css";

class AdminConfirmationPopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
   }

   
    render() {
        return (
        <div className="admin-confirm-popup ">
            <div className="admin-confirm-popup_open">

               <div className="admin-confirm-container">

                    <div className="admin-confirm-title" dangerouslySetInnerHTML={{__html: this.props.confirmTitle}}/>

                    <div className="admin-confirm-buttons-container">
                        <button className="admin-confirm-button" onClick={() => this.props.onConfirmSubmit()}>Yes</button>
                    
                        <button className="admin-confirm-button" onClick={() => this.props.closePopup()}>No</button>  
                    </div>

               </div>

            </div>
        </div>
        );
    }
}
export default AdminConfirmationPopup;
