import React, { Component } from "react";
import "./SlideRangeSelect.css";
 

class SlideRangeSelect extends Component {

  constructor(props) {
    super(props);
    //console.log(props);
    this.state = {
      rangeMarginLeft: 0,
      userAnswered: false,
    };
    
    this.getStateOfUserAnswered();
  }

  async getStateOfUserAnswered () {
    await this.setState({userAnswered: this.props.userAnswered});
  }

  componentDidMount() {
    //console.log("qweqwe: " + this.props.rangeValue);
    this.showSliderValue(this.props.rangeValue, this.state.userAnswered);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.rangeValue !== this.props.rangeValue) {
      this.showSliderValue(this.props.rangeValue, this.state.userAnswered);
    }
  }

  showSliderValue(value, answered) {
    //Set left margin to the label based on the value of the slide range
    const val = value;
    const min = this.props.min ? this.props.min : 0;
    const max = this.props.max ? this.props.max : 100;
    const newVal = Number( (((val - min) * 100) / (max - min)) / 1.05 );
    this.setState({rangeMarginLeft: newVal, userAnswered: answered})
  }


  onAnswerRangeChange(e) {
    this.showSliderValue(e.target.value, true);
    this.props.onChangeRange(e.target.value);
  }
  

  render() {
    return (<div>
        <div className={`survey-answer-slide-container ${(this.props.userAnswered == true && this.state.userAnswered == false) ?  "" : this.state.userAnswered == true ? "" : "no-answer"}`} style={{position: 'relative'}}>
        
        <span style={{left: `${this.state.rangeMarginLeft}%`, position: 'absolute', bottom: '65px'}} id="rs-bullet" className="rs-label">{`${this.props.rangeValue}%`}</span>
        
        <div style={{marginBottom: '-9px'}}>
           <input ref={this.rangeRef} type="range" step={this.props.step ? this.props.step : 1} min={this.props.min ? this.props.min : 0} max={this.props.max ? this.props.max : 100} value={this.props.rangeValue} onChange={(e)=> {this.onAnswerRangeChange(e)}} className="survey-answer-slide-slider" id="rs-range-line"/> 
           <div style={{display:'flex',justifyContent: 'space-between'}}>
            <span className="slide-min-max-text">{this.props.min ? this.props.min : 0}%</span>
            <span className="slide-min-max-text">{this.props.max ? this.props.max : 100}%</span>
          </div>
          <div style={{display:'flex',justifyContent: 'space-between'}}>
            <span className="precentage-indicator">{(this.props.indicator.split("|"))[0]}</span>
            <span className="precentage-indicator">{(this.props.indicator.split("|"))[1]}</span>
          </div>
        </div>
       
      
      </div>
    </div>);
  }
}

export default SlideRangeSelect;
