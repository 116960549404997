import React, { Component } from "react";
import "./SurveyPreparePage.css";

class SurveyPreparePage extends Component {

  render() {
    return (
      <div className="verimpact-container">
        <div className="survey-heading">
          <h3 className="page-title top-space bot-space text-blue">How to prepare for your ESG Assessment.</h3>
        </div>
        <div className="survey-cards-container container">
          <div className="card-item">
            <div className="card-container">
              <div className="card-content">
                <div className="card-group-1">
                  <p className="card-step">Step #01</p>
                  <h3 className="card-title">Launch your<br/>assessment on a<br/>desktop browser</h3>
                </div>
                <hr className="card-line"></hr>
                <div className="card-group-2">
                  <p className="card-description">
                    We suggest you launch your assessment on a Desktop for a better experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card-item">
            <div className="card-container">
              <div className="card-content">
                <div className="card-group-1">
                  <p className="card-step">Step #02</p>
                  <h3 className="card-title">The more,<br />the better</h3>
                </div>
                <hr className="card-line"></hr>
                <div className="card-group-2">
                  <p className="card-description">
                    All compulsory questions must be completed. If you decide to only answer compulsory questions the maximum impact verification status you can get is silver. We advise the completion of all questions as this will allow us to gather more accurate data and ultimately increase the likelihood of an improved outcome of your assessment, as well as the opportunity to reach platinum status.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card-item">
            <div className="card-container">
              <div className="card-content">
                <div className="card-group-1">
                  <p className="card-step">Step #03</p>
                  <h3 className="card-title">Verify your data<br/>with the right<br/>department</h3>
                </div>
                <hr className="card-line"></hr>
                <div className="card-group-2">
                  <p className="card-description">
                    Accuracy is key. Make sure that all data you are inputting is accurate. We recommend speaking to the relevant teams and departments to verify your answers where necessary.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="survey-heading bot-space">
          <h4 className="start-title">Are you all set?</h4>
          <div className="survey-btn-container">
            <a href="/survey-select" className="a-button-primary">Let's start!</a>
          </div>
        </div>
      </div>
    );
  }
}

export default SurveyPreparePage;
