import React, { Component } from "react";
import CircularProgressBar from "../../../CustomItems/CircularProgressBar";
import "./SurveyQuestionAssesment.css";
 


class SurveyQuestionAssesment extends Component {


  render() {
    return (<div className="survey-assesment-container">
      <div className="survey-assesment-notification-container">
        <p>Notifications</p>
        <div className="card-container survey-assesment-title-container">
          <div>
            <h3 className="survey-assesment-title">Reminder:</h3>
            <p>You will only be able to complete your survey once you have completed all the questions.</p>
          </div>
        </div>
      </div>
     
      <div className="card-item assesment-progress-card">
        <div className="card-container">
            <div className="assesment-progress-container">
              <div>
                <CircularProgressBar
                  strokeWidth="20"
                  sqSize="230"
                  progresstext={`${this.props.questionPercent}%`}
                  percentage={this.props.questionPercent}/>
                {/* <div className="assesment-progress-number">
                  45
                </div> */}
              </div>
              <div className="assessment-score-text">
                <h3>Assessment<br/>Completion Rate</h3>
                <p className="assesment-progress-desc">You have completed {this.props.questionPercent}% of your ESG Assessment</p>
              </div>
            </div>
        </div>
      </div>

      <div className="card-item assessment-done-card">
        <div className="card-container">
            <div className="assessment-done-container">
              <div>
                <h4>Done?</h4>
                <p>To complete your assessment, please click the button below.</p>
                <div className={`survey-questions-submit-button-on-card ${this.props.canSubmitAssessment ? 'survey-questions-submit-button-enabled' : 'survey-questions-submit-button-disabled'}`} onClick={this.props.onSubmitAssessment}>Complete Assessment</div>
              </div>
            </div>
        </div>
      </div>
        
      </div>);
  }
}

export default SurveyQuestionAssesment;
