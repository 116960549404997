import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import { userContext } from "../../context/AuthContext";
import "./PasswordUpdatedPage.css";

class PasswordUpdatedPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const redirect = this.state.redirect;
    const { value } = {
      user: this.state.user,
      isLoggedIn: this.state.isLoggedIn,
    };
    if (redirect) {
      return (
        <userContext.Provider value={value}>
          <Navigate to="/internal" />
        </userContext.Provider>
      );
    }
    const isMobile = window.innerWidth <= 500;
    if (isMobile) {
      return this.renderMobile();
    } else {
      return (
        <div className="login-container">
          <div className="login-form">
            <br/><br/>
            <img className="check-icon" src="/assets/images/complete-check.png" />
            <p className="login-heading">Password Updated!</p>
            <p className="login-heading-description">
              You can go ahead and successfully login to your account now.
            </p>
            <br/><br/>
            <div>
              <a
                href="/login"
                className="a-button-primary submit-button"
              >Login</a>
              </div>
            <br />
            <br />
            <div className="login-signup-container">
              <p className="login-signup-text">
                Do not have an account? Get in touch with us to set up your
                account.
              </p>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default PasswordUpdatedPage;
