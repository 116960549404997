import React, { Component } from "react";
import "./SurveySelectPage.css";
import { Navigate } from "react-router-dom";
import { authManager } from "../../Auth/AuthManager";
import config from "../../../constants";
import fetchInterceptor from "../../../utils/FetchClient";

class SurveySelectPage extends Component {

  constructor(props) {
    super(props);

    this.option1 = React.createRef();
    this.option2 = React.createRef();
    this.errorMsg = React.createRef();

    this.state = {
      selectedOption: 0,
      link: "#",
      showError: false,
      redirect: false,
    }
  }

  onButtonSelect (type) {
    if (type == "comp") {
      this.setState({showError: false, selectedOption: 2}); //compulsory only
    } else {
      this.setState({showError: false, selectedOption: 1}); //all questions
    }
  }

    onStartClick = async (event) => {
        event.preventDefault();
    if (this.state.selectedOption == 0) {
      this.setState({showError: true});
    } else {
      //update userdetails
        console.log(this.state.selectedOption);
        this.updateUserOption();
        this.setState({ redirect: true });
        console.log(this.state.redirect);
    }
  }

  updateUserOption = async () => {
    const userID = localStorage.getItem('userID');
      let mandatoryOnly = false;
      if (this.state.selectedOption == 2) {
        mandatoryOnly = true;
      }

      try {
          let token = await authManager.getAccessToken();
          token = 'Bearer' + " " + token;
          var myHeaders = new Headers();
          myHeaders.append("Authorization", token);
          myHeaders.append("Content-Type", "application/json-patch+json");
          myHeaders.append("Accept", "application/json");
          let ops = [];

          ops.push({
              "operationType": 2,
              "path": "/AssessmentMandatoryOnly",
              "op": "replace",
              "from": "<string>",
              "value": mandatoryOnly
          });
          var raw = JSON.stringify(ops);

          var requestOptions = {
              method: 'PATCH',
              headers: myHeaders,
              body: raw
          };
          await fetchInterceptor(config.url.API_URL + "/VerimpactAPI/UpdateProfile/" + userID, requestOptions);
      } catch (e) {
        console.log(e);
      }
  }

    render() {
        const { redirect } = this.state;
        if (redirect) {
      return <Navigate to="/esg-assessment" />;
    }
        return (
      <div className="verimpact-container">
        <div className="survey-select-heading">
          <h3 className="page-title top-space text-blue">Before you start your ESG Assessment...</h3>
          <p><b>Please select</b> if you will answer all questions or<br/>only the compulsory ones.</p>
        </div>
        <div className="survey-select-cards-container container">
          <div className={"card-item " + (this.state.showError ? "error" : "") + " " + (this.state.selectedOption == 1 ? "selected" : "")} ref={this.option1}>
            <div className="card-container">
              <div className="card-content">
                <div className="card-group-1">
                  <p className="card-step">Option #01</p>
                  <h3 className="card-title">All questions</h3>
                </div>
                <hr className="card-line"></hr>
                <div className="card-group-2">
                  <p className="card-description">
                    We advise the completion of all questions as this will allow us to gather more accurate data and ultimately increase the likelihood of an improved outcome of your assessment, as well as the opportunity to reach platinum status.
                  </p>
                </div>
                <div className="card-group-3">
                    <span className="a-button-primary" onClick={() => {this.onButtonSelect("all")}}>Select</span>
                    </div>
                </div>
            </div>
          </div>
          <div className={"card-item " + (this.state.showError ? "error" : "") + " " + (this.state.selectedOption == 2 ? "selected" : "")}  ref={this.option2}>
            <div className="card-container">
              <div className="card-content">
                <div className="card-group-1">
                  <p className="card-step">Option #02</p>
                  <h3 className="card-title">Complulsory questions</h3>
                </div>
                <hr className="card-line"></hr>
                <div className="card-group-2">
                  <p className="card-description">
                    All compulsory questions must be completed. If you decide to only answer compulsory questions the maximum impact verification you can get is silver.
                  </p>
                </div>
                <div className="card-group-3">
                  <span className="a-button-primary" onClick={() => {this.onButtonSelect("comp")}}>Select</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="survey-select-heading bot-space">
          <div className="survey-select-btn-container">
            <span className={`survey-select-error-msg ` + (this.state.showError ? "show" : "")} ref={this.errorMsg}>Please select one of the options above to proceed.</span>
                    {/**   <a href={this.state.link} onClick={() => { this.onStartClick() }} className="a-button-primary">Start ESG Assessment</a>*/}
                        <a href="/esg-assessment" className="a-button-primary">Start ESG Assessment</a>
          </div>
        </div>
      </div>
    );
  }
}

export default SurveySelectPage;
