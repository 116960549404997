import React, { Component } from "react";
import config from "../../../constants";
import fetchInterceptor from "../../../utils/FetchClient";
import { authManager } from "../../Auth/AuthManager";
import GaugeProgress from "../../CustomItems/GaugeProgress";
import MobileSupportPage from "../../Mobile/CurrencyValueSelect";
import DashboardPopUp from "../../Popup/DashboardPopUp/DashboardPopUp";
import DashboardBottomCard from "./DashboardBottomCard/DashboardBottomCard";
import "./DashboardPage.css";
import DashboardResults from "./DashboardResults/DashboardResults";
import ImpactVerification from "./ImpactVerification/ImpactVerification";


class DashboardPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPopup: false,
            chartItems: [
                {
                    name: "GOV",
                    value: 0
                },
                {
                    name: "SDG1",
                    value: 0
                },
                {
                    name: "SDG2",
                    value: 0
                },
                {
                    name: "SDG3",
                    value: 0
                },
                {
                    name: "SDG4",
                    value: 0
                },
                {
                    name: "SDG5",
                    value: 0
                },
                {
                    name: "SDG6",
                    value: 0
                },
                {
                    name: "SDG7",
                    value: 0
                },
                {
                    name: "SDG8",
                    value: 0
                },
                {
                    name: "SDG9",
                    value: 0
                },
                {
                    name: "SDG10",
                    value: 0
                },
                {
                    name: "SDG11",
                    value: 0
                },
                {
                    name: "SDG12",
                    value: 0
                },
                {
                    name: "SDG13",
                    value: 0
                },
                {
                    name: "SDG14",
                    value: 0
                },
                {
                    name: "SDG15",
                    value: 0
                },
                {
                    name: "SDG16",
                    value: 0
                }
            ],
            medalType: "",
            fullRating: 0,
            environmental: 0,
            social: 0,
            governance: 0
        };

        this.togglePopup.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    async getData() {
        let user = await authManager.getCurrentAuthenticatedUser();

        //check if assessment is complete before getting any other data
        let assessmentResults: any;
        let token = await authManager.getAccessToken();
        token = 'Bearer' + " " + token;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        let { response, data } = await fetchInterceptor(config.url.API_URL + "/VerimpactAPI/GetAssessmentResults/" + user, requestOptions);
        assessmentResults = data;
        var envScore = assessmentResults.environmental;
        var govScore = assessmentResults.governance;
        var socialScore = assessmentResults.social;
        var fullScore = assessmentResults.fullRating;
        var medalScore = assessmentResults.medalType;
        var chartItemstemp = [];
        var resultsBySection = JSON.parse(assessmentResults.resultsBySection);
        for (const key in resultsBySection) {
            chartItemstemp.push({ name: key, value: resultsBySection[key].full.score });
            //console.log(`${key}: ${resultsBySection[key]}`);
        }
        //console.log(chartItemstemp);

        this.setState({
            chartItems: chartItemstemp,
            medalType: medalScore,
            fullRating: fullScore,
            environmental: envScore,
            social: socialScore,
            governance: govScore
        });

        // this.setState({currentUser: user,sectionItems: newSections,questionItems: newQuestions,answersItems: newAnswers,currentSection: newSections[0],currentSectionIndex:0},() => {
        //   this.setPercentage();
        //   this.checkIfCompulsoryOrAllQuestionsAreAnswered();
        // });
    }

    togglePopup() {
        this.setState({
            showPopup: !this.state.showPopup
        });
    }

    openCloseDashboardPopup() {
        this.togglePopup();
    }

    renderMobile() {
        return (<MobileSupportPage />)
    }

    render() {
        return (
            <div className="verimpact-container">
                <div className="dashboard-mobile">
                    {this.renderMobile()}
                </div>

                {this.state.showPopup ?
                    <DashboardPopUp
                        closePopup={() => { this.openCloseDashboardPopup() }}
                    />
                    : null
                }
                <div className="dashboard-desktop">
                    <div className="dashboard-container">
                        <div className="dashboard-sidebar">
                            <div className="dashboard-card dashboard-your-dashboard-card dashboard-top-big-cards" style={{ backgroundColor: '#2E2EFF' }}>
                                <div className="dashboard-top-big-title">
                                    <h3>Your Dashboard</h3>
                                </div>
                                <div className="dashboard-top-big-content">
                                    <p className="dashboard-top-big-content-title">Congratulations!</p>
                                    <p className="dashboard-top-big-content-descr">Your ESG journey is starting, let’s work together to make it happen!</p>
                                </div>
                            </div>
                            <div className="dashboard-top-big-cards">
                                <ImpactVerification medalType={this.state.medalType} />
                            </div>
                        </div>
                        <div className="dashboard-content">
                            {/* TOP ITEMS */}
                            <div className="dashboard-items-top">
                                <div className="dashboard-card dashboard-circle-chart-card dashboard-top-big-cards">
                                    <h3>Full Rating</h3>
                                    <small>{this.state.fullRating}%</small>
                                    <GaugeProgress percentage={this.state.fullRating} />
                                </div>
                                <div className="dashboard-card dashboard-circle-chart-card dashboard-top-small-cards">
                                    <h3>Environmental</h3>
                                    <small>{this.state.environmental}%</small>
                                    <GaugeProgress percentage={this.state.environmental} />
                                </div>
                                <div className="dashboard-card dashboard-circle-chart-card dashboard-top-small-cards">
                                    <h3>Social</h3>
                                    <small>{this.state.social}%</small>
                                    <GaugeProgress percentage={this.state.social} />
                                </div>
                                <div className="dashboard-card dashboard-circle-chart-card dashboard-top-small-cards">
                                    <h3>Governance</h3>
                                    <small>{this.state.governance}%</small>
                                    <GaugeProgress percentage={this.state.governance} />
                                </div>
                            </div>
                            {/* MIDDLE ITEMS */}
                            <br />
                            <div className="dashboard-items-middle">


                                <div className="dashboard-top-whole-cards" >
                                    <DashboardResults chartItems={this.state.chartItems} />
                                </div>
                            </div>
                            <br />
                            {/* BOTTOM ITEMS */}
                            <div className="dashboard_bottom_card_container">
                                <DashboardBottomCard
                                    width={"50%"}
                                    title={"#01"}
                                    desc={"View full report"}
                                    buttonText={"Contact us"}
                                    titleColor={"white"}
                                    descColor={"white"}
                                    bgImage={"assets/images/dashboard_bottom_one.png"}
                                    onButtonClick={() => { this.openCloseDashboardPopup() }}
                                />
                                <DashboardBottomCard
                                    width={"50%"}
                                    title={"#02"}
                                    desc={"Recommendations for improvement"}
                                    buttonText={"Contact us"}
                                    titleColor={"white"}
                                    descColor={"white"}
                                    bgImage={"assets/images/dashboard_bottom_two.png"}
                                    onButtonClick={() => { this.openCloseDashboardPopup() }}
                                />
                                {/* <DashboardBottomCard
                        title={"#03"}
                        desc={"Useful reading material"}
                        buttonText={"Read more"}
                        titleColor={"white"}
                        descColor={"white"}
                        bgImage={"assets/images/dashboard_bottom_three.png"}
                      /> */}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default DashboardPage;
