import React, { Component } from "react";
import checkIcon from "../../assets/svg/check-icon.svg";

class DropdownSelect extends Component {

	constructor(props){
	  super(props);
	  this.state = {
	    isListOpen: false,
	    headerTitle: this.props.title,
	    headerValue: this.props.title,
		searchValue: '',
		searchList: this.props.list
	  }
	}

	toggleList = () => {
	   this.setState(prevState => ({
	     isListOpen: !prevState.isListOpen
	  }))
	}

	selectItem = (item) => {
	  const { resetThenSet } = this.props;
	  const { title, id, key } = item;

	  if (this.props.onChange != null) {
		this.props.onChange(title);
	  }

	  this.setState({
	    headerTitle: title,
	    headerValue: title,
	    isListOpen: false,
	  }, () => resetThenSet(id, key));
	}

	selectSearchItem = (item) => {
		const { resetThenSet } = this.props;
		const { title, id, key } = item;
  
		if (this.props.onChange != null) {
		  this.props.onChange(title);
		}
		

  
		this.setState({
		  headerTitle: title,
		  searchValue: title,
		  headerValue: title,
		  isListOpen: false,
		}, () => resetThenSet(id, key));
	}

	static getDerivedStateFromProps(nextProps) {
	  const { list, title } = nextProps;
	  const selectedItem = list.filter((item) => item.selected);

	  if (selectedItem.length) {
	    return {
	      headerTitle: selectedItem[0].title,
	      headerValue: selectedItem[0].title
	    };
	  }
	  return { 
	  	headerTitle: title,
	  	headerValue: title
	  	};
	}

	close = () => {
	    this.setState({
	      isListOpen: false,
	    });
	  }

	  componentDidUpdate(){
	    const { isListOpen } = this.state;

	    setTimeout(() => {
	      if(isListOpen){
	        window.addEventListener('click', this.close)
	      }
	      else{
	        window.removeEventListener('click', this.close)
	      }
	    }, 0)
	  }

  	searchItems(value) {
		this.setState({searchValue:value});
		var filterdList = [];
		this.props.list.forEach(listItem => {
			if (listItem.title.toLowerCase().includes(value.toLowerCase())) {  
				filterdList.push(listItem);
			}
		});
		this.setState({searchList:filterdList});
	}
	  

	render () {

		const { isListOpen, headerTitle, headerValue } = this.state;
  		const { list, hasSearch } = this.props;



		return (

			<div className={`dropdown-select-container ${this.props.className != null ? this.props.className : ""}`}>
				<div className={"dropdown-select-header " + (isListOpen ? 'open' : '')}
					onClick={this.toggleList}
				>
					{hasSearch ?  <div className="dropdown-select-title" >
						<input 
							className={headerTitle === "Select" ? "" : "selectedItem"}
							value={this.state.searchValue} 
							placeholder={headerTitle}
							onChange={(e) => this.searchItems(e.target.value)} 
							style={{border:'none',color: `${headerTitle === "Select" ? "rgba(0,0,0,0.2)" : "#000"}`}}
							/>
					</div>
					: <div className="dropdown-select-title" style={{color: `${headerTitle === "Select" ? "rgba(0,0,0,0.2)" : "#000"}`}} data-value={headerValue}>{headerTitle}</div>
					  } 
				</div>
				{isListOpen && (
					<div className="dropdown-select-list-container">
						<div role="list" className="dropdown-select-list">
							{hasSearch ? this.state.searchList.map((item) => (

								<div className={"dropdown-select-list-item " + (item.hasOwnProperty('type') ? item.type : '')}
									key={item.id}
									onClick={() => this.selectSearchItem(item)}
								>
								{item.title}
								{' '}
								{item.selected 
								// && <span className="dropdown-select-item-check"><img alt="" src={checkIcon} /></span>
								}
								</div>

								)) : list.map((item) => (

								<div className={"dropdown-select-list-item " + (item.hasOwnProperty('type') ? item.type : '')}
									key={item.id}
									onClick={() => this.selectItem(item)}
								>
								{item.title}
								{' '}
								{item.selected 
								// && <span className="dropdown-select-item-check"><img alt="" src={checkIcon} /></span>
								}
								</div>

							))}
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default DropdownSelect;