import "./CircularProgressBar.css";
import React, { Component } from "react";


class CircularProgressBar extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
  
    render() {
      // Size of the enclosing square
      const sqSize = this.props.sqSize;
      // SVG centers the stroke width on the radius, subtract out so circle fits in square
      const radius = (this.props.sqSize - this.props.strokeWidth) / 2;
      // Enclose cicle in a circumscribing square
      const viewBox = `0 0 ${sqSize} ${sqSize}`;
      // Arc length at 100% coverage is the circle circumference
      const dashArray = radius * Math.PI * 2;
      // Scale 100% coverage overlay with the actual percent
      const dashOffset = dashArray - dashArray * this.props.percentage / 100;
  
      return (
        <svg
            width="100"
            height="100"
            viewBox={viewBox}>
            <circle
              className="circle-background"
              cx={this.props.sqSize / 2}
              cy={this.props.sqSize / 2}
              r={radius}
              strokeWidth={`${this.props.strokeWidth}px`} />
            <circle
              className="circle-progress"
              cx={this.props.sqSize / 2}
              cy={this.props.sqSize / 2}
              r={radius}
              strokeWidth={`${this.props.strokeWidth}px`}
              // Start progress marker at 12 O'Clock
              transform={`rotate(-90 ${this.props.sqSize / 2} ${this.props.sqSize / 2})`}
              style={{
                strokeDasharray: dashArray,
                strokeDashoffset: dashOffset
              }} 
              />
              
              <circle
              className="circle-dashes"
              cx={this.props.sqSize / 2}
              cy={this.props.sqSize / 2}
              r={radius}
              strokeWidth={`${this.props.strokeWidth+5}px`}
              style={{
                strokeDasharray: "6 3"
              }} />

            <circle
              className="circle-progress-inner"
              cx="50%"
              cy="50%"
              r={89}
              strokeWidth={`${this.props.strokeWidth}px`}
              transform={`rotate(-90 ${this.props.sqSize / 2} ${this.props.sqSize / 2})`}
             />
             <text className="assesment-progress-number" x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">{this.props.progresstext}</text>   
        </svg>
      );
    }
  }

  export default CircularProgressBar;
