import React, { Component } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import LandingPage from "../Landing/LandingPage";
import ProfilePage from "../UserApp/Profile/ProfilePage";
import SurveyPreparePage from "../UserApp/SurveyPrepare/SurveyPreparePage";
import SurveySelectPage from "../UserApp/SurveySelect/SurveySelectPage";
import Footer from "../Footer/Footer";
import ContactUsPage from "../ContactUs/ContactUsPage";
import ThankYouPage from "../ContactUs/ThankYouPage";
import LoginPage from "../Login/LoginPage";
import ForgotPasswordPage from "../Login/ForgotPasswordPage";
import PasswordUpdatedPage from '../Login/PasswordUpdatedPage';
import ResetPasswordPage from '../Login/ResetPasswordPage';
import EmailSentPage from '../Login/EmailSentPage';
import {userContext} from '../../context/AuthContext';
import "./Router.css";
import SurveyQuestionsPage from '../UserApp/SurveyQuestions/SurveyQuestionsPage';
import DashboardPage from '../UserApp/Dashboard/DashboardPage';
import SurveyFinishPage from '../UserApp/SurveyFinish/SurveyFinishPage';
import SurveyThanksPage from '../UserApp/SurveyThanks/SurveyThanksPage';
import AdminSettingsPage from '../AdminApp/Settings/AdminSettingsPage';
import AdminDataInitPage from '../AdminApp/DataInit/DataInitPage';
import AdminManageUsersPage from '../AdminApp/ManageUsers/AdminManageUsersPage';
import { authManager } from '../Auth/AuthManager';
import DebugRouter from "./DebugRouter";

class Router extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoggedIn: false,
            isAdmin: false,
            hasLoadedUser: false
        }
    }

    componentDidMount() {
        this.checkIsUserLoggedIn();
    }

    async checkIsUserLoggedIn() {
        let user = null;
        let group = false;
        let userGroup = null;
        try {
            user = await authManager.getCurrentAuthenticatedUser();
            this.setState({ hasLoadedUser: true });
            userGroup = await authManager.getCurrentAuthenticatedUserGroup();
            if (userGroup === 'Administrators') {
                group = true;
                this.SetIsAdmin(group);
            }
        } catch {
            user = null;
            group = null;
            this.setState({ hasLoadedUser: true });
        }
        this.userHasAuthenticated(user != null && user, group);
    }

    SetIsAdmin(value) {
        this.setState({
            isAdmin: value,
        });
    }

    userHasAuthenticated = (isLoggedIn, isAdmin) => {
        this.setState({ isLoggedIn: isLoggedIn, isAdmin: isAdmin });
    }
    render() {
        const value = {
            user: this.state.user,
            isLoggedIn: this.state.isLoggedIn,
            isAdmin: this.state.isAdmin,
        }
        return (
            <userContext.Provider value={value}>
                <BrowserRouter>
                    <div>
                        <Header isLoggedIn={this.state.isLoggedIn} isAdmin={this.state.isAdmin} userHasAuthenticated={this.userHasAuthenticated} />
                        <Routes>
                            {/**                            <Route element={<DebugRouter />}> */}
                            <Route exact path="/about" element={<LandingPage />} />
                            <Route exact path="/contact" element={<ContactUsPage />} />
                            <Route exact path="/thankyou" element={<ThankYouPage />} />
                            <Route exact path="/" element={<LandingPage />} />
                            <Route exact path="/internal" element={
                                this.state.isLoggedIn ?
                                    this.state.isAdmin ? <AdminSettingsPage /> : <ProfilePage />
                                    : <LoginPage userHasAuthenticated={this.userHasAuthenticated} />
                            }
                            />
                            <Route exact path="/data-init" element={
                                this.state.isLoggedIn ?
                                    this.state.isAdmin ? <AdminDataInitPage /> : <ProfilePage />
                                    : <LoginPage userHasAuthenticated={this.userHasAuthenticated} />
                            }
                            />
                            <Route
                                exact
                                path="/login"
                                element={<LoginPage userHasAuthenticated={this.userHasAuthenticated} />}
                            />
                            <Route
                                exact
                                path="/forgot"
                                element={<ForgotPasswordPage />}
                            />
                            <Route
                                exact
                                path="/emailsent"
                                element={<EmailSentPage />}
                            />
                            <Route
                                exact
                                path="/reset"
                                element={<ResetPasswordPage userHasAuthenticated={this.userHasAuthenticated} />}
                            />
                            <Route
                                exact
                                path="/passwordupdated"
                                element={<PasswordUpdatedPage />}
                            />
                            <Route
                                exact
                                path="/profile"
                                element={
                                    this.state.isLoggedIn ?
                                        <ProfilePage />
                                        : this.state.hasLoadedUser ? <LoginPage userHasAuthenticated={this.userHasAuthenticated} /> : <div></div>
                                }
                            />
                            <Route
                                exact
                                path="/survey-prepare"
                                element={
                                    this.state.isLoggedIn ?
                                        <SurveyPreparePage />
                                        : this.state.hasLoadedUser ? <LoginPage userHasAuthenticated={this.userHasAuthenticated} /> : <div></div>
                                }
                            />
                            <Route
                                exact
                                path="/survey-select"
                                element={
                                    this.state.isLoggedIn ?
                                        <SurveySelectPage />
                                        : this.state.hasLoadedUser ? <LoginPage userHasAuthenticated={this.userHasAuthenticated} /> : <div></div>
                                }
                            />
                            <Route
                                exact
                                path="/esg-assessment"
                                element={
                                    this.state.isLoggedIn ?
                                        <SurveyQuestionsPage />
                                        : this.state.hasLoadedUser ? <LoginPage userHasAuthenticated={this.userHasAuthenticated} /> : <div></div>
                                }
                            />
                            <Route
                                exact
                                path="/dashboard"
                                element={
                                    this.state.isLoggedIn ?
                                        <DashboardPage />
                                        : this.state.hasLoadedUser ? <LoginPage userHasAuthenticated={this.userHasAuthenticated} /> : <div></div>
                                }
                            />
                            <Route
                                exact
                                path="/survey-submit"
                                element={
                                    this.state.isLoggedIn ?
                                        <SurveyFinishPage />
                                        : this.state.hasLoadedUser ? <LoginPage userHasAuthenticated={this.userHasAuthenticated} /> : <div></div>
                                }
                            />
                            <Route
                                exact
                                path="/survey-thanks"
                                element={
                                    this.state.isLoggedIn ?
                                        <SurveyThanksPage />
                                        : this.state.hasLoadedUser ? <LoginPage userHasAuthenticated={this.userHasAuthenticated} /> : <div></div>
                                }
                            />
                            <Route exact path="/admin" element={this.state.isLoggedIn && this.state.isAdmin ? <AdminSettingsPage /> : <LandingPage />} />

                            <Route exact path="/admin-manage-users" element={this.state.isLoggedIn && this.state.isAdmin ? <AdminManageUsersPage /> : <LandingPage />} />

                            <Route exact path="/admin" element={<AdminSettingsPage />} />

                                <Route exact path="/admin-manage-users" element={<AdminManageUsersPage />} />
                            {/**                            </Route>  */}
                        </Routes>
                    </div>
                        <Footer />
                </BrowserRouter>
            </userContext.Provider>
        );
    }
}
export default Router;
