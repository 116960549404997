import React, { Component } from "react";
import './SurveyThanksPage.css';

import SurveyThanksIcon from "../../../assets/svg/survey-thanks-check.svg";
import { Navigate } from "react-router-dom";


class SurveyThanksPage extends Component {

    constructor(){
      super();
      

      this.state = {
        redirect: false,        
      }

      this.viewResults = this.viewResults.bind(this);
  }

  viewResults () {
    this.setState({redirect: true});
  }


  render() {
    const { redirect } = this.state;

    if (redirect) {
      return <Navigate to="/dashboard" />;
    }

    return <div className="survey-thanks-page-container">
        <div className="survey-thanks-div">
            <img  alt="" src={SurveyThanksIcon}></img>
            <p className="survey-thanks-heading">Thanks for completing <br/> your assessment.</p>
            <p className="survey-thanks-message">If you have any additional <br/> information you would like to share with us <br/> (links, documents etc) please reach out <br/> to us on <a href="mailto:info@verimpact.com">info@verimpact.com</a> </p>
        </div>
        <div>
           <div className="survey-thanks-bottom-buttons">
                    <div className="survey-thanks-submit-button" onClick={this.viewResults}>See my results</div>
            </div>
        </div>
    </div>;
  }
}

export default SurveyThanksPage;
