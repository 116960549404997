import React, { Component } from "react";
import "./ImpactVerification.css";

import ImpactVerificationIcon from '../../../../assets/svg/impact_verification.svg'; 

import ImpactVerificationDefaultIcon from '../../../../assets/svg/verification_default.svg'; 
import ImpactVerificationBronzeIcon from '../../../../assets/svg/verification_bronze.svg'; 
import ImpactVerificationSilverIcon from '../../../../assets/svg/verification_silver.svg'; 
import ImpactVerificationGoldIcon from '../../../../assets/svg/verification_gold.svg'; 
import ImpactVerificationPlatinumIcon from '../../../../assets/svg/verification_platinum.svg'; 

class ImpactVerification extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedStatus: '',
      title: ' ',
      image: ImpactVerificationDefaultIcon
    };

    this.imageComponentRef = React.createRef();

    this.statuses = [ {
        name: 'Platinum',
        icon: ImpactVerificationPlatinumIcon,
        slug: 'platinum',
      },
      {
        name: 'Gold',
        icon: ImpactVerificationGoldIcon,
        slug: 'gold',
      },  
      {
        name: 'Silver',
        icon: ImpactVerificationSilverIcon,
        slug: 'silver',
      },  
      {
        name: 'Bronze',
        icon: ImpactVerificationBronzeIcon,
        slug: 'bronze',
      } ];
  }

  componentDidUpdate(prevProps, prevState) {
      if (prevProps.medalType !== this.props.medalType) {
        this.setState({selectedStatus: this.props.medalType});
        this.getStatus(this.props.medalType);
      }
    }

   getStatus(medal) {
      this.statuses.forEach((status) => {
        if (status.slug == medal) {
          this.setState({title: status.name, image: status.icon});
        }
      });
    }




 
  render() {

    
      return (
            <div className="dashboard-card">
                <div className="impact-verification-container">
                    <h3 className="impact-verification-title">Impact Verification</h3>
                    <div className="impact-verification-badge-container">
                    <img className="impact-verification-icon" alt="" src={ImpactVerificationIcon} ></img>
                    <img className="impact-verification-status-icon" alt="" src={this.state.image} ref={this.imageComponentRef} ></img>
                    <span className="impact-verification-status-text">{this.state.title}</span>
                    </div>
                    <div className="impact-verification-statuses-container">
                      {this.statuses.map((item,index) => {
                          return (<div key={`verification_status_${index}`} className={`impact-verification-status-item-container ${item.slug === this.state.selectedStatus ? '_status-item-border' : ""}`}>
                            <h4 className="impact-verification-statuses-text">
                              {item.name}
                            </h4>
                            <div>
                                <img className="impact-verification-statuses-icon" alt="" src={item.icon} ></img>
                            </div>
                          </div>)
                      })}
                    </div>
                </div>
            </div>
      );
  }
}

export default ImpactVerification;
