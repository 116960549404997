import React, { Component } from "react";
import "./DashboardBottomCard.css";


import ButtonArrowIcon from '../../../../assets/svg/card-button-arrow.svg';

class DashboardBottomCard extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

 
  render() {
    var cardStyle = {};
    var titleStyle = {};
    var descStyle = {};
    
    if (this.props.bgImage != null && this.props.bgImage !== ""){
      //`url(${bgImage}), linear-gradient(180.1deg, rgba(0, 0, 0, 0.8) 0.08%, rgba(0, 0, 0, 0) 130.14%)`;
      cardStyle.backgroundImage = `linear-gradient(180.1deg, rgba(0, 0, 0, 0.8) 0.08%, rgba(0, 0, 0, 0) 130.14%), url(${this.props.bgImage})`;
      cardStyle.backgroundSize = 'cover';
      cardStyle.backgroundRepeat = 'contain';
    }

    if (this.props.width != null) {
      cardStyle.width = this.props.width;
    }

    if (this.props.titleColor != null && this.props.titleColor !== "") {
      titleStyle.color = this.props.titleColor;
    }
    if (this.props.descColor != null && this.props.descColor !== "") {
      descStyle.color = this.props.titleColor;
    }

    return (<div style={cardStyle} className="dashboard-card dashboard-bottom-card">
      <div >
          <h3 style={titleStyle} className="dashboard-bottom-card-title">{this.props.title}</h3>
          <p  style={descStyle} className="dashboard-bottom-card-desc">{this.props.desc}</p>
    </div>
    {this.props.buttonText != null && this.props.buttonText !== "" ? <div onClick={(e)=> this.props.onButtonClick()} style={{bottom: 30, right: 55,position: 'absolute',cursor:'pointer'}}>
        <div className="dashboard-bottom-button-text" >{this.props.buttonText} <img style={{marginLeft: '14px',height:'8px',width:'8px'}} alt="" src={ButtonArrowIcon}></img> </div>
    </div>: null}
    </div>)
  }
}

export default DashboardBottomCard;
