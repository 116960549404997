import React, { Component } from "react";
import { Navigate, Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { userContext } from "../../context/AuthContext";
import "./ResetPasswordPage.css";
import { authManager } from "../Auth/AuthManager";
import config from "../../constants";
import fetchInterceptor from "../../utils/FetchClient";

class ResetPasswordPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resetEmail: '',
            resetOldPassword: '',
            resetNewPassword: '',
            confirmNewPassword: '',
            emailError: '',
            oldPassError: '',
            newPassError: '',
            reNewPassError: '',
            error: '',
            isLoggedIn: false,
            isAdmin: false,
            redirect: false
        };



        this.verificationCode = (new URLSearchParams(window.location.search)).get("vc");
        // this.verificationEmail = (new URLSearchParams(window.location.search)).get("em");

        this.isFromForgot = this.verificationCode != null;

        this.getUserDetails = this.getUserDetails.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async getUserDetails(userGuid) {
        let token = authManager.getAccessToken();
        token = 'Bearer' + " " + token;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };
        let userDetails: any;
        await fetch(config.url.API_URL + "/VerimpactAPI/GetUserDetailsByName/" + userGuid, requestOptions)
            .then(response => response.json())
            .then(result => {
                userDetails = result;
            })
            .catch(error => console.log('error', error));
        return userDetails;
    }

    async handleSubmit(e) {
        e.preventDefault();

        if (this.state.resetEmail === "") {
            this.setState({ emailError: 'Email field is empty', error: 'Email field is empty' });
            return
        }

        if (!this.isFromForgot && this.state.resetOldPassword === "") {
            this.setState({ emailError: "", oldPassError: 'Old password field is empty', error: 'Old password field is empty' });
            return
        }

        if (this.state.resetNewPassword === "") {
            this.setState({ emailError: "", oldPassError: "", newPassError: 'New password field is empty', error: 'New password field is empty' });
            return
        }

        if (this.state.confirmNewPassword === "") {
            this.setState({ emailError: "", oldPassError: "", newPassError: "", reNewPassError: 'Re-enter New Password field is empty', error: 'Re-enter New Password field is empty' });
            return
        }

        if (this.state.confirmNewPassword !== this.state.resetNewPassword) {
            this.setState({ emailError: "", oldPassError: "", newPassError: "", reNewPassError: "Passwords Don't Match", error: "Passwords Don't Match" });
            return
        }

        this.setState({ emailError: "", oldPassError: "", newPassError: "", reNewPassError: "", error: "" });


        if (this.isFromForgot) {
            console.log(this.state.resetEmail);
            console.log(this.verificationCode);
            console.log(this.state.resetNewPassword);
            console.log(this.state.confirmNewPassword);
            try {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    "email": this.state.resetEmail,
                    "password": this.state.resetNewPassword,
                    "confirmPassword": this.state.confirmNewPassword,
                    "verificationCode": this.verificationCode
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                fetch(config.url.API_URL +"/users/resetpassword", requestOptions)
                    .then(response => response.text())
                    .then(result => console.log(result))
                    .catch(error => console.log('error', error));

                try {
                    let user = authManager.getCurrentAuthenticatedUser();
                    //     let user = await Auth.signIn(
                    //       this.state.resetEmail,
                    //       this.state.confirmNewPassword
                    //     );

                    this.completeResetPass(user);
                } catch (error) {
                    this.setState({ error: error.message });
                }
            } catch (error) {
                this.setState({ error: "Your reset link has expired. Please try again and check if you have requested a newer reset link." });
            }
        } else {
            let user = authManager.getTempUnauthenticatedUser();
            // let user = await Auth.signIn(
            //   this.state.resetEmail,
            //   this.state.resetOldPassword
            // );

            if ((user != null && user.LastLogin == null) | user == null || user.signInUserSession == null || user.signInUserSession.accessToken == null) {
                //THIS PROPABLY MEANS THAT THE USER HAS A TEMPORARY PASSWORD AND HE NEEDS TO CHANGE IT
                //   await Auth.completeNewPassword(user, this.state.confirmNewPassword, {});

                let userDetails = await this.getUserDetails(user);

                var raw = JSON.stringify({
                    "email": userDetails.email,
                    "oldPassword": this.state.resetOldPassword,
                    "newPassword": this.state.resetNewPassword,
                    "confirmPassword": this.state.confirmNewPassword
                });
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                try {
                    let message: any;
                    await fetch(config.url.API_URL + "/users/changepassword", requestOptions)
                        .then(response => {
                            if (!response.ok) {
                                // create error object and reject if not a 2xx response code
                                return response.json().then(text => {
                                    throw new Error(text.title + " " + text.errors["Change Password"][0]);
                                })
                            }
                        })
                        .then(result => {
                        //    console.log(result);
                        })
                        .catch(error => {
                            console.log('error', error);
                            throw error;
                        });
                    let token = authManager.getAccessToken();
                    await this.completeResetPass(token, user);
                } catch (error) {
                    this.setState({ error: error.message });
                    return;
                }
            } else {
                //  await Auth.changePassword(user, this.state.resetOldPassword, this.state.confirmNewPassword);
            }
        }
    }

    async completeResetPass(userToken, userGuid) {
        let belongsToAdminGroup = false;
        let userGroup = authManager.getTempUnauthenticatedUserGroup();
        if (userGroup === "Administrators") {
            belongsToAdminGroup = true;
        }

        //update last login
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        let token = authManager.getAccessToken();
        token = 'Bearer' + " " + token;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json-patch+json");
        myHeaders.append("Accept", "application/json");
        let ops = [];
        ops.push({
            "operationType": 2,
            "path": "/LastLogin",
            "op": "replace",
            "from": "<string>",
            "value": today
        });

        var raw = JSON.stringify(ops);

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            body: raw
        };
        let { response, data } = await fetchInterceptor(config.url.API_URL + "/VerimpactAPI/UpdateProfile/" + userGuid, requestOptions);
        this.setState(() => ({ isLoggedIn: true, isAdmin: belongsToAdminGroup, redirect: true }));

        localStorage.removeItem("unauthUserID");
        localStorage.removeItem("unauthGroup");
        localStorage.setItem('userID', userGuid);
        localStorage.setItem('userGroup', userGroup);
        this.props.userHasAuthenticated(true, belongsToAdminGroup);
    }

  render() {
     const redirect = this.state.redirect;
    const { value } = {
      user: this.state.user,
      isLoggedIn: this.state.isLoggedIn,
      isAdmin: this.state.isAdmin,
    };
    if (redirect) {
      return (
        <userContext.Provider value={value}>
          <Navigate to="/internal" replace={true}/>
        </userContext.Provider>
      );
    }

    // const isMobile = window.innerWidth <= 500;
    // if (isMobile) {
    //   return this.renderMobile();
    // } else {
      return (
        <div className="login-container">
          <div className="login-form">
            <p className="login-heading">Reset your password.</p>
            <form onSubmit={this.handleSubmit}>
            
              <div className="form-field">
                <label className="login-label">Email</label>
                <span className="user-icon-field">
                <input
                  className={`login-form-email-input ${this.state.emailError !== "" ? "error-login-field" : ""}`}
                  placeholder="example@mail.com"
                  name="username"
                  id="username"
                  type="text"
                  value={this.state.resetEmail}
                  onChange={(e) => {this.setState({resetEmail:e.target.value})}}
                />
                </span>
              </div> 
              {this.isFromForgot ? null : 
              <div className="form-field">
                <label className="login-label">Old Password</label>
                <span className="pass-icon-field">
                  <input
                    className={`login-form-password-input ${this.state.oldPassError !== "" ? "error-login-field" : ""}`}
                    placeholder="Must contain at least 5 characters and 1 number"
                    name="old-password"
                    id="old-password"
                    type="password"
                    value={this.state.resetOldPassword}
                    onChange={(e) => {this.setState({resetOldPassword:e.target.value})}}
                  />
                  </span>
              </div> }
              <div className="form-field">
                <label className="login-label">New Password</label>
                <span className="pass-icon-field">
                  <input
                    className={`login-form-password-input ${this.state.newPassError !== "" ? "error-login-field" : ""}`}
                    placeholder="Must contain at least 5 characters and 1 number"
                    name="new-password"
                    id="new-password"
                    type="password"
                    value={this.state.resetNewPassword}
                    onChange={(e) => {this.setState({resetNewPassword:e.target.value})}}
                  />
                </span>
              </div>
              <div className="form-field">
                <label className="login-label">Re-enter New Password</label>
                <span className="pass-icon-field">
                  <input
                    className={`login-form-password-input ${this.state.reNewPassError !== "" ? "error-login-field" : ""}`}
                    placeholder="Must contain at least 5 characters and 1 number"
                    name="reenter-new-password"
                    id="reenter-new-password"
                    type="password"
                    value={this.state.confirmNewPassword}
                    onChange={(e) => {this.setState({confirmNewPassword:e.target.value})}}
                  />
                </span>
                <p className="login-terms">
                  These fields are compulsory, please complete them to proceed.
                  <br />
                  By logging in you are agreeing to terms and conditions.{" "}
                </p>
              </div>

              {this.state.error !== "" ?
              <div className="login-error-item">
                <p>{this.state.error}</p>
              </div> : null}

              <div className="login-submit-button-container">
                
                <input
                  className="a-button-primary submit-button"
                  type="submit"
                  value={this.isFromForgot ? "Reset" : "Login"}
                />
              </div>
            </form>
            <br />
            <br />
            <div className="login-signup-container">
              <p className="login-signup-text">
                Do not have an account? Get in touch with us to set up your
                account.
              </p>
            </div>
          </div>
        </div>
      );
    }
  // }
}

export default ResetPasswordPage;
