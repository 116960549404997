import React, { Component } from "react";
import { Navigate, Link } from 'react-router-dom';
import { userContext } from "../../context/AuthContext";
import "./ForgotPasswordPage.css";
import config from "../../constants";

class ForgotPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
        forgotEmail: '',
        emailError: ''
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
      e.preventDefault();

    if (this.state.forgotEmail === ""){
        this.setState({emailError: "Email field is empty."})
        return
    }
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
          "email": this.state.forgotEmail
      });

      var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
      };

      fetch(config.url.API_URL + "/users/forgotpassword", requestOptions)
          .then(response => response.text())
          .then(result => {
              window.location.replace('/');
          })
          .catch(error => {
              console.log('error', error);
              this.setState({ emailError: error.message });
          });
  }

    render() {
        return (
            <div className="login-container">
                <div className="login-form">
                    <p className="login-heading">Forgot your password?</p>
                    <p className="login-heading-description">Don’t worry! Enter the email address associated with your account and we’ll send you a link to reset your password.</p>
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-field">
                            <label className="login-label">Email</label>
                            <span className="user-icon-field">
                                <input
                                    className={`login-form-email-input ${this.state.emailError !== "" ? "error-login-field" : ""}`}
                                    placeholder="example@mail.com"
                                    name="username"
                                    id="username"
                                    type="text"
                                    value={this.state.forgotEmail}
                                    onChange={(e) => this.setState({ forgotEmail: e.target.value })}
                                />
                            </span>
                            <p className="login-terms">
                                These fields are compulsory, please complete them to proceed.
                                <br />
                                By logging in you are agreeing to terms and conditions.{" "}
                            </p>
                        </div>

                        {this.state.emailError !== "" ?
                            <div className="login-error-item">
                                <p>{this.state.emailError} <br /> Please try again.</p>
                            </div> : null}

                        <div className="login-submit-button-container">
                            <input
                                className="a-button-primary submit-button"
                                type="submit"
                                value="Continue"
                            />
                        </div>
                    </form>
                    <br />
                    <br />
                    <div className="login-signup-container">
                        <a href="/contact" className="login-signup-text">
                            Do not have an account? Get in touch with us to set up your
                            account.
                        </a>
                    </div>
                    <br />
                </div>
            </div>
        );
    }
}

export default ForgotPasswordPage;
