import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import "./DataInitPage.css";
import * as yup from "yup";
import sectionsArray from './sections-array';
import questionsArray from './questions-array';
import AdminMessagePopup from "../AdminPopUp/Message/AdminMessagePopup";

class DataInitPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAdminMessagePopup: false,
      messageTitle: ""
    };

    this.toggleAdminMessagePopup.bind(this);
  }

  closeAdminMessagePopup() {
    this.setState({showAdminMessagePopup: false})
  }

  toggleAdminMessagePopup() {  
    this.setState({  
      showAdminMessagePopup: !this.state.showAdminMessagePopup  
    });  
  }

  async onClickStart() {
    let sectionsResult = await this.getAllSections();
    //console.log(sectionsResult.data.listSurveySections.items.length);
    if (sectionsResult.data.listSurveySections.items.length <= 0) {
      //sections don't exist so we create them
      //assuming tha questions don't exist either
      this.createDataOnTables();
    } else {
      this.setState({showAdminMessagePopup: true, messageTitle: "Data already exists in database, please clear all data from sections and questions before re-initializing sections and questions. Keep in mind that users will have to restart their survey."});
    }
  }

  async createDataOnTables() {
    await Promise.all(sectionsArray.map(async (item) => {
      //console.log(item);

      let abbreviation = item.abbreviation;
      let active = item.active;
      let order = item.order;
      let title = item.title;

   //   await API.graphql({
    //    query: createSurveySections,
    //    authMode: 'AMAZON_COGNITO_USER_POOLS',
    //    variables: {
    //      input: {
    //        abbreviation,
    //        active,
    //        order,
    //        title
    //      },
    //    },
    //  });

    }));


    //get sections
    let sectionsResult = await this.getAllSections();
    //console.log(sectionResult);

    //loop through questions and add the section id on each based on section Abbreviation
    let sections = sectionsResult.data.listSurveySections.items;
    questionsArray.forEach((question) => {
      sections.forEach((section) => {
        if (question.sectionAbbr == section.abbreviation) {
          question.section = section.id;
        }
      });
    });

    //save the questions on the database
    await Promise.all(questionsArray.map(async (item) => {
      let abbreviation = item.abbreviation;
      let active = item.active;
      let indicator = item.indicator;
      let isMandatory = item.isMandatory;
      let maxValue = item.maxValue;
      let minValue = item.minValue;
      let order = item.order;
      let question = item.question;
      let section = item.section;
      let title = item.title;
      let type = item.type;

     // await API.graphql({
     //   query: createSurveyQuestions,
     //   authMode: 'AMAZON_COGNITO_USER_POOLS',
     //   variables: {
     //     input: {
     //       abbreviation,
     //       active,
     //       indicator,
     //       isMandatory,
      //      maxValue,
      //      minValue,
      //      order,
      //      question,
      //      section,
      //      title,
      //      type
      //    },
      //  },
     // });

    }));

    this.setState({showAdminMessagePopup: true, messageTitle: "Initialization of sections and questions is complete. You can now start creating users and allow them take the survey."});

  }


    async getAllSections() {
        let sectionsResult: any;
 //   let sectionsResult = await API.graphql({
 //     query: listSurveySections,
 //     authMode: 'AMAZON_COGNITO_USER_POOLS',
 //     variables: {limit:500}
 //   });
    return sectionsResult;

  }

  renderAdminSettingCard(count,title,desc,btnText,toLink) {
    return (
        <div className="admin-settings-card">

          <div className="admin-settings-card-info">
            <div className="admin-settings-card-count">{count}</div>

            <div className="admin-settings-card-title">{title}</div>

            <div className="admin-settings-card-line"></div>

            <div className="admin-settings-card-desc">{desc}</div>
          </div>
          

          <Link to={toLink} onClick={(e) => {this.onClickStart()}}>
           <div className="admin-settings-button">{btnText}</div>
          </Link>
        </div>
  
    );
  }

  render() {
      return (

        <div className="verimpact-container">

          {this.state.showAdminMessagePopup ?  
          <AdminMessagePopup  
            messageTitle={this.state.messageTitle}
            closePopup={() => {this.closeAdminMessagePopup()}}  
          />  
          : null  
          }
          <div className="admin-settings-container">

           <div className="admin-settings-header">Data Init</div>

           <div className="admin-settings-options-container">
              {this.renderAdminSettingCard("#01","Initialize data","Create sections and questions on the database. Empty tables before re-running script.","Start",'#run')}
           </div>
          </div>

         

        </div>
      );
  }
}

export default DataInitPage;
