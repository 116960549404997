import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import "./AdminSettingsPage.css";
import * as yup from "yup";

class AdminSettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  renderAdminSettingCard(count,title,desc,btnText,toLink) {
    return (
        <div className="admin-settings-card">

          <div className="admin-settings-card-info">
            <div className="admin-settings-card-count">{count}</div>

            <div className="admin-settings-card-title">{title}</div>

            <div className="admin-settings-card-line"></div>

            <div className="admin-settings-card-desc">{desc}</div>
          </div>
          

          <Link to={toLink}>
           <div className="admin-settings-button">{btnText}</div>
          </Link>
        </div>
  
    );
  }

  render() {
      return (
        <div className="verimpact-container">
          <div className="admin-settings-container">

           <div className="admin-settings-header">Manage Users</div>

           <div className="admin-settings-options-container">
              {this.renderAdminSettingCard("#01","Manage Users","Manage individual users","Manage",'/admin-manage-users')}
              {this.renderAdminSettingCard("#02","Edit Report","Add or change questions on the ESG report","Edit","admin-edit-report")}
           </div>
          </div>

         

        </div>
      );
  }
}

export default AdminSettingsPage;
