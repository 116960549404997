import React, { Component } from "react";
import TermsConditionsPopup from "../Popup/TermsConditions/TermsConditionsPopup"
import CookieNoticePopup from "../Popup/CookieNotice/CookieNoticePopup"
import CookiePolicyPopup from "../Popup/CookiePolicy/CookiePolicyPopup"
import "./Footer.css";

class Footer extends Component {
        constructor(props){  
                super(props);  
        this.state = { showPopup: false, showCookieNotice: true, showCookiePolicy: false };  
}

togglePopup() {  
        this.setState({  
             showPopup: !this.state.showPopup  
        });  
 }

toggleCookieNotice () {
        this.setState({
                showCookieNotice: !this.state.showCookieNotice
        });  
}  

toggleCookiePolicy () {
        this.setState({
                showCookiePolicy: !this.state.showCookiePolicy
        });  
}

 render() {
return (
<div className="footer-container">

        <div className="footer-left-coloumn-logo">
                <a href="/">
                  <img src="/assets/images/logo.png" alt="Verimpact Logo" />
                </a>
                <div className="trademark-logo">© Verimpact 2022. All rights reserved</div>
        </div>
<div className="social-container">
        <a href="https://www.facebook.com/verimpact">
<img className="footer-icon" src="/assets/images/facebook.png" alt="Facebook"/>
        </a>
        <a href="https://www.instagram.com/verimpact/">
<img className="footer-icon" src="/assets/images/instagram.png" alt="Instagram"/>
        </a>
        {/**
        <a href="https://www.twitter.com/verimpact/">
<img className="footer-icon" src="/assets/images/twitter.png" alt="Twitter"/>
        </a>
        {/**
        <a href="https://www.youtube.com/channel/">
<img className="footer-icon" src="/assets/images/youtube.png" alt="Youtube"/>
        </a>
*/}
        <a href="https://www.linkedin.com/company/verimpact/about/">
<img className="footer-icon" src="/assets/images/linkedin.png" alt="LinkedIn"/>
        </a>
</div>
<div className="terms-conditions-button" onClick={this.togglePopup.bind(this)}><p className="terms-conditions">Terms and Conditions</p></div>
{this.state.showPopup ?  
<TermsConditionsPopup  
  text='Terms and Conditions'  
  closePopup={this.togglePopup.bind(this)}  
/>  
: null  
}

{this.state.showCookiePolicy ?  
<CookiePolicyPopup  
  text='Cookie Policy'  
  closeCookiePolicy={this.toggleCookiePolicy.bind(this)}  
/>  
: null  
}

{this.state.showCookieNotice ?
        <CookieNoticePopup 
        closeCookieNotice={this.toggleCookieNotice.bind(this)}
        openCookiePolicy={this.toggleCookiePolicy.bind(this)} />
: null }
        {/**
        <div className="footer-logo-container">
                <div className="footer-verture">
                        <svg width="156" height="34" viewBox="0 0 156 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M37.7287 9.43384C34.4202 9.43384 31.6459 10.5286 29.4059 12.718C27.1659 14.9074 26.042 17.7294 26.0342 21.1838C26.0342 24.6324 27.1445 27.4544 29.365 29.6496C30.4226 30.7181 31.6863 31.5604 33.0794 32.1254C34.4725 32.6905 35.9659 32.9665 37.4689 32.9367C42.344 32.9367 45.8335 30.7755 47.9373 26.4531L43.6869 24.0447C42.3148 26.7693 40.2714 28.1268 37.5564 28.117C36.0232 28.1613 34.5321 27.6123 33.3936 26.5844C32.2804 25.5744 31.6382 24.2237 31.4669 22.5325H48.5941V20.5591C48.5941 17.3187 47.5792 14.6544 45.5494 12.5662C43.5195 10.478 40.9126 9.43384 37.7287 9.43384ZM31.4669 18.4105C31.8604 17.0898 32.6477 15.9206 33.7235 15.0592C34.8038 14.18 36.1609 13.7125 37.5535 13.7397C39.1338 13.7397 40.4339 14.1552 41.4536 14.9862C42.4972 15.8564 43.1832 17.0808 43.3804 18.4251L31.4669 18.4105Z" fill="black"/>
                                <path d="M57.0013 13.5966V10.0468H51.3496V32.3002H57.0013V21.3063C57.0013 19.3777 57.4761 17.8003 58.4259 16.5742C59.3756 15.3481 60.6825 14.7351 62.3464 14.7351C62.9728 14.7274 63.5955 14.8312 64.1855 15.0416L65.1051 9.69646C64.4799 9.5116 63.8303 9.42301 63.1784 9.43373C60.4635 9.43373 58.4045 10.8213 57.0013 13.5966Z" fill="black"/>
                                <path d="M79.5736 27.9185C77.6741 27.9185 76.7244 26.8676 76.7244 24.7657V14.6476H81.5879V10.0469H76.7244V4.04492H71.0757V10.0469H67.6572V14.6476H71.0757V25.8605C71.0757 30.5624 73.4549 32.9134 78.2132 32.9134C79.4951 32.9235 80.7694 32.7163 81.982 32.3003L81.4565 27.6558C80.8451 27.8348 80.2107 27.9234 79.5736 27.9185Z" fill="black"/>
                                <path d="M100.115 21.5256C100.115 23.3355 99.6402 24.8098 98.6905 25.9483C98.2397 26.502 97.6673 26.9441 97.0176 27.2403C96.368 27.5366 95.6587 27.6788 94.9451 27.656C91.9383 27.656 90.4349 25.9629 90.4349 22.5765V10.0471H84.7832V23.8464C84.7832 26.7365 85.55 28.9745 87.0836 30.5607C88.6172 32.1468 90.5954 32.935 93.0184 32.9253C96.2296 32.9253 98.5951 31.5522 100.115 28.8062V32.3093H105.764V10.0471H100.115V21.5256Z" fill="black"/>
                                <path d="M115.838 13.5966V10.0468H110.187V32.3002H115.838V21.3063C115.838 19.3777 116.313 17.8003 117.263 16.5742C118.213 15.3481 119.519 14.7351 121.183 14.7351C121.81 14.7277 122.432 14.8315 123.022 15.0416L123.942 9.69646C123.317 9.5116 122.667 9.42301 122.015 9.43373C119.298 9.43373 117.239 10.8213 115.838 13.5966Z" fill="black"/>
                                <path d="M146.693 20.5591C146.693 17.3187 145.678 14.6544 143.648 12.5662C141.618 10.478 139.012 9.43384 135.83 9.43384C132.522 9.43384 129.748 10.5286 127.507 12.718C125.267 14.9074 124.144 17.7294 124.136 21.1838C124.136 24.6324 125.245 27.4544 127.464 29.6496C128.521 30.7181 129.785 31.5604 131.178 32.1254C132.571 32.6905 134.065 32.9665 135.568 32.9367C140.433 32.9367 143.922 30.7755 146.036 26.4531L141.786 24.0447C140.413 26.7693 138.37 28.1268 135.655 28.117C134.122 28.1607 132.631 27.6118 131.492 26.5844C130.383 25.5744 129.741 24.2237 129.566 22.5325H146.693V20.5591ZM129.566 18.4105C129.96 17.0903 130.747 15.9214 131.822 15.0592C132.903 14.1796 134.262 13.712 135.655 13.7397C137.231 13.7397 138.531 14.1552 139.552 14.9862C140.596 15.8564 141.282 17.0808 141.479 18.4251L129.566 18.4105Z" fill="black"/>
                                <path d="M154.944 26.7624C154.242 26.095 153.311 25.7229 152.342 25.7229C151.373 25.7229 150.441 26.095 149.739 26.7624C149.395 27.0983 149.123 27.5016 148.941 27.9471C148.76 28.3926 148.671 28.8708 148.683 29.3518C148.683 30.3219 149.068 31.2523 149.754 31.9383C150.44 32.6242 151.37 33.0096 152.34 33.0096C153.311 33.0096 154.241 32.6242 154.927 31.9383C155.613 31.2523 155.998 30.3219 155.998 29.3518C156.01 28.871 155.922 28.3929 155.741 27.9474C155.559 27.502 155.288 27.0986 154.944 26.7624Z" fill="#2E2EFF"/>
                                <path d="M22.7701 0.89502L15.7202 20.959L14.5349 24.9438C14.2138 23.8345 13.8246 22.5062 13.3672 20.959L11.1399 14.6564L9.51091 10.0498H0V14.6564H4.96272L11.5106 32.3032H17.571L29.21 0.89502H22.7701Z" fill="black"/>
                        </svg>

                        <div className="trademark-logo">Powered by Verture</div>
                </div>
                <div className="footer-certified">
                        <img alt="" src="/assets/images/eplo-logo.jpg" height="70" />
                        <div className="trademark-logo">Certified by EPLO ISD</div>
                </div>  
        </div>*/}
</div>
    );
}
}

export default Footer;
