import React from "react";
import "./AdminMessagePopup.css";

import CloseIcon from '../../../../assets/svg/close.svg';
import UserAddedCheck from '../../../../assets/svg/user-added-check.svg'


class AdminMessagePopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
   }

   

    render() {
        return (
        <div className="admin-message-popup">
            <div className="admin-message-popup_open">


            <div className="admin-message-container">

                <img alt="" src={UserAddedCheck}></img>

                <br/>

                <div className="admin-message-popup-title">{this.props.messageTitle}</div>

                <br/>

                <button className="admin-message-back-button" onClick={() => this.props.closePopup()}>Back</button>

            </div>            
            
            </div>
        </div>
        );
    }
}
export default AdminMessagePopup;
