import React, { Component } from "react";
import "./SlideRangeStepSelect.css";
 

class SlideRangeStepSelect extends Component {

  constructor(props) {
    super(props);
    this.state = {
      rangeMarginLeft: 0,
      userAnswered: false,
    };

    this.getStateOfUserAnswered();
  }

  async getStateOfUserAnswered () {
    await this.setState({userAnswered: this.props.userAnswered});
  }

  componentDidMount() {
    this.showSliderValue(this.props.rangeValue, this.props.userAnswered);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.rangeValue !== this.props.rangeValue) {
      this.showSliderValue(this.props.rangeValue, this.state.userAnswered);
    }
  }

  showSliderValue(value, answered) {
    //Set left margin to the label based on the value of the slide range
    const val = value;
    const min = 0 ? 0 : 0;
    const max = 100 ? 100 : 100;
    const newVal = Number(((val - min) * 100) / (max - min));
    this.setState({rangeMarginLeft: newVal, userAnswered: answered})
  }


  onAnswerRangeChange(e) {
    this.showSliderValue(e.target.value, true);
    this.props.onChangeRange(e.target.value);
  }
  

  render() {
    return (<div>
        <div className={`survey-answer-slide-container ${(this.props.userAnswered == true && this.state.userAnswered == false) ?  "" : this.state.userAnswered == true ? "" : "no-answer"}`}>
        <div >
        <div className="slider-wrapper">
           
            <input className="step-range-input" list="tickmarks" ref={this.rangeRef} type="range" min="1" max="10" step={1} value={this.props.rangeValue} onChange={(e)=> {this.onAnswerRangeChange(e)}}  id="rs-range-step-line"/> 
            <div className="ticks">
              <div className="breakpoint"></div>
              <div className="breakpoint"></div>
              <div className="breakpoint"></div>
              <div className="breakpoint"></div>
              <div className="breakpoint"></div>
              <div className="breakpoint"></div>
              <div className="breakpoint"></div>
              <div className="breakpoint"></div>
              <div className="breakpoint"></div>
              <div className="breakpoint"></div>
            </div>
           </div>
           <br/>
           <datalist className="range_datalist" id="tickmarks">
            <option value="1" className={`range_datalist_low`}>1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10" className={`range_datalist_high`}>10</option>
          </datalist>
        </div>
   
      </div>
    </div>);
  }
}

export default SlideRangeStepSelect;
