import React, { Component } from "react";
import config from "../../constants";
import { authManager } from "../Auth/AuthManager";
import "./LandingPage.css";


class LandingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            isAlertVisible: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);
    }


    handleButtonClick() {
        this.setState({ isAlertVisible: true });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({ [name]: value });
    }


    handleSubmit = async (event) => {
        event.preventDefault();
        const {
            email,
        } = this.state;

        try {
           // let token = await authManager.getAccessToken();
           // token = 'Bearer' + " " + token;
            var myHeaders = new Headers();
           // myHeaders.append("Authorization", token);
                //          headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            myHeaders.append("Content-Type", "application/json");
            const response = await fetch(config.url.API_URL + "/VerimpactAPI/AddSubscriber", {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({
                    email: this.state.email
                })
            });
            const data = await response.json();
            this.setState({ redirect: true });
        } catch (e) {
            console.log(e);
        }
    }

    render() {

        var sliderImage = "/assets/images/verimpact-slide-bg.jpg";
        if (window.innerWidth <= 720) {
            sliderImage = "/assets/images/verimpact-mobile-slide-bg.jpg";
        } else if (window.innerWidth > 2000) {
            sliderImage = "/assets/images/verimpact-slide-bg-large.jpg";
        }

        return (
            <div className="landing-parent-div">
                <div className="landing-banner">
                    <div className="image-div">
                        <img
                            src={sliderImage}
                            className="landing-img"
                            alt="Fishing Boat"
                        />
                    </div>
                    <div className="landing-page-container-title">
                        <div>
                            <h2 className="landing-page-title">
                                Measure impact,
                <br />
                drive change<span className="text-dot black">.</span>
                            </h2>
                        </div>
                        <div>
                            <p className="landing-page-title-heading">
                                We provide accurate
                                ESG reporting to your company's investors and shareholders
              </p>
                        </div>
                        <div>
                            <p className="landing-page-title-heading quote">
                                “Progress is impossible without change.”<br /><small>George Bernard Shaw</small>
                            </p>
                        </div>
                    </div>
                </div>

                <div id="add_value" className="value-added-title-div section-title-div">
                    <h3 className="value-added-title section-title top-space bot-space">How we add value<span className="text-dot">.</span></h3>
                </div>
                <div className="value-added-cards-div container">
                    <div className="value-added-card-div">
                        <div className="value-added-card-inner">
                            <div className="value-added-card-front">
                                <img
                                    className="value-added-image"
                                    src="/assets/images/value-added-1.png"
                                    alt="Data"
                                />
                                <div className="value-added-text">Data</div>
                            </div>
                            <div className="value-added-card-back">
                                <div className="value-added-card-back-text">
                                    We help gather key data on your company to derive your ESG rating
                </div>
                            </div>
                        </div>
                    </div>
                    <div className="value-added-card-div">
                        <div className="value-added-card-inner">
                            <div className="value-added-card-front">
                                <img
                                    className="value-added-image"
                                    src="/assets/images/value-added-2.png"
                                    alt="Reporting"
                                />
                                <div className="value-added-text">Reporting</div>
                            </div>
                            <div className="value-added-card-back">
                                <div className="value-added-card-back-text">
                                    Through our reporting solutions, we provide you with key metrics and actionable insights
                </div>
                            </div>
                        </div>
                    </div>
                    <div className="value-added-card-div">
                        <div className="value-added-card-inner">
                            <div className="value-added-card-front">
                                <img
                                    className="value-added-image"
                                    src="/assets/images/value-added-3.png"
                                    alt="Performance"
                                />
                                <div className="value-added-text">Performance</div>
                            </div>
                            <div className="value-added-card-back">
                                <div className="value-added-card-back-text">
                                    Empowering your company to strive towards better practices, improving your prospects for investment and thus growth
                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="interested-container container top-space">
                    <div className="empty-narrow-between-div" />
                    <div className="interested-text-div">
                        <p className="interested">
                            Interested in starting your ESG
              <br />assessment or have a question?
            </p>
                    </div>
                    <div className="empty-super-narrow-between-div" />
                    <hr className="interested-hr" />
                    <div className="empty-super-narrow-between-div" />
                    <div className="interested-text-div">
                        <div className="contact-us-container">
                            <a className="contact-us a-button-primary" href="/contact">
                                Contact Us
              </a>
                        </div>
                    </div>
                    <div className="empty-narrow-between-div" />
                </div>

                <div id="our_services" className="services-title-div section-title-div">
                    <h3 className="services-title section-title  top-space bot-space">How we can help you<span className="text-dot">.</span></h3>
                </div>
                <div className="service-cards-container container">
                    <div className="service-card-container">
                        <div className="service-card-inner">
                            <div className="service-card-front">
                                <div className="services-image-container">
                                    <img
                                        className="services-image1"
                                        src="/assets/images/service-1.png"
                                        alt="Training"
                                    />
                                    <div className="service-card-text">Training</div>
                                </div>
                            </div>
                            <div className="service-card-back">
                                <div className="service-card-back-text">
                                    Tailor-made C-suite, whole company and company ambassadors training through online courses, one-to-one learning, group seminars
                </div>
                            </div>
                        </div>
                    </div>
                    <div className="service-card-container">
                        <div className="service-card-inner">
                            <div className="service-card-front">
                                <div className="services-image-container">
                                    <img
                                        className="services-image1"
                                        src="/assets/images/service-2.png"
                                        alt="Training"
                                    ></img>
                                    <div className="service-card-text">
                                        Strategic Planning for ESG Integration
                  </div>
                                </div>
                            </div>
                            <div className="service-card-back">
                                <div className="service-card-back-text">
                                    Strategic planning in order to achieve ESG integration in
                                    the operations, management and other functions of a company
                </div>
                            </div>
                        </div>
                    </div>
                    <div className="service-card-container">
                        <div className="service-card-inner">
                            <div className="service-card-front">
                                <div className="services-image-container">
                                    <img
                                        className="services-image1"
                                        src="/assets/images/service-3.png"
                                        alt="Training"
                                    ></img>
                                    <div className="service-card-text">ESG Reporting</div>
                                </div>
                            </div>
                            <div className="service-card-back">
                                <div className="service-card-back-text">
                                    ESG reporting for companies or third-party verification
                </div>
                            </div>
                        </div>
                    </div>
                    <div className="service-card-container">
                        <div className="service-card-inner">
                            <div className="service-card-front">
                                <div className="services-image-container">
                                    <img
                                        className="services-image1"
                                        src="/assets/images/service-4.png"
                                        alt="Training"
                                    ></img>
                                    <div className="service-card-text">ESG Assessment</div>
                                </div>
                            </div>
                            <div className="service-card-back">
                                <div className="service-card-back-text">
                                    ESG assessment services, through our proprietary ESG tool
                                    and methodology
                </div>
                            </div>
                        </div>
                    </div>
                    <div className="service-card-container">
                        <div className="service-card-inner">
                            <div className="service-card-front">
                                <div className="services-image-container">
                                    <img
                                        className="services-image1"
                                        src="/assets/images/service-5.png"
                                        alt="Training"
                                    ></img>
                                    <div className="service-card-text">
                                        Business Culture Transformation
                  </div>
                                </div>
                            </div>
                            <div className="service-card-back">
                                <div className="service-card-back-text">
                                    Commence a business culture transformation in order to be
                                    ESG-compliant as a business and as an entity
                </div>
                            </div>
                        </div>
                    </div>
                    <div className="service-card-container">
                        <div className="service-card-inner">
                            <div className="service-card-front">
                                <div className="services-image-container">
                                    <img
                                        className="services-image1"
                                        src="/assets/images/service-6.png"
                                        alt="Training"
                                    ></img>
                                    <div className="service-card-text">ESG Audits</div>
                                </div>
                            </div>
                            <div className="service-card-back">
                                <div className="service-card-back-text">
                                    ESG audit services as part of due diligence for prospective
                                    investors or part of other audits
                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div id="our_clients" className="clients-title-div section-title-div">
                    <h3 className="clients-title section-title  top-space bot-space">Indicative clients<span className="text-dot">.</span></h3>
                </div>
                <div className="clients-cards-container container">
                    <div className="clients-item">
                        <div className="clients-container">
                            <a className="" href="https://ec.europa.eu/">
                                <div className="clients-img" style={{ 'backgroundImage': "url('/assets/images/ec.png')" }}>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="clients-item">
                        <div className="clients-container">
                            <a className="" href="https://www.pmi.com/markets/greece/el/about-us/overview ">
                                <div className="clients-img" style={{ 'backgroundImage': "url('/assets/images/papastratos.png')" }}>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="clients-item">
                        <div className="clients-container">
                            <a className="" href="https://www.hillintl.com/">
                                <div className="clients-img" style={{ 'backgroundImage': "url('/assets/images/hill2.png')" }}>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="clients-item">
                        <div className="clients-container">
                            <a className="" href="https://www.ominvest.net/">
                                <div className="clients-img" style={{ 'backgroundImage': "url('/assets/images/ominvest.png')" }}>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="clients-item">
                        <div className="clients-container">
                            <a className="" href="https://www.ibiden.com/ ">
                                <div className="clients-img" style={{ 'backgroundImage': "url('/assets/images/ibiden.png')" }}>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="clients-item">
                        <div className="clients-container">
                            <a className="" href="https://www2.deloitte.com/nl/nl.html">
                                <div className="clients-img" style={{ 'backgroundImage': "url('/assets/images/deloitte.png')" }}>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="clients-item">
                        <div className="clients-container">
                            <a className="" href="https://www.pelagicdata.com/">
                                <div className="clients-img" style={{ 'backgroundImage': "url('/assets/images/pelagic.png')" }}>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="clients-item">
                        <div className="clients-container">
                            <a className="" href="https://babylongardens.eu/">
                                <div className="clients-img" style={{ 'backgroundImage': "url('/assets/images/babylongardens.jpg')" }}>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                 <div id="team" className="team-title-div section-title-div">
          <h3 className="team-title section-title  top-space bot-space">Meet the team<span className="text-dot">.</span></h3>
        </div>
        <div className="team-cards-container container">
          <div className="card-item">
            <div className="card-container">
              <div className="card-img" style={{'backgroundImage':"url('/assets/images/team-spyros-kouvelis.jpg')"}}></div>
              <div className="card-content">
                <h4 className="card-name">Spyros Kouvelis<span className="text-dot">.</span></h4>
                <p className="card-position">Senior Sustainability Advisor</p>
                <p className="card-descr">
                  Spyros is a world-renowned expert in sustainability. His career spans over 30 years in sustainable development and many entities, including UNEP, WWF, the Ramsar Convention, and EPLO. During his tenures as MP at the Hellenic Parliament and Deputy Minister for Foreign Affairs, he was a strong advocate for environmental protection and building robust governance systems. As Senior Associate of the University of Cambridge CISL and Director of the EPLO Institute for Sustainable Development, he has been promoting ESG integration and compliance, and building Verimpact, a verifiable ESG decision-making system.
                </p>
              </div>
            </div>
          </div>
          <div className="card-item">
            <div className="card-container">
                            <div className="card-img" style={{ 'backgroundImage':"url('/assets/images/team-christina-deligianni-new.jpg')", 'filter': "grayscale(100%)", '-webkit-filter': "grayscale(100%)"}}></div>
              <div className="card-content">
                <h4 className="card-name">Christina Deligianni<span className="text-dot">.</span></h4>
                <p className="card-position">Managing Director</p>
                <p className="card-descr">
                  Christina has worked in political and public affairs, and business development for more than 20 years in Greece and abroad. Her recent projects range from ESG strategy and audits to renewable energy to the blue economy. Christina holds an MA in the Humanities from the University of Chicago where she was a Fulbright Scholar, and a BA in English and Greek from the University of Athens. She has been trained in Public Diplomacy at the Foreign Service Institute, US Department of State. Fluent in Greek, Spanish, English and has studied Mandarin Chinese and Italian.
                </p>
              </div>
            </div>
                    </div>
                    {/*   <div className="card-item">
                        <div className="card-container">
                            <div className="card-img" style={{ 'backgroundImage': "url('/assets/images/team-konstantinos-liolios.jpg')" }}></div>
                            <div className="card-content">
                                <h4 className="card-name">Konstantinos Liolios<span className="text-dot">.</span></h4>
                                <p className="card-position">Director of Engineering</p>
                                <p className="card-descr">
                                    I love delivering products that make our life better or more fun. By participating in large biomedical projects, Silicon Valley preIPO startups, small2medium businesses as well as more established companies like Amazon, I had the pleasure of gaining experience in a wide array of fields including fintech, bioinformatics, video streaming, retail, government and gaming. During my tenure at Amazon, I was engaged with the Sustainability Ambassador program and I am excited to be given the opportunity to continue raising awareness by joining the Verimpact team.
                                </p>
                            </div>
                        </div>
                    </div>*/}
                    <div className="card-item">
                        <div className="card-container">
                            <div className="card-img" style={{ 'backgroundImage': "url('/assets/images/team-marina-deligianni.png')" }}></div>
                            <div className="card-content">
                                <h4 className="card-name">Marina Deligianni<span className="text-dot">.</span></h4>
                                <p className="card-position">Operations and Training Officer</p>
                                <p className="card-descr">
                                    Marina leads training and operations at Verimpact, leveraging on her vast experience in working with people with learning difficulties and disabilities. With studies in Theology, Advanced Music Theory and Vocal Singing and teaching at SOAS, University of London, she brings value to training teams, especially in the S pillar of ESG, and makes sure things run smoothly over multiple global projects and diverse time zones.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/*   <div className="card-item">
            <div className="card-container">
              <div className="card-img" style={{'backgroundImage':"url('/assets/images/team-stafania-mouzouris.jpg')"}}></div>
              <div className="card-content">
                <h4 className="card-name">Stefania Mourouzis<span className="text-dot">.</span></h4>
                <p className="card-position">Product Director</p>
                <p className="card-descr">
                  Stefania has worked in product for multiple years. With a background in data science and business her focus has typically been around building products focusing on machine learning and personalisation. She joined the Verimpact team to support in developing a simple yet revolutionary tool.
                </p>
              </div>
            </div>
          </div>
          <div className="card-item">
            <div className="card-container">
              <div className="card-img" style={{'backgroundImage':"url('/assets/images/team-alexandra-mousourouli.jpg')"}}></div>
              <div className="card-content">
                <h4 className="card-name">Alexandra Mousourouli<span className="text-dot">.</span></h4>
                <p className="card-position">Designer & Art Director</p>
                <p className="card-descr">
                  Alexandra is an Art Director and Designer. She has studied Communication Design and holds a Master Degree in Graphic Design. She has worked in various agencies and studios in Spain and Greece for multiple years. She is passionate about sustainability and an advocate of eco-lifestyle.
                </p>
              </div>
            </div>
          </div>
          <div className="card-item">
            <div className="card-container">
              <div className="card-img" style={{'backgroundImage':"url('/assets/images/Pantelis.JPG')"}}></div>
              <div className="card-content">
                <h4 className="card-name">Pantelis Spagis<span className="text-dot">.</span></h4>
                <p className="card-position">Training Manager</p>
                <p className="card-descr">
                Pantelis has had the privilege to work and study in 6 countries on 3 continents over the last 20 years. He has held advisory positions within the Greek Government, founded a not-for-profit in Greece and held managerial positions in Oman, Qatar and the U.A.E. throughout his professional career. A graduate of London School of Economics and Athens University of Economics and Business, he is currently pursuing a PhD in Design from IIT Institute of Design, conducting research on the future and democratization of the public policy-making process through the application of design thinking and innovative technological solutions.
                </p>
              </div>
            </div>
          </div>
          <div className="card-item">
            <div className="card-container">
              <div className="card-img" style={{'backgroundImage':"url('/assets/images/team-giannis-boulmetis.jpg')"}}></div>
              <div className="card-content">
                <h4 className="card-name">Giannis Boulmetis<span className="text-dot">.</span></h4>
                <p className="card-position">Developer</p>
                <p className="card-descr">
                  Giannis is passionate about delivering high quality services and products. Has worked as full stack developer on Web and mobile Applications for the past 10 years.
                </p>
              </div>
            </div>
          </div>*/}
        </div>


                <div className="interested-container container top-space bot-space">
                    <div className="email-subscription-text-div">
                        <div className="email-sub-div section-title-div">
                            <h3 className="email-sub-title section-title   ">Subscribe to our newsletter<span className="text-dot">.</span></h3>
                        </div>
                        <form onSubmit={this.handleSubmit}>
                            <input
                                className="email-form-text-input"
                                placeholder="Email*"
                                name="email"
                                type="text"
                                value={this.state.email}
                                onChange={this.handleChange}
                                required="required"
                            />
                            <input className="a-button-primary send-button" type="submit" value="Subscribe" />
                        </form>
                    </div>
                    {/*   <button onClick={this.handleButtonClick}>Show alert</button>
                    {this.state.isAlertVisible && <div className="alert-container">
                        <div className="alert-inner">Alert! Alert!</div>
                    </div>} */}
                    <div className="empty-narrow-between-div" />
                </div>


                <div className="empty-narrow-between-div" />
            </div>
        );

    }
}

export default LandingPage;
