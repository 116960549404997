//import 'bootstrap/dist/css/bootstrap.min.css';
//import $ from 'jquery'; 
//import "bootstrap/dist/js/bootstrap.min.js";
import { createRoot } from 'react-dom/client';
import React from 'react';
import ReactDOM from 'react-dom';
import Router from './components/Routes/Router';
import reportWebVitals from './reportWebVitals';
import './index.css';


const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
//root.render(<React.StrictMode><Router /></React.StrictMode>);
root.render(<Router />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
