import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import { authManager } from "../Auth/AuthManager";
import { userContext } from "../../context/AuthContext";
import config from "../../constants";
import "./LoginPage.css";
import fetchInterceptor from "../../utils/FetchClient";

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            userGuid: null,
            user: null,
            isLoggedIn: false,
            isAdmin: false,
            redirect: false,
            error: ''
        };
        // this.signIn = this.signIn.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setUserGuid = this.setUserGuid.bind(this);
    }

    handleChange(event) {
        const value = event.target.value;
        this.setState({
            [event.target.name]: value,
        });
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        this.signIn(this.username, this.password);
    };

    async setUserGuid(value) {
        await this.setState({
            userGuid: value
        }, () => this.getUserDetails());
    }

    async getUserDetails() {
        let token = await authManager.getAccessToken();
        token = 'Bearer' + " " + token;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };
        let userDetails: any;
        let userGuid = authManager.getCurrentAuthenticatedUser();
        await fetch(config.url.API_URL + "/VerimpactAPI/GetUserDetailsByName/" + userGuid, requestOptions)
            .then(response => response.json())
            .then(result => {
                userDetails = result;
            })
            .catch(error => console.log('error', error));
        return userDetails;
    }

    async signIn(username, password) {
        let belongsToAdminGroup = false;
        try {
            const user = await authManager.signIn(this.state.username, this.state.password);
            belongsToAdminGroup = user.role.includes(
                "Administrators"
            );
            let userDetails: any;
            if (!belongsToAdminGroup) {
                await this.setUserGuid(user.sub);
                userDetails = await this.getUserDetails();
                if (userDetails.active == false) {
                    throw new Error("NotAuthorizedException");
                }
                localStorage.setItem('firstName', userDetails.firstName);
            }

            //if (user == null || user.signInUserSession == null || user.signInUserSession.accessToken == null) {
            if (userDetails != null && userDetails.lastLogin == null) {
                //THIS PROPABLY MEANS THAT THE USER HAS A TEMPORARY PASSWORD AND HE NEEDS TO CHANGE IT
                localStorage.setItem('unauthUserID', user.sub);
                localStorage.setItem('unauthGroup', user.role);
                window.location.replace('/reset')
                return;
                //await Auth.completeNewPassword(user, this.state.password, {});
            }

            localStorage.setItem('userID', user.sub);
            localStorage.setItem('userGroup', user.role);

            //update last login
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy;
            if (!belongsToAdminGroup) {
                try {
                    let token = await authManager.getAccessToken();
                    token = 'Bearer' + " " + token;
                    var myHeaders = new Headers();
                    myHeaders.append("Authorization", token);
                    myHeaders.append("Content-Type", "application/json-patch+json");
                    myHeaders.append("Accept", "application/json");
                    let ops = [];

                    ops.push({
                        "operationType": 2,
                        "path": "/LastLogin",
                        "op": "replace",
                        "from": "",
                        "value": today
                    });
                    var raw = JSON.stringify(ops);

                    var requestOptions = {
                        method: 'PATCH',
                        headers: myHeaders,
                        body: raw
                    };
                    let userGuid = authManager.getCurrentAuthenticatedUser();
                    await fetchInterceptor(config.url.API_URL + "/VerimpactAPI/UpdateProfile/" + userGuid, requestOptions);
                } catch (e) {
                    console.log(e);
                }
            }

            this.setState(() => ({ isLoggedIn: true, isAdmin: belongsToAdminGroup, redirect: true }));
            this.props.userHasAuthenticated(true, belongsToAdminGroup);
        } catch (error) {
            console.error(error.name + "\t" + error.message);
            this.setState({ error: error.message });
            if (error.code === "UserNotConfirmedException") {
                this.setState({ error: "Login failed." });
            } else if (error.message === "NotAuthorizedException") {
                this.setState({ error: "User not authorized." });
            } else if (error.code === "UserNotFoundException") {
                this.setState({ error: "Login failed." });
            } else {
                this.setState({ error: "An error has occurred." });
                console.error(error);
            }
        }
    }

    componentDidMount() { }


    render() {
        const redirect = this.state.redirect;
        const { value } = {
            user: this.state.user,
            isLoggedIn: this.state.isLoggedIn,
            isAdmin: this.state.isAdmin,
        };
        if (redirect) {
            return (
                <userContext.Provider value={value}>
                    <Navigate to="/internal" replace={true} />
                </userContext.Provider>
            );
        }
        // const isMobile = window.innerWidth <= 500;
        // if (isMobile) {
        //   return this.renderMobile();
        // } else {
        return (
            <div className="login-container">
                <div className="login-form">
                    <p className="login-heading">Enter your details to login.</p>
                    <form onSubmit={this.handleSubmit}>
                        <div className={`form-field`}>
                            <label className="login-label">Email</label>
                            <span className="user-icon-field">
                                <input
                                    className={`login-form-email-input ${this.state.error !== "" ? "error-login-field" : ""}`}
                                    placeholder="example@mail.com*"
                                    name="username"
                                    id="username"
                                    type="text"
                                    value={this.state.username}
                                    onChange={this.handleChange}
                                />
                            </span>
                        </div>

                        <div className="form-field ">
                            <label className="login-label">Password</label>
                            <span className="pass-icon-field">
                                <input
                                    className={`login-form-password-input ${this.state.error !== "" ? "error-login-field" : ""}`}
                                    placeholder="Must contain at least 5 characters and 1 number*"
                                    name="password"
                                    id="password"
                                    type="password"
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                />
                            </span>
                            <p className="login-terms">
                                *These fields are compulsory, please complete them to proceed.
                  <br />
                  By logging in you are agreeing to terms and conditions.{" "}
                            </p>
                        </div>

                        {this.state.error !== "" ?
                            <div className="login-error-item">
                                <p>{this.state.error} <br /> Please try again.</p>
                            </div> : null}

                        <div className="login-submit-button-container">

                            <br />
                            <input
                                className="a-button-primary submit-button"
                                type="submit"
                                value="Login"
                            />

                            <div className="login-forgot-password-container">
                                <br />
                                <Link className="login-forgot-password" to="/forgot">
                                    Forgot your password?
                  </Link>
                            </div>
                        </div>
                    </form>

                    <br />
                    <div className="login-signup-container">
                        <a href="/contact" className="login-signup-text">
                            Do not have an account? Get in touch with us to set up your
                            account.
              </a>
                    </div>
                    <br />
                </div>
            </div>
        );
    }
    // }
}

export default LoginPage;
