
//ANSWER TYPES
export const ANSWER_TYPE_YES_NO = "yes-no";
export const ANSWER_TYPE_SLIDER_10 = "slider-10";
export const ANSWER_TYPE_SCALE = "scale";
export const ANSWER_TYPE_PERCENTAGE = "percentage";
export const ANSWER_TYPE_PERCENTAGE_MINUS_HANDREND = "percentage-100";
export const ANSWER_TYPE_CURRENCY = "currency";

const prod = {
    url: {
        API_URL: 'https://verimpact-backend.verimpact.com',
        AUTH_URL: 'https://verimpact-oauth.verimpact.com'
    }
};
const dev = {
    url: {
        API_URL: 'https://localhost:7105',
        AUTH_URL: 'https://localhost:44310'
    }
};
//const config = process.env.REACT_APP_ENV === 'development' ? dev : prod;
const config = process.env.NODE_ENV === 'development' ? dev : prod;
export default config;